import "./HowWeWork.scss";
import { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import checkForInvalidToken from "../../utils/checkForInvalidToken";
import { useContext } from "react";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import {useAuthContext} from "../../context";

/**
 * @module
 * @description This function create the structure and the logic of the How We Work at project
 * @param {Function} getProjectOverview get How We Work at project
 * @param {Number} id get Id the project
 * @returns {JSX} AddHowWeWork
 */

const HowWeWork = ({ getProjectDetail, id }) => {
  const [project, setProject] = useState(""); //data is restored here
  const [loading, setLoading] = useState(false); //implement a spinner
  const { editProjectHowWeWork, auth } = useContext(ProjectsContext);
  const [howWeWork, setHowWeWork] = useState(project.howWeWork);
  const [isEditing, setIsEditing] = useState(false);
  const intl = useIntl();
  const { user } = useAuthContext();
  const [permissions,setPermissions] = useState([]);

  useEffect(() => {
    setLoading(true);
    getProjectDetail(id)
      .then((res) => {
        const projectData = res.data.data;
        setProject(projectData);
        setHowWeWork(projectData.howWeWork); //get the res to validate the info
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      }); //do something if some went wrong
  }, [isEditing]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    setHowWeWork(e.target.value);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setHowWeWork(project.howWeWork);
  };

  const handleConfirm = async () => {
    setIsEditing(false);
    let data = {
      howWeWork: howWeWork,
    };
    try {
      const response = await editProjectHowWeWork(id, data);
      if (response) {
        setLoading(true);
        getProjectDetail(id)
          .then((res) => {
            const projectData = res.data.data;
            setProject(projectData);
            setHowWeWork(projectData.howWeWork); //get the res to validate the info
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          }); //do something if some went wrong
      }
    } catch (err) {
      console.log(err);
    }
  };

  const activities = howWeWork?.split(".");

  useEffect(()=>{
    let permission =user.permissions.userPermissions;
    let permissionsArray = [];
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <>
      {" "}
      {loading ? (
        <div> Loading... </div>
      ) : (
        <>
          <div className="howWeWork">
            <h2>
              {" "}
              <FormattedMessage
                id="project.by.id.how.we.work"
                defaultMessage="How We Work"
              />
              {permissions.includes('update-projects')?
              <div className="edit">
                <button
                  data-tip={intl.formatMessage({
                    id: "edit.task",
                    defaultMessage: "Edit",
                  })}
                  data-for="editHowWeWork"
                  onClick={handleEdit}
                >
                  <BiEditAlt />
                </button>
                <ReactTooltip
                  id="editHowWeWork"
                  place="bottom"
                  backgroundColor="#3FAA58"
                />
              </div>:null}
              <div className="lineHowWeWork"></div>
            </h2>
            {isEditing ? (
              <>
                <textarea
                  className="howWeWorkEdit"
                  type="text"
                  rows="6"
                  onChange={handleChange}
                >
                  {howWeWork}
                </textarea>
                <div className="buttons">
                  <Button className="cancelButton" onClick={handleCancel}>
                    <FormattedMessage
                      id="button.cancel"
                      defaultMessage="Cancel"
                    />
                  </Button>
                  <Button className="confirmButton" onClick={handleConfirm}>
                    <FormattedMessage
                      id="button.accept"
                      defaultMessage="Accept"
                    />
                  </Button>
                </div>
              </>
            ) : (
              <>
                <ul>
                  {activities?.map((activitie, index) => (
                    <li key={index}>
                      <span>{activitie}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </>
      )}{" "}
    </>
  );
};

export default HowWeWork;
