import { useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext/";
import LoginPage from "../googleLogin/googleLogin";
import image from "../../assets/BTS-Logo/bts-logo.svg";
import "./Login.scss";
import { Outlet } from "react-router-dom";

// language swichter feature
import { FormattedMessage } from "react-intl";

function Login() {
  const navigate = useNavigate();
  const auth = useAuthContext();

  if (!!auth.user) {
    return (
      <>
        Redirecting to log in page....
        <Outlet />
      </>
    );
  }

  return (
    <>
      <div className="main_container">
        <div className="navbar_container"></div>
        <div className="view_container">
          <img src={image} alt="" />
          <h3>
            <FormattedMessage id="login.welcome.message" defaultMessage="Welcome to your Blue Trail!"/>{" "}
          </h3>
          <label>
            <FormattedMessage id="login.google.sign.data" defaultMessage="Please sign in with your company Google Account to continue"/>
          </label>

          {/* THE FOLLOWING BUTTON AND PARAGRAPH MUST BE REMOVED, IT'S JUST AN EXAMPLE TO SWITCH LANGUAGE */}
          { /* <p> This is an example using parameters: </p>
          <p>
            <FormattedMessage
              id="with_parameters_example"
              values={{ n: 300 }}
            />{" "}
          </p>
          <button onClick={() => switchLanguage()}>
            <FormattedMessage id="general_switch_language" />{" "}
          </button>
          */}
          <div className="google-btn">
            <LoginPage />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
