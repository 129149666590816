import css from "./WhatIsGoingOn.module.scss";
import React, { useState, useLayoutEffect, useEffect } from "react";
import { Link } from "react-router-dom";
import WhatIsGoingOnElement from "./WhatIsGoingOnElement.js";
import PropTypes from "prop-types";
import { useAuthContext } from "../../../context/AuthContext";
import { getNews } from "../services/news";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @module
 * @description Display what is going on component on global / location page
 * @param {String} region Can be name of a region or global
 * @returns {JSX} Component
 */
const WhatIsGoingOn = ({ region }) => {
  const context = useAuthContext();
  const intl = useIntl();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [lastFour, setLastFour] = useState([]);

  useLayoutEffect(() => {
    let isMounted = true;

    (async function () {
      try {
        let response = await getNews(region, context);
        let localResponse = await getNews('local', context);

        if(region==='local') response = response.filter(item => !item.isGlobal)
        if(region==='global') response = [...response, ...localResponse]

        let tempNews = [];
        response.forEach((item)=>{
          let push = true;
          for (const itemElement of tempNews) {
            if(itemElement.noticeId !== item.noticeId){
              push =  true;
            }else {
              push = false;
              break;
            }
          }
          if(push){
            tempNews.push(item);
          }
        })
        response = tempNews;
        response = response.sort(function (a, b) {
          if (a.datePublished < b.datePublished) {
            return 1;
          }
          if (a.datePublished > b.datePublished) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        if (isMounted) {
          setNews(response);
          setLoading(false);
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    })();
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const aux = await news.slice(0, 4);
      setLastFour(aux);
    }
    fetchData();
  }, [news]);

  const title =
    region === "global" ? (
      <FormattedMessage
        id="what.is.going.on.globally"
        defaultMessage="globally"
      />
    ) : (
      <FormattedMessage
        id="what.is.going.on.locally"
        defaultMessage="locally"
      />
    );

  return (
    <div className={css.whatIsGoingOnContainer}>
      <h2 className={css.whatIsGoingOnTitle}>
        <FormattedMessage
          id="what.is.going.on"
          defaultMessage="What is going on {title}"
          values={{ title: title }}
        />
      </h2>
      <div className={css.articles}>
        {news.length === 4
          ? news.map((item, key) => (
              <WhatIsGoingOnElement
                key={item.noticeId}
                item={item}
                region={region}
                id={item.noticeId}
              />
            ))
          : lastFour.map((item, key) => (
              <WhatIsGoingOnElement
                key={item.noticeId}
                item={item}
                region={region}
              />
            ))}
      </div>
      <Link className={css.viewAll} to={`/news/region/${region}`}>
        <FormattedMessage
          id="going.on.view.entries"
          defaultMessage="View all entries."
        />
      </Link>
    </div>
  );
};

WhatIsGoingOn.propTypes = {
  region: PropTypes.string.isRequired,
};

export default WhatIsGoingOn;
