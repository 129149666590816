import "../../pages/Projects/Projects2.scss";
import { useState, useRef, useEffect } from "react";
import FilterableIcons from "../../components/Projects/FilterableIcons";
import { MyProjects } from "../Icons/index";
import AddProjectModal from "../../components/Projects/AddProjectModal";
import TableHeaderItem from "./TableHeaderItem";
import InfoStatus from "../../components/Projects/InfoStatus";
import InfoProject from "../../components/Projects/InfoProject";
import SearchProject from "../../components/Projects/SearchProject";
import Players from "../../components/Projects/Players";
import ProjectActionsColumn from "./ProjectActionsColumn";
import ActionButton from "../../components/shared/ActionButton";
import { FormattedMessage } from "react-intl";
import {useAuthContext} from "../../context";
import {useNavigate} from "react-router-dom";

export const ProjectsList = ({
  projects,
  handleFilterSort,
  filterSort,
  setFilterSearch,
  refetch,
}) => {
  const searchValueRef = useRef("");
  const [filterArchived, setFilterArchived] = useState(true);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [projectStatus, setProjectStatus] = useState([]);
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);

  const arrayToFilter = projects.map((project) => {
    let data = project.data;
    for (let item of data) {
      if (item.projectIsArchived) item.statusId = 5;
    }
    return data;
  });

  const projectsList = [];
  const [allProjects, setAllProjects] = useState([]);

  arrayToFilter.forEach((projects) => {
    projects.forEach((project) => {
        projectsList.push(project);
    });
  });

  const statusButtons = [
    {
      name: "In-danger",
      statusId: 2,
      statusType: "st_inDanger",
    },
    {
      name: "At-risk",
      statusId: 3,
      statusType: "st_atRisk",
    },
    {
      name: "On-track",
      statusId: 1,
      statusType: "st_onTrack",
    },
    {
      name: "Out-dated",
      statusId: 4,
      statusType: "st_outdated",
    },
    {
      name: "Archived",
      statusId: 5,
      statusType: "st_archived",
    },
  ];

  useEffect(() => {
    setAllProjects(projectsList);
  }, [projects]);

  useEffect(() => {
    if (projectStatus.length === 0) {
      setAllProjects(projectsList);
    } else {
      setAllProjects(
        projectsList.filter((statusButtons) =>
          projectStatus.some((type) =>
            [statusButtons.statusId].flat().includes(type)
          )
        )
      );
    }
  }, [projectStatus]);

  const handleFilterByStatus = (e) => {
    setFilterArchived(true);
    if (e.target.checked) {
      setProjectStatus([...projectStatus, Number(e.target.value)]);
    } else {
      setProjectStatus(
        projectStatus.filter((id) => id !== Number(e.target.value))
      );
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    setFilterSearch(searchValueRef.current.value.trim().toLowerCase());
  };

  const clearSearch = () => {
    searchValueRef.current.value = "";
    setFilterSearch("");
  };

  const handleProjectModal = () => setProjectModalOpen(!projectModalOpen);
  const addProject = () => handleProjectModal();

  useEffect(()=>{
    let permission = user.permissions.userPermissions;
    let permissionsArray = [];
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <div>
      <header>
        <div className="flex-header">
          <h1 className="title-conteiner">
            <div className="folder">
              <MyProjects />
            </div>{" "}
            <FormattedMessage
              id="projects.header.projects"
              defaultMessage="Projects"
            />
          </h1>
          <FilterableIcons
            setFilterArchived={setFilterArchived}
            projects={projects}
            statusButtons={statusButtons}
            handleFilterByStatus={handleFilterByStatus}
          ></FilterableIcons>
        </div>
      </header>
      <div className="projects-list">
        <div className="project-list-search">
          <div className="search-project">
            <SearchProject
              searchValueRef={searchValueRef}
              onSearch={onSearch}
              projectsList={projectsList}
              clearSearch={clearSearch}
            />
          </div>
          <div className="position">
            {permissions.includes('create-projects') ?
              <div className="project-button">
                <AddProjectModal
                  show={projectModalOpen}
                  handleProjectModal={handleProjectModal}
                />
                <ActionButton onClick={addProject}>
                  <FormattedMessage
                    id="projects.add.projects"
                    defaultMessage="Add projects"
                  />
                </ActionButton>
              </div> : null}
          </div>
        </div>
      </div>
      {!projectsList ? (
        <p style={{ textAlign: "center" }}>
          <FormattedMessage
            id="no.projects.to.show"
            defaultMessage="There are no projects to show"
          />
        </p>
      ) : (
        <>
          <div className="project-list-table head-table">
            <TableHeaderItem
              label="Name"
              currentColumn="projectName"
              filterSort={filterSort}
              handleFilterSort={handleFilterSort}
            />
            <TableHeaderItem
              label="Status"
              currentColumn="status"
              filterSort={filterSort}
              handleFilterSort={handleFilterSort}
            />
            <div>
              <FormattedMessage id="project.players" defaultMessage="Players" />
            </div>
          </div>
          {allProjects.map((project, index) => (
            <div className="project-list-table" key={project.projectId}>
              <div className="info-project">
                <InfoProject project={project} />
              </div>
              <div className="status-project-list">
                <InfoStatus project={project} />
              </div>
              <div className="list-users">
                <Players
                  members={project.players}
                  moodId={project.players.moodId}
                  position={index}
                  refetch={refetch}
                  projectId={project.projectId}
                />
              </div>
              <div className="puntos">
                <ProjectActionsColumn projectData={project} refetch={refetch} />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ProjectsList;
