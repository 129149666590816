import axios from "axios";
import { environment } from "../../../config/environment";
import moment from "moment";

//retrieves global events
export const getCalendarEvents = (token) => {
  const header = {
    headers: {
      Authorization: token,
    },
  };

  return GetEvents();

  function GetEvents() {

    const url = `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/events/all`;

    return axios
      .get(url, header)
      .then((res) => res.data)
      .then((daysData) => daysData.data)
      .then((days) => {
        let events = [];
        days.map((day) => {
          if (day.globallyViewable) {
            let utcStartHour = moment(day.startHour,'HH:mm').format("HH:mm");
            let utcEndHour = moment(day.endHour,'HH:mm').format("HH:mm");
            const eventDate = moment.utc(day.date).format("YYYY-MM-DD"+"T00:00:00");
            events.push({
              id: Date.parse(day.date),
              title: day.name,
              start: eventDate,
              borderColor: "#785549",
              textColor: "#785549",
              backgroundColor: "transparent",
              allDay: true,
              global: day.globallyViewable,
              wholeDay: day.allDay,
              startHour: day.startHour !== "00:00"? moment.utc(utcStartHour,'HH:mm').local().format("hh:mm A") : day.startHour,
              endHour: day.endHour !== "00:00"? moment.utc(utcEndHour,'HH:mm').local().format("hh:mm A") : day.endHour,
              type: "Event",
              reason: `${day.description ? day.description : day.name}`,
            });
          }
        });
        return events;
      })
      .catch((error) => {
        throw error.response || error.toJSON() || error;
      });
  }
};

//retrieves events for global context
export const getGlobalCalendarEvents = (token) => {
  const header = {
    headers: {
      Authorization: token,
    },
  };

  return GetGlobalEvents();

  function GetGlobalEvents() {

    const url = `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/events/all`;

    return axios
      .get(url, header)
      .then((res) => res.data)
      .then((daysData) => daysData.data)
      .then((days) => {
        let events = [];
        days.map((day) => {
          let utcStartHour = moment(day.startHour,'HH:mm').format("HH:mm");
          let utcEndHour = moment(day.endHour,'HH:mm').format("HH:mm");
          const eventDate = moment.utc(day.date).format("YYYY-MM-DD"+"T00:00:00");
          events.push({
            id: Date.parse(day.date),
            title: day.name,
            start: eventDate,
            borderColor: "#785549",
            textColor: "#785549",
            backgroundColor: "transparent",
            allDay: true,
            global: day.globallyViewable,
            wholeDay: day.allDay,
            startHour: day.startHour !== "00:00"? moment.utc(utcStartHour,'HH:mm').local().format("hh:mm A") : day.startHour,
            endHour: day.endHour !== "00:00"? moment.utc(utcEndHour,'HH:mm').local().format("hh:mm A") : day.endHour,
            type: "Event",
            reason: `${day.description ? day.description : day.name}`,
          });
        });
        return events;
      })
      .catch((error) => {
        throw error.response || error.toJSON() || error;
      });
  }
};

//retrieves events from a specific region
export const getCalendarEventsByRegion = (token, regionId) => {
  const header = {
    headers: {
      Authorization: token,
    },
  };

  return GetEventsByRegion(regionId);

  function GetEventsByRegion(regionId) {

    const url = `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/events?regionId=${regionId}`;

    return axios
      .get(url, header)
      .then((res) => res.data)
      .then((daysData) => daysData.data)
      .then((days) => {
        let events = [];
        days.rows.map((day) => {
          let utcStartHour = moment(day.startHour,'HH:mm').format("HH:mm");
          let utcEndHour = moment(day.endHour,'HH:mm').format("HH:mm");
          const eventDate = moment.utc(day.date).format("YYYY-MM-DD"+"T00:00:00");
          events.push({
            id: Date.parse(day.date),
            title: day.name,
            start: eventDate,
            borderColor: "#785549",
            textColor: "#785549",
            backgroundColor: "transparent",
            allDay: true,
            global: day.globallyViewable,
            wholeDay: day.allDay,
            startHour: day.startHour !== "00:00"? moment.utc(utcStartHour,'HH:mm').local().format("hh:mm A") : day.startHour,
            endHour: day.endHour !== "00:00"? moment.utc(utcEndHour,'HH:mm').local().format("hh:mm A") : day.endHour,
            type: "Event",
            reason: `${day.description ? day.description : day.name}`,
          });
        });
        return events;
      })
      .catch((error) => {
        throw error.response || error.toJSON() || error;
      });
  }
};