import moment from 'moment';
import AllocationDelete from '../Icons/AllocationDelete';
import BillableIcon from '../Icons/BillableIcon';
import TemporarySupport from '../Icons/TemporarySupport';
import WarningIcon from '../Icons/WarningIcon';
import './AllocationTable.scss';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * @module
 * @description This function create the structure and the logic of the allocation table
 * @param {Function} getUserData get the user data
 * @param {Function} playerAllocations
 * @param {Number} playerId
 * @param {String} currentAllocation
 * @param {String} type to see the type of the modal
 * @param {Function} callback function to be executed one we have the response
 * @returns {JSX} allocation table
 */

const AllocationTable = ({
  playerAllocations,
  currentAllocation,
  selectedAllocations,
  setSelectedAllocations,
}) => {
  const [allocation, setAllocation] = useState([]);

  useEffect(() => {
    setAllocation([]);
  }, []);

  const onCheckboxChange = (e, allocationValue, allocationType) => {
    const checked = e.target.checked;
    const value = e.target.value;

    if (allocationType === 'Partial-time-hs') {
      allocationValue = Math.floor((allocationValue * 100) / 56);
    } else if (allocationType === 'On-demand') {
      allocationType = 0;
    }
    if (checked) {
      setAllocation([...allocation, allocationValue]);
      setSelectedAllocations([...selectedAllocations, value]);
    } else {
      setAllocation([...allocation, -allocationValue]);
      setSelectedAllocations(
        selectedAllocations.filter((val) => val !== value)
      );
    }
  };

  // check if a player is overtime
  /**
   * @description See if a player has more than 100% of allocations
   * @returns {Number} totalPercentege
   */
  const playerIsOvertime = () => {
    let totalPercentage = Number(currentAllocation);
    let totalHrs = Math.floor(Number(currentAllocation));
    let total;

    playerAllocations?.forEach((player) => {
      if (player.allocationType === 'Partial-time-hs') {
        totalHrs += Math.floor((player.allocationValue * 100) / 56);
      } else {
        totalPercentage += player.allocationValue;
      }
      total = totalPercentage + totalHrs;
    });
    return total > 100;
  };

  const checkingAllocations = () => {
    const initialValue = 0;
    const sum = allocation.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
    let totalPercentage = Number(currentAllocation);
    playerAllocations?.forEach((player) => {
      if (player.allocationType === 'Partial-time-hs') {
        totalPercentage += Math.floor((player.allocationValue * 100) / 56);
      } else {
        totalPercentage += player.allocationValue;
      }
    });
    return totalPercentage - sum;
  };

  const imgURL =
    'https://pbs.twimg.com/profile_images/1395764701620015107/rioTM8Ld_400x400.jpg';

  return (
    <>
      {playerAllocations?.length ? (
        <div className="allocation-container">
          <h5 className="table-title">Review other allocations</h5>
          <div>
            <table className="allocation-table">
              <thead>
                <tr className="allocation-header">
                  <td>Projects</td>
                  <td>Dates</td>
                  <td>Types</td>
                  <td>Role</td>
                  <td className="thrash-icon">
                    <div>
                      <AllocationDelete />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody style={{ lineHeight: '1.2' }}>
                {playerAllocations.map((item) => {
                  return (
                    <tr
                      className="allocation-body"
                      key={item.userProjectHoursId}
                    >
                      <td>
                        <div>
                          <img
                            src={imgURL}
                            className="img-project"
                            alt="project"
                          />
                          #{item.projectKey}
                        </div>
                      </td>
                      <td style={{ lineHeight: '1' }}>
                        From {moment(item.fromDate).format(`DD/MM/YY`)} Release:{' '}
                        {item.releaseDate === null
                          ? 'N/A'
                          : moment(item.releaseDate).format(`DD/MM/YY`)}
                      </td>
                      <td>
                        <b>
                          {item.allocationType}
                          {item.allocationType !== 'On-demand'
                            ? ` (${item.allocationValue}${
                                item.allocationType === 'Partial-time-hs'
                                  ? 'Hrs'
                                  : '%'
                              })`
                            : ''}
                        </b>
                      </td>
                      <td>
                        <b>{item.projectRoleName}</b>
                      </td>
                      <td className="icons-container">
                        <TemporarySupport
                          temporary={item.temporarySupport ? 1 : 0.3}
                        />
                        <BillableIcon
                          billable={item.billableAllocation ? 1 : 0}
                        />
                        <label>
                          <input
                            type="checkbox"
                            style={{ height: '15px' }}
                            value={item.id}
                            onChange={(e) =>
                              onCheckboxChange(
                                e,
                                item.allocationValue,
                                item.allocationType
                              )
                            }
                          />
                        </label>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="allocation-footer">
              {playerIsOvertime() && checkingAllocations() > 100 ? (
                <>
                  <WarningIcon />
                  <h6>Total allocations is over 100%</h6>
                </>
              ) : (
                <h6>Total allocation: {checkingAllocations()}%</h6>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

AllocationTable.propTypes = {
  getUserData: PropTypes.func.isRequired,
  playerAllocations: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  playerId: PropTypes.number.isRequired,
  currentAllocation: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

export default AllocationTable;
