import { useEffect, useState } from "react";
import "./ProjectOverview.scss";
import { BiEditAlt } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useContext } from "react";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import checkForInvalidToken from "../../utils/checkForInvalidToken";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import {useAuthContext} from "../../context";

/**
 * @module
 * @description This function creates the structure and the logic of Project Overview
 * @param {number} id get id project
 * @param {Function} getProjectOverview get Project Overview
 * @returns {JSX} Project Overview
 */

const ProjectOverview = ({ getProjectDetail, id }) => {
  const [project, setProject] = useState({}); //data is restored here
  const [loading, setLoading] = useState(false); //implement a spinner
  const { editProjectOverview, auth } = useContext(ProjectsContext);
  const [overview, setOverview] = useState(project.description);
  const [isEditing, setIsEditing] = useState(false);
  const intl = useIntl();
  const { user } = useAuthContext();
  const [permissions,setPermissions] = useState([]);

  useEffect(() => {
    setLoading(true);
    getProjectDetail(id)
      .then((res) => {
        //get the res to validate the info
        const projectData = res.data.data;
        setProject(projectData);
        setOverview(projectData.description);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      }); //do something if some went wrong
  }, [isEditing]);


  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    setOverview(e.target.value);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setOverview(project.description);
  };

  const handleConfirm = async () => {
    setIsEditing(false);
    let data = {
      description: overview,
    };
    try {
      const response = editProjectOverview(id, data);
      if (response) {
        setLoading(true);
        getProjectDetail(id)
          .then((res) => {
            const projectData = res.data.data;
            setProject(projectData);
            setOverview(projectData.description); //get the res to validate the info
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          }); //do something if some went wrong
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(()=>{
    let permission =user.permissions.userPermissions;
    let permissionsArray = [];
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <>
      {" "}
      {loading ? (
        <div> Loading... </div>
      ) : (
        <div className="projectOverview">
          <h2>
            <FormattedMessage
              id="project.by.id.overview"
              defaultMessage="Overview"
            />
            {permissions.includes('update-projects')?
            <div className="edit">
              <button
                data-tip={intl.formatMessage({
                  id: "edit.task",
                  defaultMessage: "Edit",
                })}
                data-for="editOverview"
                onClick={handleEdit}
              >
                <BiEditAlt />
              </button>
              <ReactTooltip
                id="editOverview"
                place="bottom"
                backgroundColor="#3FAA58"
              />
            </div>:null}
            <div className="lineprojectOverview"></div>
          </h2>
          {isEditing ? (
            <>
              <textarea
                className="overviewEdit"
                type="text"
                rows="6"
                onChange={handleChange}
              >
                {overview}
              </textarea>
              <div className="buttons">
                <Button className="cancelButton" onClick={handleCancel}>
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button className="confirmButton" onClick={handleConfirm}>
                  <FormattedMessage
                    id="button.accept"
                    defaultMessage="Accept"
                  />
                </Button>
              </div>
            </>
          ) : (
            <>
              <p>{project.description}</p>
            </>
          )}
        </div>
      )}{" "}
    </>
  );
};

export default ProjectOverview;
