import "./Sidebar.scss";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  MyLocation,
  GlobalComponent,
  Scheduler,
  MyProjects,
  RolesAndPermissions,
} from "../../Icons/index";
import FeedbackModal from "../FeedbackModal/FeedbackModal";
import ReactTooltip from "react-tooltip";

const navItems = [
  {
    id: 'sideBarGlobalLocalContainer',
    type: 'container',
    children: [
      {
        id: 'global',
        path: "/",
        icon: <GlobalComponent />,
        title: "Global Home",
      },
      {
        id: 'local',
        path: "/location",
        icon: <MyLocation />,
        title: "My Location",
      },
    ]
  },
  {
    id: 'scheduler',
    path: "/scheduler",
    icon: <Scheduler />,
    title: "Scheduler",
  },
  {
    id: 'projects',
    path: "/projects",
    icon: <MyProjects />,
    title: "My Projects",
  },
  {
    id: 'roles_and_permissions',
    path: "/roles-and-permissions",
    icon: <RolesAndPermissions />,
    title: "Roles and permissions",
  },
];
/**
 * @module
 * @description Gathers all the informations of a sidebar element and creates a single component of it.
 * @param {Object} item
 * @returns {JSX} Items
 */
const Items = ({ item }) => {
  const [className, setClassname] = useState("text-hidden");
  return (
    <div id={item.id} data-tip={item.title} data-for={item.title} key={item.path}>
      <li key={item.path} className="sidebarNavItem">
        <NavLink
          to={item.path}
          className="sidebarNavIcon"
          activeclassname="active"
        >
          <div
            className="box-items"
            onMouseEnter={() => setClassname("bubble-box-active")}
            onMouseLeave={() => setClassname("bubble-box-desactive")}
          >
            {item.icon}
          </div>
        </NavLink>
      </li>
    </div>
  );
};

/**
 * @description It Gathers all the individual components of the items array and puts them into a single component , the sidebar.
 * @returns {JSX} Sidebar
 */
function Sidebar() {
  return (
    <div className="sidebar">
      <nav className="sidebarNav">
        <ul className="sidebarNavItems">
          {navItems.map((item) => {
            if (item.type === 'container') {
              const children = item.children.map(child => (
                <>
                  <Items item={child} key={child.path} />
                      
                  <ReactTooltip
                    id={child.title}
                    place="right"
                    backgroundColor="#3FAA58"
                  />
                </>
              ))

              return (
                <div id={item.id}>
                  {children}
                </div>
              )
            }
            
            return (
            <>
              <Items item={item} key={item.path} />

              <ReactTooltip
                id={item.title}
                place="right"
                backgroundColor="#3FAA58"
              />
            </>
          )}
          )}
          <div className="box-container">
            <FeedbackModal />
          </div>
        </ul>
      </nav>
    </div>
  );
}

Items.propTypes = {
  item: PropTypes.object.isRequired,
};
export default Sidebar;
