import React, { useState, useEffect, forwardRef } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../SchedulerLayout/scheduler.scss";
import "../PopOver/popOver.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { default as BootstrapForm } from "react-bootstrap/Form";
import * as Yup from "yup";
import { useAuthContext } from "../../../context/AuthContext";
import { useSchedulerContext } from "../../../context/SchedulerContext/SchedulerContext";
import CustomDatePicker from "../../../utils/CustomDatePicker";
// language switcher feature
import { FormattedMessage, useIntl } from "react-intl";
const {
  getLocationsAndRegions,
} = require("../services/getLocationsAndRegions");
const { holidayServiceDataFormat } = require("../services/post.holidays");

/**
 * @module
 * @description This component is the holidays form
 * @param {Object} obj - An Object
 * @param {string} obj.selectedDate
 * @param {Function} obj.handleClose
 * @returns {JSX} HolidayModal
 */
function HolidayModal({
  contexts,
  refreshTask,
  count,
  selectedDate,
  handleClose,
  toast,
  handleToast,
  entryType,
  handleChangeModalType,
}) {
  const auth = useAuthContext();
  const [startDate, setStartDate] = useState(moment(selectedDate).toDate());
  const [context, setContext] = useState([]);
  const contextEnum = {
    Global: "Global",
    Region: auth.userInfo.region,
    Local: auth.userInfo.location,
  };
  const schedulerContext = useSchedulerContext();

  const intl = useIntl();

  const NewHolidaySchema = Yup.object().shape({
    context: Yup.number().required(
      intl.formatMessage({
        id: "modal.required.field",
        defaultMessage: "This is a required field",
      })
    ),
    date: Yup.date().required(
      intl.formatMessage({
        id: "modal.required.field",
        defaultMessage: "This is a required field",
      })
    ),
    title: Yup.string().required(
      intl.formatMessage({
        id: "modal.required.field",
        defaultMessage: "This is a required field",
      })
    ),
    description: Yup.string().required(
      intl.formatMessage({
        id: "modal.required.field",
        defaultMessage: "This is a required field",
      })
    ),
  });

  useEffect(() => {
    let mounted = true;
    getLocationsAndRegions(auth.user.token).then((items) => {
      if (mounted) {
        setContext(items);
      }
    });
    return () => (mounted = false);
  }, []);

  return (
    <>
      <Modal.Header closeButton className="modalHeader">
        <FormattedMessage
          id="scheduler.modal.entry.holyday"
          defaultMessage="Add holiday entry"
        />
      </Modal.Header>
      <div className="timesheetModalBody">
        <div className="form-group">
          <label htmlFor="type">
            <FormattedMessage
              id="scheduler.modal.entry.type"
              defaultMessage="Entry type"
            />
          </label>
          <Col md={6}>
            <BootstrapForm.Select
              size="sm"
              className="timesheetSelect"
              value={entryType}
              onChange={handleChangeModalType}
              id="entryTypeSelect"
            >
              {/* EXPORT THESE VALUES FOR A GLOBAL CONTEXT ARRAY*/}
              <option value={2}>
                {intl.formatMessage({
                  id: "modal.entry.type.option2",
                  defaultMessage: "Holidays",
                })}
              </option>
              <option value={4}>
                {intl.formatMessage({
                  id: "modal.entry.type.option4",
                  defaultMessage: "Event",
                })}
              </option>
            </BootstrapForm.Select>
          </Col>
        </div>
      </div>
      <hr className="hrModal"></hr>
      <Formik
        initialValues={{
          context: "",
          date: selectedDate || moment().format("YYYY-MM-DD"),
          title: "",
          description: "",
        }}
        validationSchema={NewHolidaySchema}
        onSubmit={async (values, actions) => {
          try {
            const response = await holidayServiceDataFormat(
              values,
              auth.user.id,
              auth.user.token
            );
            if (response) {
              refreshTask(count + 1);
              Swal.fire(
                intl.formatMessage({
                  id: "modal.holidays.swal.success",
                  defaultMessage: "A new holiday was added successfully!",
                }),
                "",
                "success"
              );
              handleClose();
              schedulerContext.getHolidays();
            }
            actions.setSubmitting(false);
          } catch (error) {
            Swal.fire(
              intl.formatMessage({
                id: "modal.holidays.swal.error.description",
                defaultMessage: "Something went wrong: ",
              }) + error.data?.message,
              intl.formatMessage({
                id: "modal.error.footer",
                defaultMessage: "Please, try again later",
              }),
              "error"
            );
            console.log(error);
          }
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <>
              <Modal.Body className="timesheetModalBody">
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="context">
                        <FormattedMessage
                          id="modal.holidays.context"
                          defaultMessage="Context*"
                        />
                      </label>
                      <br />
                      <Field
                        component="select"
                        name="context"
                        className={`form-control form-select ${
                          errors.context ? "is-invalid" : ""
                        }`}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "modal.holidays.context.select",
                            defaultMessage: "Select context",
                          })}
                        </option>
                        {contexts === contextEnum.Global ? (
                          <>
                            {context.map((contextItem) => {
                              return (
                                <>
                                <optgroup
                                  key={contextItem.id}
                                  value={contextItem.id}
                                  label={contextItem.name}
                                >
                                  {contextItem.locations.map((locations) => {
                                    return (
                                      <option
                                        key={locations.id}
                                        value={locations.id}
                                        label={locations.name}
                                      >
                                        {locations.name}
                                      </option>
                                    );
                                  })}
                                </optgroup>
                              </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {context
                              .filter(
                                (contextItem) =>
                                  contextItem.name === contextEnum.Region
                              )
                              .map((contextItem) =>
                                contextItem.locations.map((locations) => (
                                  <option
                                    key={locations.id}
                                    value={locations.id}
                                    label={locations.name}
                                  >
                                    {locations.name}
                                  </option>
                                ))
                              )}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="context"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>

                  <Col xs={4}>
                    <div className="form-group">
                      <label htmlFor="date">
                        <FormattedMessage
                          id="modal.holidays.date"
                          defaultMessage="Date*"
                        />
                      </label>
                      <br />
                      <DatePicker
                        wrapperClassName="datePicker"
                        dateFormat="MM/dd/yyyy"
                        onChange={(date) => setStartDate(date)}
                        showDisabledMonthNavigation
                        selected={startDate}
                        customInput={<CustomDatePicker />}
                        //locale={locale}
                      />
                    </div>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="titleHoliday">
                        <FormattedMessage
                          id="modal.holidays.title"
                          defaultMessage="Title*"
                        />
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="title"
                        id="titleHoliday"
                        placeholder={intl.formatMessage({
                          id: "modal.holidays.title.placeholder",
                          defaultMessage: "Title Holiday",
                        })}
                        className={`form-control ${
                          touched.title && errors.title ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="title"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="description">
                        <FormattedMessage
                          id="modal.holidays.description"
                          defaultMessage="Description*"
                        />
                      </label>
                      <br />
                      <Field
                        as="textarea"
                        name="description"
                        placeholder={intl.formatMessage({
                          id: "modal.holidays.description.placeholder",
                          defaultMessage: "Write a description",
                        })}
                        className={`form-control ${
                          touched.description && errors.description
                            ? "is-invalid"
                            : ""
                        }`}
                        id="description"
                      />
                      <ErrorMessage
                        component="div"
                        name="description"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button className="closeButton" onClick={handleClose}>
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button className="submitButton" type="submit">
                  <FormattedMessage
                    id="button.accept"
                    defaultMessage="Accept"
                  />
                </Button>
              </Modal.Footer>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default HolidayModal;
