import React, { useContext, useEffect, useState } from "react";
import "./LatestUpdates.scss";
import moment from "moment";
import { getStatusIcon } from "../../utils/getProjectStatusIcon";
import { AddSquareIcon } from "../Icons";
import ProjectUpdateModal from "./ProjectUpdateModal";
import ProjectUpdateDetail from "./ProjectUpdateDetail";
import { FormattedMessage, useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../context";

/**
 * @module
 * @description This function create the structure and the logic of the Latest Updates
 * @param {Number} id get id
 * @param {Function} getLatestUpdates get data Latest Updates
 * @param {Function} getTeamAndRoles get data team and roles
 * @returns {JSX} Latest Updates
 */

const LatestUpdates = ({ id, getLatestUpdates, getTeamAndRoles }) => {
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [players, setPlayers] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [showUpdateDetails, setShowUpdateDetails] = useState(false);
  const [updateInfo, setUpdateInfo] = useState();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { auth } = useContext(ProjectsContext);
  const intl = useIntl();
  const [permissions,setPermissions] = useState([]);

  useEffect(() => {
    getUpdatesInfo();
  }, []);

  useEffect(() => {
    if (showUpdateModal || showUpdateDetails) {
      getTeamAndRoles(id, "?active=true")
        .then((res) => setPlayers(res?.data?.data))
        .catch((err) => {
          console.error(err, "Something went wrong while getting the players");
        });
    }
  }, [showUpdateModal, showUpdateDetails]);

  // make the api call to get the info and fill the content
  const getUpdatesInfo = async () => {
    setLoading(true);

    getLatestUpdates(id)
      .then((res) => {
        setUpdates(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(
          err,
          "Something went wrong while getting the latest updates"
        );
      }); //do something if some went wrong
  };

  const convertDate = (date) => {
    return moment(date).format("LL");
  };

  const lastUpdates = updates.slice(0, 2);

  // this funcs map all areas updates to get the amount of each status are
  const getStatusLength = (updateInfo) => {
    const {
      clientArea,
      issuesArea,
      risksArea,
      scheduleArea,
      scopeArea,
      teamArea,
    } = updateInfo;

    const areasArray = [
      clientArea,
      issuesArea,
      risksArea,
      scheduleArea,
      scopeArea,
      teamArea,
    ];

    const statusLength = {
      3: 0,
      2: 0,
      1: 0,
    };

    areasArray.forEach((area) => {
      statusLength[area.statusId]++;
    });

    return statusLength;
  };

  useEffect(()=>{
    let permission = user.permissions.userPermissions;
    let permissionsArray = []
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <>
      {loading ? (
        <div>Loading</div>
      ) : (
        <>
          <ProjectUpdateModal
            show={showUpdateModal}
            handleProjectUpdateModal={setShowUpdateModal}
            projectId={id}
            callback={getUpdatesInfo}
            players={players}
          />
          {showUpdateDetails && (
            <>
              <ProjectUpdateDetail
                show={showUpdateDetails}
                handleProjectUpdateDetail={setShowUpdateDetails}
                projectId={id}
                callback={getUpdatesInfo}
                players={players}
                info={updateInfo}
              />
            </>
          )}
          <div className="latest-updates-main">
            {isShow ? (
              <>
                <div className="updates-header">
                  <h2>
                    <FormattedMessage
                      id="project.by.id.latest.updates"
                      defaultMessage="Latest Updates"
                    />{" "}
                    {permissions.includes('update-projects')?
                      <span onClick={() => setShowUpdateModal(true)}>
                      <AddSquareIcon />
                    </span>:
                      <span>
                        <div style={{'height':'25px'}}></div>
                      </span>
                    }
                  </h2>
                  <button
                    className="btn btn-link"
                    onClick={() => setIsShow(false)}
                    id="allViewer"
                  >
                    <FormattedMessage
                      id="project.by.id.lateste.updates.hide.all.updates"
                      defaultMessage="View all updates"
                    />
                  </button>
                </div>
                <div className="updates-list">
                  {updates.map((up) => (
                    <>
                      <div
                        key={up.psId}
                        className={`update border-status-${up.projectStatusId}`}
                      >
                        <div className="status-info">
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {convertDate(up.updatedAt)}{" "}
                          </span>{" "}
                          <div>
                            {getStatusIcon(up.projectStatusId)}
                            <span className={`status-${up.projectStatusId}`}>
                              {" "}
                              {up.projectStatusTitle}
                            </span>
                          </div>
                        </div>
                        <div className="update-areas">
                          <span>Areas:</span>
                          <div className="areas-status">
                            <div
                              className="status-2"
                              data-tip="In-danger"
                              data-for="status-2"
                            >
                              {" "}
                              {getStatusIcon(2)} {getStatusLength(up)[2]}
                            </div>
                            <ReactTooltip
                              id="status-2"
                              place="bottom"
                              backgroundColor="#3FAA58"
                            />
                            <div
                              className="status-3"
                              data-tip="At-risk"
                              data-for="status-3"
                            >
                              {" "}
                              {getStatusIcon(3)} {getStatusLength(up)[3]}
                            </div>
                            <ReactTooltip
                              id="status-3"
                              place="bottom"
                              backgroundColor="#3FAA58"
                            />
                            <div
                              className="status-1"
                              data-tip="On-track"
                              data-for="status-1"
                            >
                              {" "}
                              {getStatusIcon(1)} {getStatusLength(up)[1]}
                            </div>
                            <ReactTooltip
                              id="status-1"
                              place="bottom"
                              backgroundColor="#3FAA58"
                            />
                          </div>
                        </div>
                        <p> {up.publicOverview} </p>
                        <button
                          className="btn btn-link"
                          onClick={() => {
                            setShowUpdateDetails(true);
                            setUpdateInfo(up);
                          }}
                          id="singleViewer"
                        >
                          <FormattedMessage
                            id="project.by.id.lateste.updates.view.full.update"
                            defaultMessage="View full update"
                          />{" "}
                        </button>{" "}
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="updates-header">
                  <h2>
                    <FormattedMessage
                      id="project.by.id.latest.updates"
                      defaultMessage="Latest Updates"
                    />{" "}
                    {permissions.includes('update-projects')?
                    <span onClick={() => setShowUpdateModal(true)}>
                      <AddSquareIcon />
                    </span>:
                      <span>
                        <div style={{'height':'25px'}}></div>
                      </span>
                    }
                  </h2>
                  <button
                    className="btn btn-link"
                    onClick={() => setIsShow(true)}
                    id="allViewer"
                  >
                    <FormattedMessage
                      id="project.by.id.lateste.updates.view.all.updates"
                      defaultMessage="View all updates"
                    />
                  </button>
                </div>
                <div className="updates-list">
                  {lastUpdates.map((up) => (
                    <>
                      <div
                        key={up.psId}
                        className={`update border-status-${up.projectStatusId}`}
                      >
                        <div className="status-info">
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {convertDate(up.updatedAt)}{" "}
                          </span>{" "}
                          <div>
                            {getStatusIcon(up.projectStatusId)}
                            <span className={`status-${up.projectStatusId}`}>
                              {" "}
                              {up.projectStatusTitle}
                            </span>
                          </div>
                        </div>
                        <div className="update-areas">
                          <span>Areas:</span>
                          <div className="areas-status">
                            <div
                              className="status-2"
                              data-tip="In-danger"
                              data-for="status-2"
                            >
                              {" "}
                              {getStatusIcon(2)} {getStatusLength(up)[2]}
                            </div>
                            <ReactTooltip
                              id="status-2"
                              place="bottom"
                              backgroundColor="#3FAA58"
                            />
                            <div
                              className="status-3"
                              data-tip="At-risk"
                              data-for="status-3"
                            >
                              {" "}
                              {getStatusIcon(3)} {getStatusLength(up)[3]}
                            </div>
                            <ReactTooltip
                              id="status-3"
                              place="bottom"
                              backgroundColor="#3FAA58"
                            />
                            <div
                              className="status-1"
                              data-tip="On-track"
                              data-for="status-1"
                            >
                              {" "}
                              {getStatusIcon(1)} {getStatusLength(up)[1]}
                            </div>
                            <ReactTooltip
                              id="status-1"
                              place="bottom"
                              backgroundColor="#3FAA58"
                            />
                          </div>
                        </div>
                        <p> {up.publicOverview} </p>
                        <button
                          className="btn btn-link"
                          onClick={() => {
                            setShowUpdateDetails(true)
                            setUpdateInfo(up);
                          }}
                          id="singleViewer"
                        >
                          <FormattedMessage
                            id="project.by.id.lateste.updates.view.full.update"
                            defaultMessage="View full update"
                          />{" "}
                        </button>{" "}
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LatestUpdates;
