import { useContext, useEffect, useState } from 'react';
import ProjectsContext from '../../context/ProjectsContext/ProjectsContext';
import './TeamAndRol.scss';
import { AddSquareIcon } from '../Icons';
import AddPlayerModal from './AddPlayerModal';
import { PlayerInfo } from './index';
import {FormattedMessage, useIntl} from "react-intl";
import {useAuthContext} from "../../context";

/**
 * @module
 * @description This function creates the structure and the logic of Team and Rol
 * @param {any} props get id,
 * @returns {JSX} TeamAndRol
 */
const TeamAndRol = (props) => {
  const { id } = props;
  const { getTeamAndRoles, auth } = useContext(ProjectsContext);
  const intl = useIntl();
  const [team, setTeam] = useState([]); //data is restored here
  const [loading, setLoading] = useState(false); //implement a spinner
  const [showAddModal, setShowAddModal] = useState(false);
  const [inactive, setInactive] = useState(false);
  const { user } = useAuthContext();
  const [permissions,setPermissions] =useState([]);

  const getTeamAndRolesData = async (queryParams) => {
    getTeamAndRoles(id, queryParams)
      .then(
        (res) => setTeam(res?.data?.data), //get the res to validate the info
        setLoading(false)
      )
      .catch((err) => {
        console.error(err, 'Something went wrong while getting the info')
      }

      );
  };

  const handleClickInactive = async () => {
    setInactive(!inactive);
    if (!inactive) {
      getTeamAndRolesData(null);
    } else {
      getTeamAndRolesData('?active=true');
    }
  };

  useEffect(() => {
    let queryParams = '?active=true';
    setLoading(true);
    getTeamAndRolesData(queryParams);
  }, []);

  useEffect(()=>{
    let permission =user.permissions.userPermissions;
    let permissionsArray = [];
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <>
          <AddPlayerModal
            show={showAddModal}
            handlePlayerModal={setShowAddModal}
            projectId={parseInt(id)}
            type="add"
            callback={() => getTeamAndRolesData('?active=true')}
            players={team}
          />

          <div className="teamRoles">
            <h2>
              <FormattedMessage id="project.by.id.players" defaultMessage="Players" />{' '}
              {permissions.includes('create-players') ?
              <span onClick={() => setShowAddModal(true)}>
                <AddSquareIcon />
              </span>:null}
              <div className="linePlayers"></div>
            </h2>
            <div className="users">
              {team.map((user) => (
                <div
                  key={user.userId}
                  className={
                    user.status === 'Active' ? 'user' : 'user-inactive'
                  }
                >
                  <PlayerInfo
                    id={id}
                    user={user}
                    getTeamAndRolesData={getTeamAndRolesData}
                  />
                </div>
              ))}
            </div>
            <p className="inactive-players">
              <u onClick={handleClickInactive}>
                {!inactive
                  ? 'Display inactive players'
                  : 'Hide inactive players'}
              </u>
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default TeamAndRol;
