import { Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../../Icons";
import "./AddProjectModal.scss";
import "react-datepicker/dist/react-datepicker.css";
import ActionButton from "../shared/ActionButton";
import moment from "moment";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import Swal from "sweetalert2";
import CloseIcon from "../Icons/CloseIcon";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { getCurrentEpoch } from "../../utils/date";

/**
 * initialValues
 * @type {{projectName: string,
 * projectKey: string,
 * client: string,
 * category: string,
 * contractType: string,
 * otherContractType: string,
 * previousNotice: string,
 * startDate: date,
 * estimatedEndDate: date}}
 */
const initialValues = {
  projectName: "",
  projectKey: "",
  client: "",
  category: "",
  contractType: "",
  otherContractType: "",
  previousNotice: 0,
  startDate: "",
  estimatedEndDate: "",
};

/**
 * @module
 * @description This function creates the structure and the logic of the add/edit a project
 * @param {Boolean} show to see if the modal is open or not
 * @param {Function} handleProjectModal to control the modal
 * @returns {JSX} AddPlayerModal
 */

const AddProjectModal = ({ show, handleProjectModal }) => {
  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { addNewProject, auth } = useContext(ProjectsContext);
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    //data to fill the clients list until the endpoint is ready
    setClientsList([
      { label: "BTS", value: "100" },
      { label: "Nike", value: "101" },
      { label: "Samsung", value: "102" },
      { label: "Najib", value: "103" },
      { label: "Lenovo", value: "104" },
      { label: "Panasonic", value: "105" },
    ]);
  }, []);

  const AddProjectSchema = Yup.object().shape({
    projectName: Yup.string().required("Please enter a project name"),
    projectKey: Yup.string()
      .required("Please enter a project key")
      .matches(/^[aA-zZ\s-+#$&]+$/, "Only alphabets are allowed")
      .min(3, 'Must be exactly 3 letters')
      .max(3, 'Must be exactly 3 letters'),
    client: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .default(null)
      .nullable()
      .required("Please select a client name"),
    //category: Yup.string().required("Please select a category"),
    category: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .default(null)
    .nullable()
    .required("Please select a category"),
    //contractType: Yup.string().required("Please select a contract type"),
    contractType: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .default(null)
    .nullable()
    .required("Please select a contract type"),
    otherContractType: Yup.string().when("contractType", {
      is: (contractType) => contractType?.value === "Other",
      then: Yup.string().required("Please enter a specification"),
    }),
    previousNotice: Yup.number()
      .min(0, "The minimum valid value is 0")
      .max(100, "The maximum valid value is 100")
      .when("contractType", {
        is: (contractType) => contractType?.value === "Long-term",
        then: Yup.number()
          .required("Please enter a previous notice value")
          .min(1, "The minimum valid value is 1"),
      }),
    startDate: Yup.date().nullable().required("Please enter a start date"),
    estimatedEndDate: Yup.date()
      .nullable()
      .when("startDate", (startDate, AddProjectSchema) => {
        if (startDate) {
          const dayAfter = new Date(startDate.getTime() + 86400000);

          return AddProjectSchema.min(
            dayAfter,
            "End date must be later than start date"
          );
        }
        return AddProjectSchema;
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddProjectSchema,
    onSubmit: (values) => {
      const formatedStartDate = getCurrentEpoch(values.startDate);

      const formatedEndDate = getCurrentEpoch(values.estimatedEndDate);

      const payload = {
        projectName: values.projectName,
        projectKey: values.projectKey,
        clientName: values.client.label,
        projectCategory: values.category.label,
        contractType: values.contractType.label,
        otherContractType: values.otherContractType,
        previousNotices: values.previousNotice,
        startDate: moment(formatedStartDate).format('YYYY-MM-DD'),
        endDate: values.estimatedEndDate
          ? moment(formatedEndDate).format('YYYY-MM-DD')
          : values.estimatedEndDate,
      };

      setLoading(true);

      addNewProject(payload)
        .then(({ data: { status, data } }) => {
          setLoading(false);

          if (status === "success") {
            Swal.fire("The project was added successfully!", "", "success");
            handleProjectModal();
            navigate(`/projects/${data.id}`);
          }
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire("Something went wrong", "Try again later", "error");
          console.error(err);
        });
    },
  });

  const onHide = () => {
    formik.resetForm();
    handleProjectModal();
  };

  const categoryOptions = [
    { label: "External Project", value: "External Project" },
    { label: "Internal Project", value: "Internal Project" },
    { label: "Internal Initiative", value: "Internal Initiative" },
    { label: "Internal Group/Sector", value: "Internal Group/Sector" },
  ];

  const contractOptions = [
    { label: "Long-term", value: "Long-term" },
    { label: "Short-term", value: "Short-term" },
    { label: "Fixed-time", value: "Fixed-time" },
    { label: "Other", value: "Other" },
  ];

  const {
    projectName,
    projectKey,
    client,
    category,
    contractType,
    otherContractType,
    previousNotice,
    startDate,
    estimatedEndDate,
  } = formik.values;

  console.log(estimatedEndDate);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="lg"
        className="add-project-modal"
        backdrop={"static"}
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="modal-title">Add a project</Modal.Title>
          <button
            type="button"
            className="close-icon"
            aria-label="Close"
            onClick={onHide}
          >
            <CloseIcon />
          </button>
        </Modal.Header>
        <Modal.Body>
          <form className="add-project-form">
            <div className="form-field">
              <label htmlFor="">Name*</label>
              <input
                type="text"
                id="project-name"
                value={projectName}
                name="projectName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
                placeholder="Enter a name"
              />

              {formik.touched.projectName && formik.errors.projectName ? (
                <div className="formik-error">
                  <small>{formik.errors.projectName}</small>
                </div>
              ) : null}
            </div>

            <div className="form-field">
              <label htmlFor="project-key">3-letters key*</label>
              <input
                type="text"
                id="project-key"
                value={projectKey}
                name="projectKey"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
                placeholder="#"
              />

              {formik.touched.projectKey && formik.errors.projectKey ? (
                <div className="formik-error">
                  <small>{formik.errors.projectKey}</small>
                </div>
              ) : null}
            </div>

            <div className="form-field">
              <label htmlFor="client-name">Client*</label>
              <Select
                id="client-name"
                value={client}
                name="client"
                onChange={(selected) => {
                  formik.setFieldValue("client", selected);
                }}
                onBlur={formik.handleBlur}
                className="select-client"
                placeholder="Search by name..."
                options={clientsList}
              />

              {formik.touched.client && formik.errors.client ? (
                <div className="formik-error">
                  <small>{formik.errors.client}</small>
                </div>
              ) : null}
            </div>

            <div className="form-field">
            <label htmlFor="category">Category*</label>
              <Select
                id="category"
                value={category}
                name="category"
                onChange={(selected) => {
                  formik.setFieldValue("category", selected);
                }}
                onBlur={formik.handleBlur}
                className="select-client"
                placeholder="Select one"
                options={categoryOptions}
              />

              {formik.touched.category && formik.errors.category ? (
                <div className="formik-error">
                  <small>{formik.errors.category}</small>
                </div>
              ) : null}
            </div>

            <div className="form-field">
              <label htmlFor="contract-type">Contract type*</label>
              <Select
                id="contract-type"
                value={contractType}
                name="contractType"
                onChange={(selected) => {
                  formik.setFieldValue("contractType", selected);
                }}
                onBlur={formik.handleBlur}
                className="select-client"
                placeholder="Select type"
                options={contractOptions}
              />

              {formik.values.contractType.value === "Other" && (
                <div className="form-field">
                  <label className="other-contract-type">Other*</label>
                  <textarea
                    id="other-contract-type"
                    value={otherContractType}
                    name="otherContractType"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                    placeholder="Please specify"
                  />
                  {formik.touched.otherContractType &&
                  formik.errors.otherContractType ? (
                    <div className="formik-error">
                      <small>{formik.errors.otherContractType}</small>
                    </div>
                  ) : null}
                </div>
              )}

              {formik.touched.contractType && formik.errors.contractType ? (
                <div className="formik-error">
                  <small>{formik.errors.contractType}</small>
                </div>
              ) : null}
            </div>

            <div className="form-field">
              {/* <label htmlFor="previous-notice"> */}
                {formik.values.contractType.value === "Long-term" ? (
                  <>
                  <label htmlFor="previous-notice"></label>
                  <span>Previous notice*</span>
                  <div className="icon-outside-input">
                    <input
                      type="number"
                      id="previous-notice"
                      min="0"
                      max="100"
                      value={previousNotice}
                      name="previousNotice"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control"
                      placeholder="0"
                    />
                    <span>days</span>
                  </div>
                  </>
                ) : null}
              {/* </label> */}

              {formik.touched.previousNotice && formik.errors.previousNotice ? (
                <div className="formik-error">
                  <small>{formik.errors.previousNotice}</small>
                </div>
              ) : null}
            </div>

            <div className="form-field">
              <label htmlFor="start-date">Start date*</label>
              <div className="input-with-icon">
                <label>
                  <DatePicker
                    selected={startDate}
                    name="start-date"
                    onBlur={formik.handleBlur}
                    id="start-date"
                    placeholderText="Select a date"
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => formik.setFieldValue("startDate", date)}
                    className="form-control"
                    minDate={moment().subtract(10, 'd').toDate()}
                  />
                  <CalendarIcon />
                </label>
              </div>
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="formik-error">
                  <small>{formik.errors.startDate}</small>
                </div>
              ) : null}
            </div>

            <div className="form-field">
              <label htmlFor="estimated-end-date">Estimated end date</label>
              <div className="input-with-icon">
                <label>
                  <DatePicker
                    selected={estimatedEndDate}
                    name="estimated-end-date"
                    onBlur={formik.handleBlur}
                    id="estimated-end-date"
                    placeholderText="Select a date"
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) =>
                      formik.setFieldValue("estimatedEndDate", date)
                    }
                    className="form-control"
                    minDate={moment().toDate()}
                  />
                  <CalendarIcon />
                </label>
              </div>
              {formik.touched.estimatedEndDate &&
              formik.errors.estimatedEndDate ? (
                <div className="formik-error">
                  <small>{formik.errors.estimatedEndDate}</small>
                </div>
              ) : null}
            </div>
          </form>
        </Modal.Body>
        <div className="footer-btn">
          <Modal.Footer>
            <div>
              <ActionButton secondary onClick={onHide}>
                Cancel
              </ActionButton>
              <ActionButton disabled={loading} onClick={formik.submitForm}>
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Add"
                )}
              </ActionButton>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default AddProjectModal;
