import axios from "axios";
import { environment } from "../../../config/environment";

export const getCalendarHolidays = (
  startDate,
  endDate,
  locationId,
  regionId,
  token,
  isPersonal
) => {
  const header = {
    headers: {
      Authorization: token,
    },
  };

  return GetHolidays();

  function GetHolidays() {

    let url = "";
    if (isPersonal) {
      url = `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/calendar?startDate=${startDate}&endDate=${endDate}&regionId=${regionId}&locationId=${locationId}`;
    } else {
      if (regionId && !locationId) {
        url = `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/calendar?startDate=${startDate}&endDate=${endDate}&regionId=${regionId}`;
      } else {
        url = `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/calendar?startDate=${startDate}&endDate=${endDate}&locationId=${locationId}`;
      }
    }
    return axios
      .get(url, header)
      .then((res) => res.data)
      .then((daysData) => daysData.data)
      .then((days) => {
        let holidays = [];
        days.map((day) => {
          if (day.status !== "Working day")
            holidays.push({
              id: Date.parse(day.date),
              title: day.status,
              start: day.date,
              borderColor: "#1C2BC0",
              textColor: "#1C2BC0",
              backgroundColor: "transparent",
              allDay: true,
              type: "Holiday",
              reason: `${day.description ? day.description : day.status}`,
            });
        });
        return holidays;
      })
      .catch((error) => {
        throw error.response || error.toJSON() || error;
      });
  }
};
