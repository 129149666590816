import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../SchedulerLayout/scheduler.scss";
import "../PopOver/popOver.scss";
import { useFormikContext, Formik, Form, Field, ErrorMessage } from "formik";
import { default as BootstrapForm } from "react-bootstrap/Form";
import * as Yup from "yup";
import { useAuthContext } from "../../../context/AuthContext";
import { editTimesheet } from "../services/editTimesheet";
import TagsInput from "../../../utils/TagsInput";
import CustomDatePicker from "../../../utils/CustomDatePicker";
import { useSchedulerContext } from "../../../context/SchedulerContext/SchedulerContext";

// language switcher feature
import { FormattedMessage, useIntl } from "react-intl";
import createUTCDate from "../../../utils/createUTCDate";

/**
 * @module
 * @description This component displays the Time Log Modal
 * @param {string} selectedDate
 * @param {number} count
 * @param {string} selectedDate
 * @param {Function} handleClose
 * @param {number} projectId
 * @param {string} startHour
 * @param {string} endHour
 * @param {string} createAt
 * @param {string} notes
 * @param {string} taskDescription
 * @param {Function} isEditing
 * @param {number} taskId
 * @param {string} originalDate
 * @param {string} tags
 * @returns {JSX} TimeLogModal
 */

const { projectService } = require("../services/get.user.projects");
const {
  userTimesheetsServiceDataFormat,
} = require("../services/post.user.timesheets");

function TimeLogModal({
  refreshTask,
  count,
  selectedDate,
  handleClose,
  projectId,
  startHour,
  endHour,
  createAt,
  notes,
  taskDescription,
  isEditing,
  taskId,
  tags,
  entryType,
  handleChangeModalType,
  firstBillingPeriodModal,
  projectsDB,
  permissions
  }) {
  const auth = useAuthContext();
  const [projects, setProjects] = useState([]);
  const [totalTime, setTotalTime] = useState(0);
  const [startTime, setStartTime] = useState(startHour ? startHour : 0);
  const [endTime, setEndTime] = useState(endHour ? endHour : 0);
  const [timeframeInvalid, setTimeframeInvalid] = useState(false);
  const [tagsInvalidFormat, setTagsInvalidFormat] = useState(false);
  const [tagsInvalidNumber, setTagsInvalidNumber] = useState(false);
  const [startDate, setStartDate] = useState(moment.utc(selectedDate).toDate());
  const [userTags, setUserTags] = useState(
    tags === undefined || tags === null ? [] : tags.split(",")
  );
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [allocationDate, setAllocationDate] = useState();
  const scheduler = useSchedulerContext();
  const intl = useIntl();

  const handleChangeProject =(value)=>{

    const projId = parseInt(value.target.value);

    for (let i = 0; i < projectsDB.length; i++) {
      if(value.target.name === 'project' && projId === projectsDB[i].projectId){
        setAllocationDate(new Date(moment.utc(projectsDB[i].startDate).format("YYYY-MM-DD"+"T00:00:00")));
        break;
      }
    }
  }

  const isNotSunday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  useEffect(() => {
    const currentMonth = moment.utc().format("MM");
    const currentYear = moment.utc().format("YYYY");
    if(firstBillingPeriodModal) {
      const previousMonth = moment
        .utc(moment().subtract(1, "month"))
        .format("MM");
      setMinDate(new Date(moment.utc(currentYear + "-" + previousMonth + "-16").format("YYYY-MM-DD"+"T00:00:00")));
      setAllocationDate(new Date(moment.utc(currentYear + "-" + previousMonth + "-16").format("YYYY-MM-DD"+"T00:00:00")))
      setMaxDate(new Date(moment.utc(currentYear + "-" + currentMonth + "-15").format("YYYY-MM-DD"+"T00:00:00")));
    } else {
      const lastDayOfMonth = moment.utc().endOf("month").format("YYYY-MM-DD");
      setMinDate(new Date(moment.utc(currentYear + "-" + currentMonth + "-1").format("YYYY-MM-DD"+"T00:00:00")));
      setAllocationDate(new Date(moment.utc(currentYear + "-" + currentMonth + "-1").format("YYYY-MM-DD"+"T00:00:00")))
      setMaxDate(new Date(moment.utc(lastDayOfMonth).format("YYYY-MM-DD"+"T00:00:00")));
    }
  }, [projects])

  const validateDescription = (value) => {
    let string = String(value).trim();
    if (string.length < 5)
      return intl.formatMessage({
        id: "modal.required.characters.min",
        defaultMessage: "Description must be at least 5 characters",
      });
  };

  const handleDate = (date, errors) => {
    setStartDate(date);
    errors.date = undefined;
  }

  useEffect(() => {
    if (createAt) {
      // Inserting UTC date into edit timesheet & creating timesheets from alerts.
      setStartDate(createUTCDate(createAt));
    } else if (selectedDate) {
      // Inserting UTC date into edit timesheet & creating timesheets from alerts.
      setStartDate(createUTCDate(selectedDate));
    } else {
      // If no date props are passed, then we will have a "today" date.
      setStartDate(Date.now());
    }
    let hours = moment.utc(startTime, "HH:mm A").get("hours");
    let minutes = moment.utc(startTime, "HH:mm A").get("minutes");
    const total = moment(moment.utc(endTime, "HH:mm A"))
      .subtract(hours, "hours")
      .subtract(minutes, "minutes")
      .format("hh:mm A");
    let totalTime = total;
    setTotalTime(totalTime);
  }, []);

  useEffect(() => {
    let mounted = true;
    projectService
        .getProjects(auth.user.id, auth.user.token)
      .then((items) => {
        if (mounted) {
          setProjects(items);
        }
      })
      .catch((error) => {console.log(error)});
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let countTags = userTags.length;
    if (countTags > 10) {
      setTagsInvalidNumber(true);
    } else {
      setTagsInvalidNumber(false);
    }
  }, [userTags, setUserTags]);

  Yup.addMethod(Yup.string, "taskMinLength", function (errorMessage) {
    return this.test(`test-text-box-length`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        (value && value.trim().length > 4) ||
        createError({
          path,
          message: intl.formatMessage({
            id: "modal.required.characters.min",
            defaultMessage: "Description must be at least 5 characters",
          }),
        })
      );
    });
  });

  Yup.addMethod(Yup.string, "taskMaxLength", function (errorMessage) {
    return this.test(`test-text-box-length`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        (value && value.trim().length < 121) ||
        createError({
          path,
          message: intl.formatMessage({
            id: "modal.required.characters.max",
            defaultMessage: "Description must have as maximum 120 characters",
          }),
        })
      );
    });
  });

  const NewTimesheetSchema = Yup.object().shape({
    type: Yup.number(),
    project: Yup.number()
      .min(
        1,
        intl.formatMessage({
          id: "modal.required.field",
          defaultMessage: "This is a required field",
        })
      )
      .required(),
    //feeling: Yup.boolean().required(messages[locale].time_log_feeling_required),
    date: Yup.date()
      .required()
      .min(moment().subtract(30, "days").format("YYYY-MM-DD")),
    workTimeStart: Yup.string().required(
      intl.formatMessage({
        id: "modal.required.field.short",
        defaultMessage: "Required",
      })
    ),
    workTimeEnd: Yup.string().required(
      intl.formatMessage({
        id: "modal.required.field.short",
        defaultMessage: "Required",
      })
    ),
    totalTime: Yup.string().strict(true).required(),
    task: Yup.string()
      .required(
        intl.formatMessage({
          id: "modal.required.field.short",
          defaultMessage: "Required",
        })
      )
      .taskMinLength()
      .taskMaxLength(),
    note: Yup.string(),
    //tags: Yup.string().required(messages[locale].time_log_tags_required),
  });
  const page = "Modal";

  const modalOptions = () =>{
    let permissionOptions =[];

    if(permissions.includes('log-hours')){
      permissionOptions.push({id:1, name:'Time log'});
    }

    permissionOptions.push({id:3, name:'Time off'});

    return permissionOptions;
  }

  return (
    <>
      {!isEditing ? (
        <>
          <Modal.Header closeButton className="modalHeader">
            <FormattedMessage
              id="scheduler.modal.entry.time.log"
              defaultMessage="Add time log entry"
            />
          </Modal.Header>
          <div className="timesheetModalBody">
            <div className="form-group">
              <label htmlFor="type">
                <FormattedMessage
                  id="scheduler.modal.entry.type"
                  defaultMessage="Entry type"
                />
              </label>
              <Row>
                <Col xs={6}>
                  <BootstrapForm.Select
                    size="sm"
                    className="timesheetSelect"
                    value={entryType}
                    onChange={handleChangeModalType}
                    id="entryTypeSelect"
                  >
                    {modalOptions().map(item=>{
                      return <option value={item.id}>
                        {intl.formatMessage({
                          id: `modal.entry.type.option${item.id}`,
                          defaultMessage: item.name,
                        })}
                      </option>
                    })}
                  </BootstrapForm.Select>
                </Col>
              </Row>
            </div>
          </div>
        </>) : (
        <>
          <Modal.Header closeButton className="modalHeader">
            <FormattedMessage
              id="scheduler.modal.edit.entry.time.log"
              defaultMessage="Edit time log entry"
            />
          </Modal.Header>
        </>
      )}
      <hr className="hrModal"></hr>
      <Formik
        initialValues={{
          type: 1,
          project: projectId || 0,
          feeling: true,
          date: createAt || selectedDate || moment.utc().format("YYYY-MM-DD"),
          workTimeStart: startTime,
          workTimeEnd: endTime,
          totalTime: totalTime || 0,
          task: taskDescription,
          note: notes,
        }}
        validationSchema={NewTimesheetSchema}
        onSubmit={async (values, actions) => {
          values.date = startDate;
          values.totalTime = totalTime;
          values.tags = userTags;
          try {
            if (isEditing) {
              const response = await editTimesheet(
                values,
                auth.user.id,
                auth.user.token,
                taskId
              );
              if (response) {
                refreshTask();
                await Swal.fire(
                  intl.formatMessage({
                    id: "modal.time.log.edit.success",
                    defaultMessage:
                      "Your time log has been updated successfully!",
                  }),
                  "",
                  "success"
                );
                scheduler.setUpdateContext(!scheduler.updateContext)
                handleClose();
              }
            } else {
              const response = await userTimesheetsServiceDataFormat(
                values,
                auth.user.id,
                auth.user.token,
                page
              );
              if (response) {
                refreshTask();
                await Swal.fire(
                  intl.formatMessage({
                    id: "modal.time.log.edit.success",
                    defaultMessage:
                      "Your time log has been created successfully!",
                  }),
                  "",
                  "success"
                );
                await scheduler.getTimesheets();
                scheduler.setUpdateContext(!scheduler.updateContext)
                handleClose();
              }
            }
            actions.setSubmitting(false);
          } catch (error) {
            Swal.fire(
              "Something went wrong",
              error.data.message || error.message,
              "error"
            );
            console.log(error);
          }
        }}
        validate={(values) => {
          // I am really sorry to do this. But since how everything
          // has been coded, I am left with no other choice than to do this,
          // or refactor the entire form using useFormik.
          let errors = {};
          if (
            moment
              .utc(values.workTimeStart, "HH:mm A")
              .isBefore(moment.utc(values.workTimeEnd, "HH:mm A"))
          ) {
            let hours = moment
              .utc(values.workTimeStart, "HH:mm A")
              .get("hours");
            let minutes = moment
              .utc(values.workTimeStart, "HH:mm A")
              .get("minutes");
            const total = moment
              .utc(moment.utc(values.workTimeEnd, "HH:mm A"))
              .subtract(hours, "hours")
              .subtract(minutes, "minutes")
              .format("hh:mm A");
            values.totalTime = total;
            setTotalTime(total);
            setTimeframeInvalid(false);
          } else {
            if (values.workTimeStart === 0 && values.workTimeEnd === 0) {
              setTimeframeInvalid(true);
              errors.workTimeStart = "Invalid range";
              errors.workTimeEnd = "Invalid range";
            }
            values.totalTime = 0;
            setTotalTime(0);
          }

          if (tagsInvalidFormat || tagsInvalidNumber) {
            errors.tags = "Tags' error";
          }
          if (
            values.totalTime === 0 &&
            values.workTimeStart &&
            values.workTimeEnd
          ) {
            errors.workTimeStart = "Invalid range";
            errors.workTimeEnd = "Invalid range";
          }
          if (startDate < allocationDate) {
            console.log('error')
            errors.date = "Invalid date";
          };
          return errors;
        }}
      >
        {({touched, errors}) => (
          <Form onChange={handleChangeProject}>
            <>
              <Modal.Body className="timesheetModalBody">
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="project">
                        <FormattedMessage
                          id="modal.time.log.project"
                          defaultMessage="Project*"
                        />
                      </label>
                      <br/>
                      <Field
                        as="select"
                        component="select"
                        name="project"
                        className={`form-control form-select ${
                          errors.project ? "is-invalid" : ""
                        }`}
                      >
                        <option disabled value={0}>
                          {intl.formatMessage({
                            id: "modal.time.log.select.project",
                            defaultMessage: "Select a project",
                          })}
                        </option>
                        {projects.map((project) => {
                          return (
                            <option key={project.id} value={project.id}>
                              {project.name}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="project"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <br/>
                <div className="dateAndTimeframesText">
                  <FormattedMessage
                    id="modal.time.log.date.and.timeframes"
                    defaultMessage="Date and Timeframes"
                  />
                </div>
                <Row>
                  <Col xs={4} id="datePickerDiv">
                    <DatePicker
                      wrapperClassName="datePicker"
                      dateFormat="MM/dd/yyyy"
                      selected={startDate}
                      onChange={(date, errors) => handleDate(date, errors)}
                      minDate={minDate > allocationDate ? minDate : allocationDate}
                      maxDate={maxDate}
                      showDisabledMonthNavigation
                      disabled={isEditing}
                      filterDate={isNotSunday}
                      customInput={<CustomDatePicker/>}
                    />
                    {errors.date ?
                      <small className="validity">
                        <FormattedMessage
                          id="modal.time.log.date.invalid"
                          defaultMessage="Selected date is before allocation date"
                        />
                      </small> : null}
                  </Col>
                  <Col xs={4} id="startTimeDiv">
                    <div className="form-group d-flex align-items-end">
                      <label
                        htmlFor="workTimeStart"
                        className="labelTimeframes"
                      >
                        <FormattedMessage
                          id="modal.time.log.date.from"
                          defaultMessage="From*"
                        />
                      </label>
                      &nbsp;
                      <Field
                        type="time"
                        name="workTimeStart"
                        className={`timeframesField form-control ${
                          errors.workTimeStart ? "is-invalid error" : ""
                        }`}
                      />
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="form-group d-flex align-items-end">
                      <label htmlFor="workTimeEnd" className="labelTimeframes">
                        <FormattedMessage
                          id="modal.time.log.date.to"
                          defaultMessage="To*"
                        />
                      </label>
                      &nbsp;
                      <Field
                        type="time"
                        name="workTimeEnd"
                        className={`timeframesField form-control ${
                          errors.workTimeEnd ? "is-invalid error" : ""
                        }`}
                      />
                    </div>
                  </Col>
                  <div>
                    <small className="validity">
                      {" "}
                      {timeframeInvalid ? (
                        <div>
                          <FormattedMessage
                            id="modal.time.log.date.invalid.interval"
                            defaultMessage="Invalid time interval"
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </small>
                  </div>
                  <div className="timesheetTotalDay">
                    <small>
                      <FormattedMessage
                        id="time_log_time_total"
                        defaultMessage="Total: "
                      />
                      {moment.utc(totalTime, "HH:mm A").get("hours")}h{" "}
                      {moment.utc(totalTime, "HH:mm A").get("minutes")}m{" "}
                    </small>
                  </div>
                </Row>
                <hr></hr>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="task">
                        <FormattedMessage
                          id="modal.time.log.description"
                          defaultMessage="Description*"
                        />
                      </label>
                      <Field
                        type="text"
                        name="task"
                        id="descriptionField"
                        className={`form-control ${
                          touched.task && errors.task ? "is-invalid" : ""
                        }`}
                        placeholder={intl.formatMessage({
                          id: "modal.time.log.description.placeholder",
                          defaultMessage: "Doing regression testing of V3.12.1",
                        })}
                        validate={validateDescription}
                      />
                      <ErrorMessage
                        component="div"
                        name="task"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="note">
                        <FormattedMessage
                          id="modal.time.log.notes"
                          defaultMessage="Notes"
                        />
                      </label>
                      <Field
                        as="textarea"
                        name="note"
                        id="noteField"
                        className={`form-control ${
                          touched.note && errors.note ? "is-invalid" : ""
                        }`}
                        placeholder={intl.formatMessage({
                          id: "modal.time.log.notes.placeholder",
                          defaultMessage: "Additional notes",
                        })}
                      />
                      <ErrorMessage
                        component="div"
                        name="note"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="tags">
                        <FormattedMessage
                          id="modal.time.log.tag"
                          defaultMessage="Tags"
                        />
                      </label>
                      <TagsInput allTags={userTags} setAllTags={setUserTags}/>
                      <ErrorMessage
                        component="div"
                        name="tags"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <div>
                    <small className="validity">
                      {" "}
                      {tagsInvalidFormat ? (
                        <div>
                          <FormattedMessage
                            id="modal.time.log.tags.invalid.format"
                            defaultMessage="Invalid format on tags"
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </small>
                  </div>
                  <div>
                    <small className="validity">
                      {" "}
                      {tagsInvalidNumber ? (
                        <div>
                          <FormattedMessage
                            id="modal.time.log.tags.invalid.number"
                            defaultMessage="Allowed number of tags exceeded"
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </small>
                  </div>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button className="closeButton" onClick={handleClose}>
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button className="submitButton" type="submit">
                  <FormattedMessage
                    id="button.accept"
                    defaultMessage="Accept"
                  />
                </Button>
              </Modal.Footer>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default TimeLogModal;
