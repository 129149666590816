import css from "./Managers.module.scss";
import React, { useState, useEffect } from "react";
import Manager from "./Manager";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import PropTypes from "prop-types";
import getManagers from "../services/managers";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @module
 * @description This function gathers a set of Managers/Titans and display them together in a component
 * @param {Object} type
 * @param {String} title
 * @returns {JSX} Managers
 */

const Managers = ({ type, title }) => {
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const intl = useIntl();
  const context = useAuthContext();

  const titansList = [
    {
      email: "berenicer@bluetrailsoft.com",
      firstName: "Berenice",
      id: 515,
      lastName: "Ramos",
      photo: "assets/userImages/d91a7d00-98e4-11ec-a786-dbfd2a7406ac.png",
      regionName: "Mexico"
    },
    {
      email: "braulior@bluetrailsoft.com",
      firstName: "Braulio",
      id: 512,
      lastName: "Ruiz",
      photo: "assets/userImages/8a0ee480-98e4-11ec-a699-85371229fd21.jpg",
      regionName: "Mexico"
    },
    {
      email: "nicolasa@bluetrailsoft.com",
      firstName: "Nicolás",
      id: 511,
      lastName: "Arigón Perdomo",
      photo: "assets/userImages/733e4930-98e4-11ec-897f-81a966af41e0.jpg",
      regionName: "Uruguay"
    },
    {
      email: "priscillap@bluetrailsoft.com",
      firstName: "Priscilla",
      id: 516,
      lastName: "Perez",
      photo: "assets/userImages/f51c49c0-98e4-11ec-93fc-338c07358f61.jpg",
      regionName: "United States"
    },
    {
      email: "saulf@bluetrailsoft.com",
      firstName: "Saúl",
      id: 514,
      lastName: "Flores Alcala",
      photo: "assets/userImages/b6641320-98e4-11ec-904b-b3cb5bfbb471.jpg",
      regionName: "Mexico"
    },
    {
      email: "alejandrav@bluetrailsoft.com",
      firstName: "Alejandra",
      id: 520,
      lastName: "Valdez Torrico",
      photo: "https://lh3.googleusercontent.com/a-/ACNPEu_MEy16L4nhq82yXJKUtNIUyNnoGR6IJf93VRTZ=s64-p-k-rw-no",
      regionName: "Bolivia"
    },
    {
      email: "sebastianb@bluetrailsoft.com",
      firstName: "Sebastian",
      id: 517,
      lastName: "Balestriere",
      photo: "https://lh3.googleusercontent.com/a-/ACNPEu8uQyN6XjAb9CY3LkNjH45Rfezf7kxbYO9S8imS=s88-w88-h88-c-k",
      regionName: "Argentina"
    },
    {
      email: "danielg@bluetrailsoft.com",
      firstName: "Daniel",
      id: 518,
      lastName: "Garrote",
      photo: "https://lh3.googleusercontent.com/a-/ACNPEu-PZN5_hCRHZkah5V5HrZgZU3el9pfxyG5f7GG2=s88-w88-h88-c-k",
      regionName: "Spain"
    },
  ]

  const managersList = [
    {
      email: "rosalba@bluetrailsoft.com",
      firstName: "Rosalba",
      id: 500,
      lastName: "Reynoso",
      photo: "https://images.ctfassets.net/tvct06g28aw7/coXPatRlD5fM41GDnB1fH/9c393dec410cf99c8f3934094e1f23e3/Rosalba_Reynoso.jpg",
      position: "Chief Executive Officer"
    },
    {
      email: "remiv@bluetrailsoft.com",
      firstName: "Remi",
      id: 501,
      lastName: "Vespa",
      photo: "https://images.ctfassets.net/tvct06g28aw7/6s5WfMEbBHmkGhd94uKDfZ/e83c66dadb2dbaf853b7effe78819291/Remi_Vespa.png",
      position: "President"
    },
    {
      email: "vramk@bluetrailsoft.com",
      firstName: "Vram",
      id: 502,
      lastName: "Kouramajian",
      photo: "https://images.ctfassets.net/tvct06g28aw7/5YN2wGoFvnaHPSGqjLrrgh/e5abd32513d8731314e44d6efb58eeb9/Vram.png",
      position: "Chief Mentor"
    },
    {
      email: "cesarn@bluetrailsoft.com",
      firstName: "Cesar",
      id: 503,
      lastName: "Nicola",
      photo: "https://images.ctfassets.net/tvct06g28aw7/qUj66ukhQd3eIJJWJbYch/99e14b7efc53e3459bd0f49682a1c7a8/Cesar_Nicola.jpg",
      position: "Vice President"
    },
    {
      email: "lorenzop@bluetrailsoft.com",
      firstName: "Lorenzo",
      id: 505,
      lastName: "Peña",
      photo: "https://lh3.googleusercontent.com/a-/ACNPEu9hfl3G4pu8vVY_KEh7NjJQd2kAlItDCQdbfAMb=s88-w88-h88-c-k",
      position: "Country Manager"
    },
    {
      email: "magdalenap@bluetrailsoft.com",
      firstName: "Magdalena",
      id: 508,
      lastName: "Penades",
      photo: "https://lh3.googleusercontent.com/a-/ACNPEu_sW8XSch8a4BQB01oPzdkQamZW8XCGO6j5bjms=s64-p-k-rw-no",
      position: "Country Manager"
    },
    {
      email: "manuelv@bluetrailsoft.com",
      firstName: "Manuel",
      id: 135,
      lastName: "Villa",
      photo: "assets/userImages/2be99b40-98e7-11ec-bd57-7bd305c5ad2c.jpg",
      position: "Country Manager"
    },
  ]


  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (type === 'global'){
        setManagers(managersList);
        setLoading(false);
      } else if (type === 'titan'){
        setManagers(titansList);
        setLoading(false);
      } else if (type === 'local'){
        if (context.userInfo.region === 'Spain') {
          setManagers([managersList[2]]);
          setLoading(false);
        } else if (context.userInfo.region === 'Argentina') {
          setManagers([managersList[6]]);
          setLoading(false);
        } else if (context.userInfo.region === 'Uruguay') {
          setManagers([managersList[5]]);
          setLoading(false);
        } else if (context.userInfo.region === 'Mexico') {
          setManagers([managersList[0], managersList[1], managersList[4]]);
          setLoading(false);
        }
      } else if (type === 'localTitan') {
        if (context.userInfo.region === 'Spain') {
          setManagers([titansList[7]]);
          setLoading(false);
        } else if (context.userInfo.region === 'Argentina') {
          setManagers([titansList[6]]);
          setLoading(false);
        } else if (context.userInfo.region === 'Bolivia') {
          setManagers([managersList[5]]);
          setLoading(false);
        } else if (context.userInfo.region === 'Mexico') {
          setManagers([managersList[4]]);
          setLoading(false);
        } else if (context.userInfo.region === 'Uruguay') {
          setManagers([managersList[2]]);
          setLoading(false);
        } else if (context.userInfo.region === 'United States') {
          setManagers([managersList[3]]);
          setLoading(false);
        }
      }
    }

    // (async function () {
    //   console.log(context)
    //   try {
    //     const response = await getManagers(type, context);
    //
    //     if (isMounted) {
    //       setManagers(response);
    //       setLoading(false);
    //     }
    //   } catch (error) {
    //     setError(error?.message);
    //     setLoading(false);
    //   }
    // })();

    return () => (isMounted = false);
  }, [context, type]);

  return (
    <>
      {managers.length === 0 ? (
        <div className={css.ManagersContainer}>
          <h2 className={css.ManagersTitle}>{title}</h2>

          <div className={css.ManagersGrid}>
            <h2 className={css.ManagersTitle}>
              <FormattedMessage id = "doesnt.exist.managers.titans" defaultMessage = "Doesn't exist {name} in this area."
              values = {{name: title}}/>
            </h2>
          </div>
        </div>
      ) : (
        <div className={css.ManagersContainer}>
          <h2 className={css.ManagersTitle}>{title}</h2>

          <div className={css.ManagersGrid}>
            {managers.map((item) => (
              <Manager key={item.id} item={item} type={type} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

Managers.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Managers;
