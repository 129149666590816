import React, { useContext, useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import { useSchedulerContext } from "../../../context/SchedulerContext";
import { useAuthContext } from "../../../context/AuthContext";
import "./Context.scss";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {FormControl, FormHelperText, MenuItem} from "@mui/material";
import {getLocationsByRegionName} from "../../../services/RoleAccessControl/getUserLocation";
import getUsersByAdmin from "../services/getUserByAdmin";

/**
 * @module
 * @description this component is a dropdown that allows you to select the context of the scheduler
 * @returns {JSX} Context
 */

const Context = (props) => {
  const auth = useAuthContext();
  const scheduler = useSchedulerContext();
  const { context, setContext } = props;
  const { name, setName } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);
  const options = users;
  const {setContextEnumerator} = props;
  const {contextEnumerator} = props;
  const {local} = props;
  const {region} = props;
  const {setLocal} = props;
  const {setRegion} = props;
  const contextEnum = {
    Personal: "Personal",
    Global: "Global",
    Region: auth.userInfo.region,
    Local: auth.userInfo.location,
    Employee: name,
  };

  const {locations, setLocations} = props;
  const {regions, setRegions} = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    scheduler.setSelectedUser(auth.userInfo.id)
    let tempContext= contextEnumerator;
    regions.forEach(item=> {
      if (item.name == e.target.value) setRegion(e.target.value)
    });

    locations.forEach(item=> {
      if (item.name == e.target.value) setLocal(e.target.value)
    });

    setContext(e.target.value);
    setName('name')
    scheduler.setTaskCounter(scheduler.taskCounter+1)
    handleClose();
  };

  const onChange = (option) => {
    scheduler.setSelectedUser(option.id);
    setName(option.firstName + " " + option.lastName);
    setContext(option.firstName + " " + option.lastName);
    handleClose();
  };

  // if (context === "Global" || context === auth.userInfo.region || context === auth.userInfo.location) {
  //   scheduler.setPersonalTickler(false);
  // } else {
  //   scheduler.setPersonalTickler(true);
  // }

  if (context !== "Personal") {
    scheduler.setPersonalTickler(false);
  } else {
    scheduler.setPersonalTickler(true);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : "simple-popover";

  // This effect renders all the users in the first select that appears inside the context selector.
  useEffect(async () => {
    await scheduler.getUsers();
    if (open) {
      let getUsers = await getUsersByAdmin(auth.user.token, auth.user.id);

      setUsers(getUsers);
    }
  }, [open]);

  return (
    <div>
      &nbsp;
      <button id="buttonS" variant="outlined" onClick={handleClick}>
        <i class="fa fa-angle-down" id="Selector"></i>
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorOrigin"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorPosition={{ left: 500 }}
      >
        <FormControl sx={{ p: 2 }} >
          <Select
            id="select"
            options={options}
            getOptionLabel={(option) => option.firstName + " " + option.lastName}
            getOptionValue={(option) => option.id}
            onChange={onChange}
            placeholder={"Search employee"}
            maxMenuHeight={190}
            styles={{
              menu:(baseStyles, )=>({
                ...baseStyles,
                minWidth: '100px'
              })
            }}
          >
          </Select>
          <button
            onClick={handleChange}
            value={contextEnum.Personal}
            className="btn btn-outline-secondary"
            id="contextBtn"
          >
            <FontAwesomeIcon icon={['fas', 'user']} id="contextIcon"/>
            Personal
          </button>
          <button
            onClick={handleChange}
            value={contextEnum.Global}
            className="btn btn-outline-secondary"
            id="contextBtn"
          >
            <FontAwesomeIcon icon={['fas', 'globe']} id="contextIcon"/>
            Global
          </button>

          {regions.map(item => {
            return <button
              onClick={handleChange}
              value={item.name}
              className="btn btn-outline-secondary"
              id="contextBtn"
            >
              <FontAwesomeIcon icon={['fas', 'city']} id="contextIcon"/>
              {item.name}
            </button>
          })}

          {locations.map(item => {
            return <button
              onClick={handleChange}
              value={item.name}
              className="btn btn-outline-secondary"
              id="contextBtn"
            >
              <FontAwesomeIcon icon={['fas', 'home']} id="contextIcon"/>
              {item.name}
            </button>
          })}

          {/*<button*/}
          {/*  onClick={handleChange}*/}
          {/*  value={auth.userInfo.region}*/}
          {/*  className="btn btn-outline-secondary"*/}
          {/*  id="contextBtn"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon icon={['fas', 'city']} id="contextIcon"/>*/}
          {/*  {auth.userInfo.region}*/}
          {/*</button>*/}
          {/*<button*/}
          {/*  onClick={handleChange}*/}
          {/*  value={auth.userInfo.location}*/}
          {/*  className="btn btn-outline-secondary"*/}
          {/*  id="contextBtn"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon icon={['fas', 'home']} id="contextIcon"/>*/}
          {/*  {auth.userInfo.location}*/}
          {/*</button>*/}
        </FormControl>
      </Popover>
    </div>
  );
};

export default Context;
