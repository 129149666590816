import React from "react";
import DayCard from "./DayCard";
import ModalContainer from "../Modal/ModalContainer";
import { DangerTriangleIcon } from "../../Icons";
import "moment/locale/en-gb";
// language switcher feature
import { FormattedMessage, FormattedDate } from "react-intl";

const moment = require("moment");

/**
 * @module
 * @description This component displays the cards for the
 * user's worked projects
 * @param {Object} obj - An Object
 * @param {Object[]} obj.items - User's timesheets
 * @param {Object[]} obj.alerts - Hours worked alerts
 * @param {Function} obj.refreshTask - Function that increases the value of the timesheet counter by one
 * @param {Object[]} obj.holidays - User's holidays
 * @param {Object[]} obj.eventsGlobal - User's global events
 * @param {Object[]} obj.eventsRegion - User's region events
 * @param {Object[]} obj.daysOff - User's days off
 * @returns {JSX} Tickler
 */
const Tickler = ({ context, items, alerts, refreshTask, holidays, eventsGlobal, eventsGlobalContext, eventsRegion, daysOff, selectedMonth, prevMonth, nextMonth, handlePrevMonth, handleNextMonth }) => {

  let redAlerts = alerts.filter((event) => event.alertStr === "⚠");
  let yellowAlerts = alerts?.filter((event) => event.alertStr !== "⚠");

  let newHolidays = holidays
    ? holidays.map((holiday) => {
        let isAlert = yellowAlerts.find(
          (alert) => alert.type === "Holiday" && alert.start === holiday.start
        );
        if (!isAlert) {
          return holiday;
        } else {
          return {};
        }
      })
    : [];

  let newGlobalEvents = eventsGlobal
    ? eventsGlobal.map((event) => {
      let isAlert = yellowAlerts.find(
        (alert) => alert.type === "Event" && alert.start === event.start
      );
      if (!isAlert) {
        return event;
      } else {
        return {};
      }
    })
    : [];

  let newGlobalContextEvents = eventsGlobalContext
    ? eventsGlobalContext.map((event) => {
      let isAlert = yellowAlerts.find(
        (alert) => alert.type === "Event" && alert.start === event.start
      );
      if (!isAlert) {
        return event;
      } else {
        return {};
      }
    })
    : [];

  let newRegionEvents = eventsRegion
    ? eventsRegion.map((event) => {
      let isAlert = yellowAlerts.find(
        (alert) => alert.type === "Event" && alert.start === event.start
      );
      if (!isAlert) {
        return event;
      } else {
        return {};
      }
    })
    : [];

  let newDaysOff = daysOff
    ? daysOff.map((dayOff) => {
        let isAlert = yellowAlerts.find(
          (alert) => alert.type === "Day off" && alert.start === dayOff.start
        );
        if (!isAlert) {
          return dayOff;
        } else {
          return dayOff;
        }
      })
    : [];

  if(context === "Personal" || context === "Employee") {
    items = [...items, ...newHolidays, ...newDaysOff, ...newGlobalEvents, ...newRegionEvents]
  } else if (context === "Global") {
    items = [...newHolidays, ...newGlobalContextEvents];
  } else {
    items = [...newHolidays, ...newRegionEvents];
  }

  items.sort((a, b) => {
    if (
      (a.date ? moment(a.date).format("YYYY-MM-DD") : a.start) >
      (b.date ? moment(b.date).format("YYYY-MM-DD") : b.start)
    )
      return 1;
    if (
      (a.date ? moment(a.date).format("YYYY-MM-DD") : a.start) <
      (b.date ? moment(b.date).format("YYYY-MM-DD") : b.start)
    )
      return -1;
    return 0;
  });
  items = items.reverse();
  if (items) {
    return (
      <div className="timeline">
        <div className="first-and-last-tickler first-tickler">
          <span onClick={handleNextMonth}>
            <FormattedMessage
              id="scheduler.tickler.go.to"
              defaultMessage=" Go to "
            />
            {nextMonth} ↑
          </span>
        </div>
        <div className="red-alerts">
          {redAlerts ? (
            redAlerts.map((event) => {
              let elementDate = moment(event.start).format("YYYY-MM-DD");
              return (
                <div className="day-card red-card">
                  <div className="day-header">
                    <div className="day-title">
                      {moment(event.start).format("ddd, DD")}
                    </div>
                  </div>
                  <div>
                    <span style={{ fontSize: "16px" }}>
                      <DangerTriangleIcon fillColor="#ff0101" />{" "}
                    </span>
                    <FormattedMessage
                      id="alert.noreport"
                      defaultMessage="Ops... you haven't reported hours this day, please add them."
                    />
                    &nbsp;
                    {elementDate >=
                    moment().subtract(1, "M").format("YYYY-MM-DD") ? (
                      <ModalContainer
                        contexts={context}
                        refreshTask={refreshTask}
                        selectedDate={moment(event.start).format("YYYY-MM-DD")}
                        buttonText={
                          <span
                            style={{
                              color: "#16448a",
                              textDecoration: "underline",
                            }}
                          >
                            <FormattedMessage
                              id="scheduler.tickler.add.hours"
                              defaultMessage="Add missing entries now"
                            />
                          </span>
                        }
                      />
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : (
            <h3>
              <FormattedMessage id="no_alerts" />
            </h3>
          )}
        </div>
        {items.map((element) => {
          if (
            element.descriptions &&
            element.descriptions.length === 1 &&
            element.descriptions[0].isFinished === false
          ) {
            return null;
          } else {
            if (element.start || element.descriptions)
              return (
                <DayCard
                  item={element}
                  key={element.id}
                  yellowAlerts={yellowAlerts}
                  refreshTask={refreshTask}
                  isTopTickler={false}
                />
              );
          }
        })}
        <div className="first-and-last-tickler last-tickler">
          <span onClick={handlePrevMonth}>
            <FormattedMessage
              id="scheduler.tickler.go.to"
              defaultMessage=" Go to "
            />
            {prevMonth} ↓
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="timeline">
        <div className="first-and-last-tickler first-tickler">
          <FormattedMessage id="next_month_sign" />
          {selectedMonth},
          <span onClick={handleNextMonth}>
            <FormattedMessage id="go_to" />
            {nextMonth} ↑
          </span>
        </div>
        <div>
          <h3>
            <FormattedMessage id="no_timesheets_found" />
          </h3>
        </div>
        <div className="first-and-last-tickler last-tickler">
          <FormattedMessage id="prev_month_sign" />
          {selectedMonth},
          <span onClick={handlePrevMonth}>
            <FormattedMessage id="go_to" />
            {prevMonth} ↓
          </span>
        </div>
      </div>
    );
  }
};

export default Tickler;
