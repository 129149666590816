import GrayCalendarIcon from "../../Icons/GrayCalendarIcon";
import ModalContainer from "../Modal/ModalContainer";
import Context from "../Context/Context";
import {useEffect, useState} from "react";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// language switcher feature
import { FormattedMessage } from "react-intl";
import { useAuthContext } from "../../../context/AuthContext";
import moment from "moment";
import {getLocationsAndRegions} from "../../../services/RoleAccessControl/getUserLocation";

/**
 * @module
 * @description this component displays the calendar icon and the
 * add timesheet component
 * @param addTask
 * @param  taskCount
 * @returns {JSX} Header
 */

const Header = (props) => {
  const auth = useAuthContext();
  const { addTask, setAddTask } = props;
  const { taskCount, setTaskCount } = props;
  const { context, setContext } = props;
  const { name, setName } = props;
  const [firstBillingPeriod, setFirstBillingPeriod] = useState(false);
  const [permissions,setPermissions] = useState([]);
  const { user } = useAuthContext();
  const [locations, setLocations] = useState([]);
  const [regions, setRegions] = useState([]);
  const {setContextEnumerator} = props;
  const {contextEnumerator} = props;
  const {local} =props;
  const {region} = props;
  const {setLocal} =props;
  const {setRegion} = props;

  useEffect(() => {
    if(moment().format("D") < 16) {
      setFirstBillingPeriod(true);
    };
  });

  useEffect(async ()=>{
    let permission = user.permissions.userPermissions;
    let permissionsArray = []
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
    let userLocations= await getLocationsAndRegions(auth.user.token, auth.userInfo.id);
    let userRegions = userLocations.filter(item => !item.id);
    userLocations = userLocations.filter(item => item.id);
    setLocations([...userLocations])
    setRegions([...userRegions])
  },[])

  return (
    <>
      <div className="scheduler-header">
        <div className="styleDiv">
          <GrayCalendarIcon />
          <h4>
            <FormattedMessage
              id="scheduler.header.left.txt"
              defaultMessage="Scheduler and Log"
            />
          </h4>
        </div>

        <div className="styleDiv1">
          <h5 id="context-title">
            <FormattedMessage
              id="scheduler.header.context"
              defaultMessage="Context: "
            />
          </h5>
          <h5>
            &nbsp;
            { context === name ? <FontAwesomeIcon icon={['fas', 'user']}/> : <></> }
            { context === "Personal" ? <FontAwesomeIcon icon={['fas', 'user']}/> : <></> }
            { context === "Global" ? <FontAwesomeIcon icon={['fas', 'globe']}/> : <></> }
            { context === region ? (
              <FontAwesomeIcon icon={['fas', 'city']}></FontAwesomeIcon>
            ) : (
              <></>
            )}
            { context === local ? <FontAwesomeIcon icon={['fas', 'home']}/> : <></> }
            &nbsp;
            { context }
            { context === "Personal" ? " (you)" : <></> }
          </h5>
          <Context
            setLocal={setLocal}
            setRegion={setRegion}
            local={local}
            region={region}
            contextEnumerator={contextEnumerator}
            setContextEnumerator={setContextEnumerator}
            context={context}
            setContext={setContext}
            name={name}
            setName={setName}
            locations={locations}
            regions={regions}
          />
        </div>

        <ModalContainer
          contexts={context}
          name={name}
          refreshTask={addTask}
          count={taskCount}
          buttonText={
            <FormattedMessage
              id="scheduler.header.add.entry"
              defaultMessage="Add entry manually"
            />
          }
          firstBillingPeriodModal={firstBillingPeriod}
        />
      </div>
    </>
  );
};

export default Header;
