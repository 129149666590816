import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { environment } from "../../../config/environment";
import scss from "./Profile.module.scss";
import Dropdown from "../../shared/Dropdown";
import { ProfileIcon, SettingIcon, LogOutIcon } from "../../Icons/index";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Tutorial from "../../Icons/Tutorial";
import FirstTutorial from "../FirstTutorial/FirstTutorial";
import {FormattedMessage, useIntl} from "react-intl";
//This component Renders the Profile picture with a menu to navigate through all the profile settings

/**
 * @module
 * @description This component Renders the Profile picture with a menu to navigate through all the profile settings
 * @returns {JSX} Profile
 */
const Profile = () => {
  const context = useAuthContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const [outsideClick, setOutsideClick] = useState(false);
  const [tutorialClick, setTutorialClick] = useState(false);
  const [isDonev, setIsDonev] = useState();
  const skip = true;
  const info = context?.userInfo?.photo;
  const navigate = useNavigate();
  const intl = useIntl();

  let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${info}`;
  if (info?.slice(0, 4) === "http") {
    userPhoto = info;
  }

  // Close menu Apps
  const hideDropdown = () => {
    setShowDropdown(false);
    setOutsideClick(true);
  };
  // useEffect(() => {
  //   if (tutorialClick === true) {
  //     setTutorialClick(false);
  //   }
  // }, []);
  useEffect(() => {
    const myTimer = setTimeout(() => {
      setOutsideClick(false);
    }, 100);
    return () => {
      clearTimeout(myTimer);
    };
  }, [outsideClick]);

  function logout() {
    context.logout();
  }
  function handleChange(value) {
    setTutorialClick(false);
    setIsDonev(value);
  }

  return (
    <>
      <div
      id="profile"
        className={scss.profileContainer}
        onClick={!outsideClick ? () => setShowDropdown(!showDropdown) : null}
      >
        <div
          className={scss.profilePictureContainer}
          data-tip={intl.formatMessage({id : "topbar.profile", defaultMessage : "Profile"})}
          data-for="profile"
        >
          <svg
            className={scss.profilePicture}
            alt="profilePic"
            src={userPhoto}
            style={{ backgroundImage: `url(${userPhoto})` }}
          ></svg>
          <ReactTooltip id="profile" place="bottom" backgroundColor="#3FAA58" />
        </div>

        {showDropdown ? (
          <div className={scss.drop}>
            <Dropdown hideDropdown={hideDropdown} showDropdown={showDropdown}>
              <Dropdown.Item>
                <div className={scss.profileIcon}>
                  <FormattedMessage id = "topbar.profile.my.profile" defaultMessage = "My profile " /><ProfileIcon />
                </div>
              </Dropdown.Item>

              <Dropdown.Item>
                <div className={scss.settingsIcon}>
                <FormattedMessage id = "topbar.profile.settings" defaultMessage = "Settings " /><SettingIcon />
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <hr />
                <div
                  className={scss.tutorialIcon}
                  onClick={() => setTutorialClick(true)}
                >
                  <FormattedMessage id = "topbar.profile.see.tutorial" defaultMessage = "See tutorial " /><Tutorial />
                </div>
              </Dropdown.Item>

              <Dropdown.Item>
                <hr />
                <div className={scss.logoutIcon} onClick={() => logout()}>
                <FormattedMessage id = "topbar.profile.log.out" defaultMessage = "Log Out " />
                  <LogOutIcon />
                </div>
              </Dropdown.Item>
            </Dropdown>
          </div>
        ) : (
          <></>
        )}
      </div>
      {tutorialClick === true || isDonev === false ? (
        <div className={scss.tutorialContainer}>
          <FirstTutorial handleChange={handleChange} skip={skip}/>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Profile;
