import HappeningNext from "../../Projects/HappeningNext";
import css from "./WhatIsNext.module.scss";
import { FormattedMessage } from "react-intl";

const WhatIsNext = (props) => {
  return (
    <>
      <div className={css.whatIsNextContainer}>
        <h2 className={css.whatIsNextTitle}><FormattedMessage id ="what.is.happening.next"
        defaultMessage = "What is happening next?"/></h2>
        <div className={css.articles}>
          <HappeningNext region={props.region} />
        </div>
      </div>
    </>
  );
};

export default WhatIsNext;
