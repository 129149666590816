import image from "../../assets/BTS-Logo/bts-logo.svg";
import WelcomeSignUp from "../../components/Core/Welcome/WelcomeSignUp";
import "./SignUp.scss";
import Video from "../../components/SignUp/Video/Video";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import Skills from "../../components/SignUp/Skills/Skills";
import Field from "../../components/SignUp/Select/Field";
import Locations from "../../components/SignUp/Select/Locations";
import Position from "../../components/SignUp/Select/Position";
import Seniority from "../../components/SignUp/Select/Seniority";
import getCompanyInfo from "../../components/Core/services/retrieveCompanyInfo";
import { userAuthServices } from "../../services/Login/userAuthServices";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import btsLogo from "../../assets/BTS-Logo/bts-logo.svg";
import ActionButton from "../../components/shared/ActionButton";
import { useIntl } from "react-intl";

function SignUp() {
  const context = useAuthContext();
  const navigate = useNavigate();
  const intl = useIntl();
  const MySwal = withReactContent(Swal);

  // Checking for user information that is supposed not to be here in this page
  // because the user is already signed up if he has that information.
  useEffect(() => {
    if (context?.userInfo) {
      const from = "/";
      navigate(from, { replace: true });
      // Already registered, sending information back
      MySwal.fire({
        title: "Redirecting back",
        text: "You're already signed up",
        imageUrl: btsLogo,
        imageWidth: 150,
        imageHeight: 150,
        imageAlt: "BTS Logo",
        backdrop: `
        rgba(0,0,0,0.9)`,
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }, []);

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    let isMounted = true;

    (async function () {
      try {
        const info = await userAuthServices.userInfo(
          context?.user.id,
          context?.user.token
        );

        if (isMounted) setUserInfo(info.data);
      } catch (error) {
        console.log("Error");
      }
    })();

    return () => (isMounted = false);
  }, []);

  const [field, setField] = useState("");
  const [fieldList, setFieldlist] = useState([]);
  const [fieldError, setFieldError] = useState("");
  const [fieldAux, setFieldaux] = useState("");

  const [location, setLocation] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [locationError, setLocationError] = useState("");
  const [locationAux, setLocationaux] = useState("");

  const [position, setPosition] = useState("");
  const [positionList, setPositionList] = useState([]);
  const [positionError, setPositionError] = useState("");

  const [seniority, setSeniority] = useState("");
  const [seniorityList, setSeniorityList] = useState([]);
  const [seniorityError, setSeniorityError] = useState("");
  const [seniorityAux, setSeniorityaux] = useState(31);

  const [skills, setSkills] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [skillsError, setSkillsError] = useState("");

  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const fieldsWithSeniority = ['Customer Support', 'Development', 'Graphical Design', 'Highly Technical / Office of', 'Infrastructure', 'Quality Assistance', 'Project Management', 'Product Management', 'Sales', 'Talent Acquisition'];
  const fieldsWithSkills = ['Customer Support', 'Development', 'Graphical Design', 'Highly Technical / Office of', 'Infrastructure', 'Quality Assistance', 'Project Management', 'Product Management'];

  useEffect(() => {
    let isMounted = true;

    (async function () {
      try {
        const companyFields = await getCompanyInfo(
          context,
          "fields",
          setFieldlist,
          setFieldError
        );
        const companyLocations = await getCompanyInfo(
          context,
          "locations",
          setLocationList,
          setLocationError
        );
        const companyPositions = await getCompanyInfo(
          context,
          "positions",
          setPositionList,
          setPositionError
        );
        const companySeniorities = await getCompanyInfo(
          context,
          "seniorities",
          setSeniorityList,
          setSeniorityError
        );
        const companySkills = await getCompanyInfo(
          context,
          "skills",
          setSkillsList,
          setSkillsError
        );

        if (isMounted) {
          setFieldlist(companyFields);
          setLocationList(companyLocations);
          setPositionList(companyPositions);
          setSeniorityList(companySeniorities);
          setSkillsList(companySkills);
        }
      } catch (err) {
        console.log("Error: " + err?.message);
      }
    })();

    return () => (isMounted = false);
  }, [context]);

  function liftStateSkills(skill) {
    setSkills(skill);
  }

  function liftState(e) {
    let name = e.target.name;
    let value = e.target.value;

    switch (name) {
      case "field": {
        setField(value);
        for (const x in fieldList) {
          if (value === fieldList[x].name) {
            setFieldaux(fieldList[x].id);
          }
        }
        break;
      }
      case "location": {
        setLocation(value);
        for (const x in locationList) {
          if (value === locationList[x].name) {
            setLocationaux(locationList[x].id);
          }
        }
        break;
      }
      case "position": {
        setPosition(value);
        break;
      }
      case "seniority": {
        setSeniority(value);
        for (const x in seniorityList) {
          if (value === seniorityList[x].name) {
            setSeniorityaux(seniorityList[x].id);
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        description:
          "Please, change your description and tell us something about yourself",
        companyField: fieldAux,
        companySeniority: seniorityAux,
        companyPosition: position,
        companyLocation: locationAux,
        skills: skills.items,
        userId: context?.user?.id,
      };

      // Checking if other properties but skills are OK.
      for (let property in body) {
        if (!body[`${property}`] && fieldsWithSeniority.includes(field)) {
          throw Object.assign(new Error(), {
            code: 400,
            message: "Please, check if every field is complete and try again",
          });
        }
      }

      // Checking if the user has chosen at least one skill.
      if (fieldsWithSkills.includes(field) && !body.skills.length) {
        throw Object.assign(new Error(), {
          code: 400,
          message: "Please, select at least one skill",
        });
      }
      if (termsAndConditions === false) {
        throw Object.assign(new Error(), {
          code: 400,
          message: "Please, accept to the terms and conditions to continue",
        });
      }

      // Attempting to register while calling a callback function to redirect ourselves in case it is successful.
      await context.googleSignUp(body, () => {
        const from = "/";
        navigate(from, { replace: true });

        MySwal.fire({
          title: "Success",
          text: "We are pleased to have you in!",
          imageUrl: btsLogo,
          imageWidth: 150,
          imageHeight: 150,
          imageAlt: "BTS Logo",
          backdrop: `
          rgba(0,0,0,0.9)`,
          timer: 2000,
          showConfirmButton: false,
        });
      });

      const gToken = localStorage.getItem('googleToken');
      await context.googleLogin(gToken)

    } catch (error) {
      MySwal.fire({
        title: "Oops",
        text: error.message,
        icon: "error",
        backdrop: `
        rgba(0,0,0,0.9)`,
        timer: 2000,
        showConfirmButton: true,
      });
    }
  };

  return (
    <div className="main-container">
      <div className="header">
        <img src={image} alt="logo" />
        <WelcomeSignUp name={userInfo?.firstName} />
      </div>
      <div className="container1">
        <div className="titles1">
          <div className="circle-n">
            <h4 className="text">1</h4>
          </div>
          <h4 className="subtitle">Get to know your new second home</h4>
        </div>

        <Video />
      </div>
      <div className="container2">
        <div className="titles2">
          <div className="circle-n">
            <h4 className="text">2</h4>
          </div>
          <h4 className="subtitle">Let us know you better</h4>
        </div>

        <form className="cards" onSubmit={(e) => handleSubmit(e)}>
          <div className="card1">
            <Field
              name={"field"}
              liftState={liftState}
              currentValue={field}
              fieldList={fieldList}
            />
            <Locations
              name={"location"}
              liftState={liftState}
              currentValue={location}
              locationList={locationList}
            />
            <Position
              name={"position"}
              liftState={liftState}
              currentValue={position}
              positionList={positionList}
              company_field={field}
              company_field_id={fieldAux}
            />
            {fieldsWithSeniority.includes(field) ?
              <Seniority
              name={"seniority"}
              liftState={liftState}
              currentValue={seniority}
              seniorityList={seniorityList}
              /> : null
            }
          </div>
          <div className="main">
            <div className="card2">
              {fieldsWithSkills.includes(field) ?
              <div>
                <label className="label">What skills do you know?</label>
                <div className="content">
                  <Skills
                    name={"skills"}
                    liftStateSkills={liftStateSkills}
                    currentValue={skills}
                    skillsList={skillsList}
                  />
                </div>
              </div>
                :
                <div hidden>
                  <Skills
                    name={"skills"}
                    liftStateSkills={liftStateSkills}
                    currentValue={skills}
                    skillsList={skillsList}
                  />
                </div>
              }
              <div className="terms">
                <div className="check">
                  <input
                    type="checkbox"
                    onClick={() => {
                      setTermsAndConditions(!termsAndConditions);
                    }}
                    checked={termsAndConditions}
                    className="checkbox"
                  ></input>
                  <label>
                    I accept to the{" "}
                    <Link to="/terms" target="_blank" rel="noopener noreferrer">
                      terms and conditions
                    </Link>
                  </label>
                </div>
                <div className="btn-container1">
                  <ActionButton secondary="tertiary" type="submit">
                    Continue
                  </ActionButton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
