import moment from "moment";
import "./popOver.scss";
import ModalContainer from "../Modal/ModalContainer";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// language switcher feature
import { useIntl } from "react-intl";
import { useAuthContext } from "../../../context/AuthContext";

/**
 * @module
 * @description this Component that renders a pop over with options
 * on every day in the calendar
 * @param {Style} left, top
 * @param {Date} date
 * @param refreshTaskPoP
 * @param countPoP
 * @param closePop
 * @returns {JSX} PopOverOptions
 */

let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};
export default function PopOverOptions({
  context,
  leftP,
  topP,
  date,
  refreshTaskPoP,
  countPoP,
  closePop,
  show,
  popOverClick,
  firstBillingPeriod,
}) {
  const auth = useAuthContext();
  const [currentDate, setCurrentDate] = useState();
  const contextEnum = {
    Personal: "Personal",
    Global: "Global",
    Region: auth.userInfo.region,
    Local: auth.userInfo.location,
  };
  const selectedDate = moment.utc(date).format("DD");
  const selectedMonth = moment.utc(date).format("MM");
  const currentMonth = moment.utc().format("MM");
  const previousMonth = moment
    .utc(moment.utc().subtract(1, "month"))
    .format("MM");
  const lastDayOfMonth = moment.utc().endOf("month").format("YYYY-MM-DD");
  const [permissions,setPermissions] = useState([]);
  const { user } = useAuthContext();
  const intl = useIntl();

  useEffect(() => {
    setCurrentDate(moment.utc().format("YYYY-MM-DD"));
  });

  let domNode = useClickOutside(() => {
    closePop();
  });

  useEffect(()=>{
    let permission = user.permissions.userPermissions;
    let permissionsArray = []
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <>
      <div
        className="pop-over"
        style={{
          position: "fixed",
          left: leftP + 45,
          top: topP,
          display: "flex",
          zIndex: 1,
        }}
        ref={domNode}
      >
        {context === contextEnum.Personal ? (
          firstBillingPeriod ? (
            currentDate < date ? (
              selectedMonth === currentMonth && selectedDate < 16 ? (
                <>
                  <div className="triangle-right-up"></div>
                  <div className="square">
                    <div className="options">
                      {permissions.includes('log-hours')?
                        <>
                          <div className="pop-button">
                            <ModalContainer
                              contexts={context}
                              selectedDate={date}
                              refreshTask={refreshTaskPoP}
                              count={countPoP}
                              myOnClose={closePop}
                              type={"1"}
                              buttonText={intl.formatMessage({
                                id: "pop.up.time.add",
                                defaultMessage: "Add worked hours",
                              })}
                              modalDisplayed={popOverClick}
                              popOn={show}
                              firstBillingPeriodModal={firstBillingPeriod}
                            />
                          </div>
                        </>:null}
                          <div className="pop-button">
                            <ModalContainer
                              contexts={context}
                              selectedDate={date}
                              refreshTask={refreshTaskPoP}
                              count={countPoP}
                              myOnClose={closePop}
                              type={"3"}
                              buttonText={intl.formatMessage({
                                id: "pop.up.illness.time.add",
                                defaultMessage: "Request time off",
                              })}
                              modalDisplayed={popOverClick}
                              popOn={show}
                              firstBillingPeriodModal={firstBillingPeriod}
                            />
                          </div>
                    </div>
                  </div>
                </>
              ) : (
                <> </>
              )
            ) : (selectedMonth === previousMonth && selectedDate > 15) ||
              selectedMonth === currentMonth ? (
              <>
                <div className="triangle-right-up"></div>
                <div className="square">
                  <div className="options">
                    {permissions.includes('log-hours')?
                      <>
                        <div className="pop-button">
                          <ModalContainer
                            contexts={context}
                            selectedDate={date}
                            refreshTask={refreshTaskPoP}
                            count={countPoP}
                            myOnClose={closePop}
                            type={"1"}
                            buttonText={intl.formatMessage({
                              id: "pop.up.time.add",
                              defaultMessage: "Add worked hours",
                            })}
                            modalDisplayed={popOverClick}
                            popOn={show}
                            firstBillingPeriodModal={firstBillingPeriod}
                          />
                        </div>
                      </>:null}
                        <div className="pop-button">
                          <ModalContainer
                            contexts={context}
                            selectedDate={date}
                            refreshTask={refreshTaskPoP}
                            count={countPoP}
                            myOnClose={closePop}
                            type={"3"}
                            buttonText={intl.formatMessage({
                              id: "pop.up.illness.time.add",
                              defaultMessage: "Add illness time off",
                            })}
                            modalDisplayed={popOverClick}
                            popOn={show}
                            firstBillingPeriodModal={firstBillingPeriod}
                          />
                        </div>
                  </div>
                </div>
              </>
            ) : (
              <> </>
            )
          ) : currentDate < date && date <= lastDayOfMonth ? (
            <>
              <div className="triangle-right-up"></div>
              <div className="square">
                <div className="options">
                  {permissions.includes('log-hours')?
                    <>
                      <div className="pop-button">
                        <ModalContainer
                          contexts={context}
                          selectedDate={date}
                          refreshTask={refreshTaskPoP}
                          count={countPoP}
                          myOnClose={closePop}
                          type={"1"}
                          buttonText={intl.formatMessage({
                            id: "pop.up.time.add",
                            defaultMessage: "Add worked hours",
                          })}
                          modalDisplayed={popOverClick}
                          popOn={show}
                          firstBillingPeriodModal={firstBillingPeriod}
                        />
                      </div>
                    </>:null}
                      <div className="pop-button">
                        <ModalContainer
                          contexts={context}
                          selectedDate={date}
                          refreshTask={refreshTaskPoP}
                          count={countPoP}
                          myOnClose={closePop}
                          type={"3"}
                          buttonText={intl.formatMessage({
                            id: "pop.up.illness.time.add",
                            defaultMessage: "Request time off",
                          })}
                          modalDisplayed={popOverClick}
                          popOn={show}
                          firstBillingPeriodModal={firstBillingPeriod}
                        />
                      </div>
                </div>
              </div>
            </>
          ) : selectedMonth === currentMonth ? (
            <>
              <div className="triangle-right-up"></div>
              <div className="square">
                <div className="options">
                  {permissions.includes('log-hours')?
                    <>
                      <div className="pop-button">
                        <ModalContainer
                          contexts={context}
                          selectedDate={date}
                          refreshTask={refreshTaskPoP}
                          count={countPoP}
                          myOnClose={closePop}
                          type={"1"}
                          buttonText={intl.formatMessage({
                            id: "pop.up.time.add",
                            defaultMessage: "Add worked hours",
                          })}
                          modalDisplayed={popOverClick}
                          popOn={show}
                          firstBillingPeriodModal={firstBillingPeriod}
                        />
                      </div>
                    </>:null}
                    <div className="pop-button">
                      <ModalContainer
                        contexts={context}
                        selectedDate={date}
                        refreshTask={refreshTaskPoP}
                        count={countPoP}
                        myOnClose={closePop}
                        type={"3"}
                        buttonText={intl.formatMessage({
                          id: "pop.up.illness.time.add",
                          defaultMessage: "Add illness time off",
                        })}
                        modalDisplayed={popOverClick}
                        popOn={show}
                        firstBillingPeriodModal={firstBillingPeriod}
                      />
                    </div>
                </div>
              </div>
            </>
          ) : (
            <> </>
          )
        ) : currentDate <= date ? (
          <>
            <div className="triangle-right-up"></div>
            <div className="square">
              <div className="options">
                <div className="pop-button">
                  <ModalContainer
                    contexts={context}
                    selectedDate={date}
                    refreshTask={refreshTaskPoP}
                    count={countPoP}
                    myOnClose={closePop}
                    type={"2"}
                    buttonText={intl.formatMessage({
                      id: "pop.up.holiday.add",
                      defaultMessage: "Add a holiday",
                    })}
                    modalDisplayed={popOverClick}
                    popOn={show}
                  />
                </div>
                <div className="pop-button">
                  <ModalContainer
                    contexts={context}
                    selectedDate={date}
                    refreshTask={refreshTaskPoP}
                    count={countPoP}
                    myOnClose={closePop}
                    type={"4"}
                    buttonText={intl.formatMessage({
                      id: "pop.up.other.add",
                      defaultMessage: "Add other event",
                    })}
                    modalDisplayed={popOverClick}
                    popOn={show}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

PopOverOptions.propTypes = {
  currentDate: PropTypes.string,
};
