import "./ProjectHeader.scss";
import { useEffect, useState } from "react";
import LinkExterno from "./LinkExterno";
import { getStatusIcon } from "../../utils/getProjectStatusIcon";
import { getMoodsIcon } from "../../utils/getMoodsIcon";
import { getSatisfactionIcon } from "../../utils/getSatisfactionIcon";
import { Link } from "react-router-dom";
import { MyProjects } from "../Icons/index";
import ReactTooltip from "react-tooltip";
import {FormattedMessage, useIntl} from "react-intl";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import { useContext } from "react";

/**
 * @module
 * @description This function creates the structure and the logic of Project Header
 * @param {number} id get id project
 * @param {Function} getProjectHeader  get Project Header
 * @param {Function} getLatestUpdates  get Latest Updates
 * @returns {JSX} ProjectHeader
 */

const ProjectHeader = ({ id, getProjectHeader, getLatestUpdates }) => {
  const [headerData, setHeaderData] = useState({});
  const [updates, setUpdates] = useState([]);
  // const [lastMonthUpdates, setLastMonthUpdates] = useState(0);

  const [loading, setLoading] = useState(false);
  const { auth } = useContext(ProjectsContext);
  const intl = useIntl();

  useEffect(() => {
    setLoading(true);
    getProjectHeader(id)
      .then((res) => {
        setHeaderData(res.data.data[0]); //get the res to validate the info
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      }); //do something if some went wrong

    getUpdatesInfo();
  }, []);

  const { projectKey, projectName, clientName, statusId } = headerData;

  // refactor this - pass it to the right component
  const getUpdatesInfo = async () => {
    setLoading(true);

    getLatestUpdates(id)
      .then((res) => {
        setUpdates(res.data.data[0]);
        // setLastMonthUpdates(res.data.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.error(
          err,
          "Something went wrong while getting the latest updates"
        )
      }
      ); //do something if some went wrong
  };

  const { updatesIn30Days, teamMoodId, projectStatusId, clientSatisfactionId } =
    updates;

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="projectHeader">
          <div className="mainData">
            <h1>
              <Link to="/projects" id="#forward">
                <div className="folder">
                  <MyProjects />
                </div>
              </Link>
              <FormattedMessage id ="projects.header.projects" defaultMessage="Projects"/> /{" "}
            </h1>
            <div className="projectName">
              <h1>{projectName} </h1>
            </div>
            <div></div>
            <div className="projectKey">
              <span className="projectKeySpan"> #{projectKey} - </span>
              <LinkExterno url="#" blank={false} text={clientName} />
            </div>
          </div>

          <div className="statusData">
            <div className="status">
              <span
                className="statusIcon"
                data-tip={intl.formatMessage({id : "project.by.id.header.icon1", defaultMessage: "Project last update"})}
                data-for="status"
              >
                {getStatusIcon(projectStatusId)}
              </span>
              <ReactTooltip id="status" place="top" backgroundColor="#3FAA58" />

              <span
                className="statusIcon"
                data-tip={intl.formatMessage({id : "project.by.id.header.icon2", defaultMessage: "Project mood"})}
                data-for="mood"
              >
                {getMoodsIcon(teamMoodId)}
              </span>
              <ReactTooltip id="mood" place="top" backgroundColor="#3FAA58" />

              <span
                className="statusIcon"
                data-tip={intl.formatMessage({id : "project.by.id.header.icon3", defaultMessage: "Client satisfaction"})}
                data-for="clientMood"
              >
                {getSatisfactionIcon(clientSatisfactionId)}
              </span>
              <ReactTooltip
                id="clientMood"
                place="top"
                backgroundColor="#3FAA58"
              />
            </div>
            <div className="updateTitle">
              <h5>
                {" "}
                {updatesIn30Days === 0 || updatesIn30Days > 1
                  ? "updates"
                  : "update"}{" "}
                in the last 30 days{" "}
              </h5>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectHeader;
