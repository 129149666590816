import React, {useEffect, useRef, useState} from "react";
import { Button } from "react-bootstrap";
import { useOutsideAlerter } from "../../hooks";
import "./FloatMenu.scss";
import ReactTooltip from "react-tooltip";
import {useAuthContext} from "../../context";

const FloatMenu = ({
  bottomLeft,
  topRight,
  topLeft,
  floatMenuOpen,
  handleFloatMenu,
  children,
  menuOptions,
}) => {
  const floatMenu = useRef(null);
  // the next hook is used to detect if user clicks outside the menu, then it's closed
  useOutsideAlerter(floatMenu, handleFloatMenu, floatMenuOpen);
  const [options, setOptions] = useState([]);
  const { user } = useAuthContext();

  useEffect(() => {
    let permission = user.permissions.userPermissions;
    let permissionsArray = []
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }

    let temp = menuOptions.filter((option)=>{
      if(!permissionsArray.includes('update-projects')){
        if(option.text==='Add status update'){
          return false;
        }
      }

      if(!permissionsArray.includes('archive')){
        if(option.text==='Archive project'){
          return false;
        }
      }

      if(!permissionsArray.includes('create-players')){
        if(option.text==='Add player'){
          return false;
        }
      }

      if(!permissionsArray.includes('remove-from-project')){
        if(option.text==='Remove from project'){
          return false;
        }
      }

      if(!permissionsArray.includes('manage-allocations')){
        if(option.text==='Edit assignment'){
          return false;
        }
      }

      return true;
    });
    setOptions(temp)
  },[menuOptions])

  return (
    <div className="floatMenu">
      {children}
      <div
        ref={floatMenu}
        className={`menu ${floatMenuOpen ? "opened" : "closed"} ${
          bottomLeft && "bottomLeft"
        } ${topRight && "topRight"} ${topLeft && "topLeft"} `}
      >
        <div className="optionsContainer">
          {options.map((btn, idx) => (
            <div key={idx}>
              {btn.text !== 'View profile' ? (
              <Button
                variant="success"
                onClick={() => {
                  btn.action();
                  handleFloatMenu();
                }}
              >
                {btn.text}
              </Button>) : (
              <>
                <Button id='disabledButton' data-tip='Coming soon' data-for="viewProfile" style={{}}>
                  {btn.text}
                </Button>
                <ReactTooltip id="viewProfile" place="top" backgroundColor="#3FAA58"/>
              </>
              )}
              {btn.addDividerAfter && <div className="divider"></div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FloatMenu;
