import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import React, {useState, useEffect, useRef} from "react";
import PopOverOptions from "../PopOver/PopOverOptions";
import {
  IoChevronBackCircleOutline,
  IoChevronForwardCircleOutline,
} from "react-icons/io5";
import { useSchedulerContext } from "../../../context/SchedulerContext";
import moment from "moment";

/**
 * @module
 * @description This component displays a calendar for the scheduler
 * @param {Object} obj - An Object
 * @param {Object[]} obj.events - Array of objects containing days events
 * @param {Function} obj.onClickNextMonth - Function that changes to the next month
 * @param {Function} obj.onClickPrevMonth - Function that changes to the previous month
 * @param {Function} obj.addTask - Function that increases the value of the timesheet counter by one
 * @param {Number} obj.taskCount - Timesheet counter
 * @returns {JSX} Calendar
 */
export default function Calendar({
  context,
  events,
  onClickNextMonth,
  onClickPrevMonth,
  addTask,
  taskCount, isWheeling, setWheeling, setSchedulerLayoutFocus,
}) {
  const schedulerContext = useSchedulerContext();
  const calendarRef = schedulerContext.calendarRef;
  const popOverRef = React.useRef();
  const [firstBillingPeriod, setFirstBillingPeriod] = useState(false);
  const [editedEvent, setEditedEvent] = useState([]);
  const [editedEventName, setEditedEventName] = useState([...events]);

  useEffect(() => {
    const edited = editTitle(events)
    setEditedEvent([...edited]);
  }, [events])

  const editTitle = (eventList)=>{
    const list =eventList.map(e => {
      if(e.borderColor === '#ff0101'){
        let title = e.alertStr;
        let event = {...e};
        event.title=title;
        return event;
      } else{
        let title = e.title !== null && e.title !== undefined ?  e.title : '';
        title = title.split("=")[0];
        let event = {...e};
        event.title = title;
        return event;
      }
    })
    return list;
  }

  const editTitle1 =(eventList)=>{
  try {
    let temp = [];
    for (let i = 0; i < eventList.length; i++) {
      for (let j = 0; j < eventList.length; j++) {
        if (eventList[i].start === events[j].start) {
          if (eventList[i].type !== "Day off") {
          } else if (eventList[i].type === "Day off") {
            const end = events[i].end;
            if (end !== undefined || end !== null) {
              if (eventList[i].title.length === 0) {
                const tit = eventList[j].title;
                eventList[i].title = tit;
                eventList[j].textColor = eventList[i].borderColor; //eventos
                if (!eventList[i].end && eventList[i].start) {
                  temp.push(eventList[j])
                } else {
                  eventList[i].title = '';
                }
              }
            }
          }
        }
      }
    }

    return [eventList, temp];
   }catch (e){
      console.log(e)
    return null;
  }
  }

  const filterAndMapColor = (eventList, temp)=>{
    let eventReturn = eventList.filter(element =>!temp.includes(element))
    eventReturn = eventReturn.map(element =>{
        if(element.title===null){
          element.title = '';
        }
        //
        if(element.alertStr=== '⚠'){
          element.borderColor = '#ff0101';
          element.textColor = '#ff0101';
        }

        if(element.textColor !== element.borderColor){
          element.borderColor =  element.textColor;
        }
        return element;
      })
    return eventReturn;
  }

  const eventMapTransparent = (eventList) =>{

      let tempEditedEvent = eventList.map(element =>{

        if(!element.type){
          for (let i = 0; i <eventList.length ; i++) {
            if(eventList[i].type){
              element.borderColor = 'transparent';
            }

            if(element.start >= eventList[i].start  && element.start  < eventList[i].end && eventList[i].type){
              element.textColor = eventList[i].borderColor;
              element.borderColor = eventList[i].borderColor;

            }

            if(!eventList[i].end && (element.start  < eventList[i].start || element.start  > eventList[i].end) ){
              element.textColor =parseInt(element.title[0]) < 8 ? "#ef9325" : "#167f45";
              element.borderColor =  parseInt(element.title[0]) < 8 ? "#ef9325" : "#167f45";// antes solo estaba '#167f45'//element.textColor;//element.title[0] < '8' ? "#ef9325" : "#167f45";
            }
          }
        }
        return element;
      });
      return tempEditedEvent;
  };

  const dontOverlatEvents = (eventsList)=>{
    let tempEditedEvent = eventsList.map(element =>{
          if(!element.type){
            for (let i = 0; i <eventsList.length ; i++) {
              if (eventsList[i].type){
                const elementStart = new Date(element.start);
                const rangeStart = new Date(eventsList[i].start);
                const rangeEnd = new Date(eventsList[i].end);

                const elementStartMs = elementStart.getTime();
                const rangeStartMs = rangeStart.getTime();
                const rangeEndMs = rangeEnd.getTime();

                if (eventsList[i].end && elementStartMs >= rangeStartMs && elementStartMs <= rangeEndMs) {
                  const colorTemp = element.textColor;

                  element.textColor = eventsList[i].borderColor;
                  element.borderColor = 'transparent';//element.title[0] < '8' ? "#ef9325" : "#167f45";
                  if (elementStartMs === rangeEndMs) {
                    element.textColor = colorTemp;
                    element.borderColor = colorTemp;//element.title[0] < '8' ? "#ef9325" : "#167f45";
                  }
                }

                if (!eventsList[i].end && element.end && (element.start < eventsList[i].start || element.start > eventsList[i].end)) {
                  element.textColor = element.title[0] < '8' ? "#ef9325" : "#167f45";
                  element.borderColor = element.textColor;//element.title[0] < '8' ? "#ef9325" : "#167f45";
                }
                const evenetDate = eventsList[i].start.length < 10 ? eventsList[i].start : eventsList[i].start.substring(0, 10);

                if (!eventsList[i].end && eventsList[i].start && evenetDate === element.start) {
                  element.borderColor = 'transparent';
                  element.textColor = 'transparent';
                  eventsList[i].title = element.title;
                }
              }else{
                element.borderColor = element.textColor;
              }
            }
          }
          return element;
        });

      return tempEditedEvent;
  }

  const filterDuplicated = (eventList)=>{
    let tempEditedEvent = eventList.filter(e =>{
      if (e.borderColor !== 'transparent' && e.textColor !== 'transparent') return true;
      if(e.borderColor !== 'transparent' || e.textColor !== 'transparent') return true;
      return false;
    });
    const workedHoursDays = tempEditedEvent.filter(e =>{
      if (!e.type) return e.start;
    });
    for (let i = 0; i <tempEditedEvent.length ; i++) {
      for (let j = 0; j < workedHoursDays.length; j++) {

        if(workedHoursDays[j].start >= tempEditedEvent[i].start  && workedHoursDays[j].start< tempEditedEvent[i].end){
          let temp = tempEditedEvent[i];
          temp.title = '';
          tempEditedEvent[i] = temp;
        }
      }
    }

    tempEditedEvent = tempEditedEvent.map(element =>{
      if(element.alertStr=== '⚠'){
        element.borderColor = '#ff0101';
        element.textColor = '#ff0101';
      }
      return element;
    })
    return tempEditedEvent;
  }
  useEffect(()=>{
    try{
      let [tempEvents, temp ] = editTitle1(editedEvent);
      tempEvents =filterAndMapColor(tempEvents, temp);
      tempEvents = eventMapTransparent(tempEvents);
      tempEvents = dontOverlatEvents(tempEvents);
      tempEvents = filterDuplicated(tempEvents)
      setEditedEventName([...tempEvents])
    }catch (e){
      console.log(e)
    }
  }, [editedEvent])

  // useEffect(()=>{
  //
  //   let tempEditedEvent = [...editedEvent];
  //
  //   let temp =[];
  //   for (let i = 0; i < tempEditedEvent.length; i++) {
  //     for (let j = 0; j < tempEditedEvent.length; j++) {
  //       if (tempEditedEvent[i].start === tempEditedEvent[j].start) {
  //         if (tempEditedEvent[i].type !== "Day off") {
  //         } else if (tempEditedEvent[i].type === "Day off") {
  //           const end = tempEditedEvent[i].end;
  //           if (end !== undefined || end !== null) {
  //             if (tempEditedEvent[i].title.length === 0) {
  //               const tit = tempEditedEvent[j].title;
  //               tempEditedEvent[i].title = tit;
  //               tempEditedEvent[j].textColor = tempEditedEvent[i].borderColor; //eventos
  //               if(!tempEditedEvent[i].end && tempEditedEvent[i].start){
  //                 temp.push(tempEditedEvent[j])
  //               }else{
  //                 tempEditedEvent[i].title = '';
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //
  //   tempEditedEvent = tempEditedEvent.filter(element =>!temp.includes(element))
  //   tempEditedEvent = tempEditedEvent.map(element =>{
  //     if(element.title===null){
  //       element.title = '';
  //     }
  //     //
  //     if(element.alertStr=== '⚠'){
  //       element.borderColor = '#ff0101';
  //       element.textColor = '#ff0101';
  //     }
  //     return element;
  //   })
  //
  //   console.log(tempEditedEvent)
  //
  //   tempEditedEvent = tempEditedEvent.map(element =>{
  //
  //     if(!element.type){
  //       for (let i = 0; i <tempEditedEvent.length ; i++) {
  //         if(tempEditedEvent[i].type){
  //           element.borderColor = 'transparent';
  //         }
  //
  //         if(element.start >= tempEditedEvent[i].start  && element.start  < tempEditedEvent[i].end && tempEditedEvent[i].type){
  //           element.textColor = tempEditedEvent[i].borderColor;
  //           element.borderColor = tempEditedEvent[i].borderColor;
  //
  //         }
  //
  //         if(!tempEditedEvent[i].end && (element.start  < tempEditedEvent[i].start || element.start  > tempEditedEvent[i].end) ){
  //
  //           element.textColor = element.title[0] < '8' ? "#ef9325" : "#167f45";
  //           element.borderColor =  '#167f45'//element.textColor;//element.title[0] < '8' ? "#ef9325" : "#167f45";
  //         }
  //       }
  //     }
  //     return element;
  //   });
  //
  //
  //   tempEditedEvent = tempEditedEvent.map(element =>{
  //     if(!element.type){
  //       for (let i = 0; i <tempEditedEvent.length ; i++) {
  //
  //         const elementStart=new Date(element.start);
  //         const rangeStart=new Date(tempEditedEvent[i].start);
  //         const rangeEnd=new Date(tempEditedEvent[i].end);
  //
  //         const elementStartMs = elementStart.getTime();
  //         const rangeStartMs = rangeStart.getTime();
  //         const rangeEndMs = rangeEnd.getTime();
  //
  //         if(tempEditedEvent[i].end && elementStartMs >= rangeStartMs && elementStartMs <= rangeEndMs){
  //           const colorTemp = element.textColor;
  //
  //           element.textColor = tempEditedEvent[i].borderColor;
  //           element.borderColor =  'transparent';//element.title[0] < '8' ? "#ef9325" : "#167f45";
  //           if(elementStartMs === rangeEndMs){
  //             element.textColor = colorTemp;
  //             element.borderColor =  colorTemp;//element.title[0] < '8' ? "#ef9325" : "#167f45";
  //           }
  //         }
  //
  //         if(!tempEditedEvent[i].end  && element.end && (element.start  < tempEditedEvent[i].start || element.start  > tempEditedEvent[i].end) ){
  //           element.textColor = element.title[0] < '8' ? "#ef9325" : "#167f45";
  //           element.borderColor =  element.textColor;//element.title[0] < '8' ? "#ef9325" : "#167f45";
  //         }
  //        const evenetDate= tempEditedEvent[i].start.length<10 ? tempEditedEvent[i].start : tempEditedEvent[i].start.substring(0, 10);
  //
  //         if(!tempEditedEvent[i].end && tempEditedEvent[i].start && evenetDate === element.start){
  //           element.borderColor =  'transparent';
  //           element.textColor =  'transparent';
  //           tempEditedEvent[i].title = element.title;
  //         }
  //       }
  //     }
  //     return element;
  //   });
  //
  //   tempEditedEvent = tempEditedEvent.filter(e =>{
  //     if (e.borderColor !== 'transparent' && e.textColor !== 'transparent') return true;
  //   });
  //   console.log(tempEditedEvent)
  //   const workedHoursDays = tempEditedEvent.filter(e =>{
  //     if (!e.type) return e.start;
  //   });
  //
  //   for (let i = 0; i <tempEditedEvent.length ; i++) {
  //     for (let j = 0; j < workedHoursDays.length; j++) {
  //
  //       if(workedHoursDays[j].start >= tempEditedEvent[i].start  && workedHoursDays[j].start< tempEditedEvent[i].end){
  //         let temp = tempEditedEvent[i];
  //         temp.title = '';
  //         tempEditedEvent[i] = temp;
  //       }
  //     }
  //   }
  //
  //   console.log(tempEditedEvent)
  //   tempEditedEvent = tempEditedEvent.map(element =>{
  //     if(element.alertStr=== '⚠'){
  //       element.borderColor = '#ff0101';
  //       element.textColor = '#ff0101';
  //     }
  //     return element;
  //   })
  //   console.log(tempEditedEvent)
  //   setEditedEventName([...tempEditedEvent])
  // },[editedEvent]);

  const nextMonth = () => {
    onClickNextMonth();
  };

  const prevMonth = () => {
    onClickPrevMonth();
  };

  const [isModalDisplayed, setIsModalDisplayed] = useState(false);

  const handleModalDisplayed = () => {
    setIsModalDisplayed(!isModalDisplayed);
  }

  //If showOptions is true indicates PopOver will render
  const [showPopOverOptions, setShowPopOverOptions] = useState();
  const [x, setX] = useState(0); //mouse x position when click in a day
  const [y, setY] = useState(0); //mouse y position when click in a day
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDateRef, setSelectedDateRef] = useState();

  //It's executed when a day is clicked
  const handleDateClick = (arg) => {
    if (!arg.dayEl.classList[2].includes("sun")) {
      setWheeling(false);
      setShowPopOverOptions(true);

      const bodyBounds = document.body.getBoundingClientRect();
      const elementBounds = arg.dayEl.getBoundingClientRect();

      arg.dayEl.style.background="#5AC775";
      arg.dayEl.style.borderRadius = "8px";
      setSelectedDateRef(arg.dayEl);

      setX(elementBounds.left - bodyBounds.left);
      setY(elementBounds.top - bodyBounds.top);

      setSelectedDate(arg.dateStr);
    }
  };

  const handleOutClick = () => {
    //It's executed when a click event happens to remove popOverOptions
    if (selectedDateRef !== undefined && selectedDateRef !== null) {
      selectedDateRef.style.background='';
    }
    if(!isModalDisplayed) setShowPopOverOptions(false);
  };

  const handleUpdate = () => {
    addTask(taskCount + 1);
  };

  useEffect(() => {
    if(isWheeling) {
      handleOutClick()
    }
  },[isWheeling])

  useEffect(() => {
    if(moment().format("D") < 16) {
      setFirstBillingPeriod(true);
    };
  });

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        customButtons={{
          nextButton: {
            text: <IoChevronForwardCircleOutline />,
            click: function () {
              nextMonth();
            },
          },
          prevButton: {
            text: <IoChevronBackCircleOutline />,
            click: function () {
              prevMonth();
            },
          },
        }}
        expandRows={true}
        aspectRatio={1}
        events={editedEventName}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        fixedWeekCount={false}
        displayEventTime={false}
        showNonCurrentDates={false}
        titleFormat={{ month: "long", year: "numeric" }}
        dateClick={handleDateClick}
        headerToolbar={{
          start: "prevButton",
          center: "title",
          end: "nextButton",
        }}
      />
      {
        //render PopOverOptions
      }
      {(showPopOverOptions) && (!isWheeling || isModalDisplayed) ? (
        <PopOverOptions
          context={context}
          ref={popOverRef}
          leftP={x}
          topP={y}
          date={selectedDate}
          refreshTaskPoP={function () {
            handleUpdate();
          }}
          countPoP={taskCount}
          closePop={function () {
            // if (isModalDisplayed) handleOutClick();
            handleOutClick();
            setSchedulerLayoutFocus();
          }}
          show={setShowPopOverOptions}
          popOverClick={function () {
            handleModalDisplayed();
          }}
          firstBillingPeriod={firstBillingPeriod}
        />
      ) : null}
    </>
  );
}
