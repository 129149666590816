import "./SearchProject.scss";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @module
 * @description This function creates the structure and the logic of Search Project
 * @param {Function} searchValueRef get search Value Ref
 * @param {Function} onSearch get on Search
 * @param {number} projectsList get projects List
 * @param {Function} clearSearch get clear Search
 * @returns {JSX} SearchProject
 */
const SearchProject = ({
  searchValueRef,
  onSearch,
  projectsList,
  clearSearch,
}) => {
  let intl = useIntl();
  const [inputValue, setInputValue] = useState(false);
  let isFiltered = <FormattedMessage id="projects.no.filter" defaultValue="no filter" />;
  if (searchValueRef?.current?.value?.trim().length) {
    isFiltered = <FormattedMessage id="projects.filtered" defaultValue="filtered" />;
  }

  const handleInputValue = (e) => {
    if (e !== "") {
      setInputValue(true);
    } else {
      setInputValue(false);
    }
  };

  return (
    <div className="search-project">
      <div className="search-bar">
        <form onSubmit={onSearch}>
          <FaSearch />
          <input
            type="text"
            placeholder={intl.formatMessage({
              id: "projects.header.input.placeholder",
              defaultMessage: "Search by project, client and/ or players",
            })}
            className="text-field"
            name="search"
            ref={searchValueRef}
            onChange={(e) => {
              handleInputValue(e.target.value);
            }}
          />
        </form>
        {inputValue ? (
          <button
            type="button"
            className="btnclose"
            onClick={() => clearSearch(setInputValue(false))}
          >
            <IoMdClose />
          </button>
        ) : (
          <></>
        )}
      </div>
      <p>
        <FormattedMessage id ="projects.header.input.data" defaultMessage = "{projects} results, {filter}"
        values = {{projects: projectsList.length,
          filter: isFiltered}} />
      </p>
    </div>
  );
};

export default SearchProject;
