import {Navigate, Route, useLocation, Routes, Redirect} from "react-router-dom";
import Layout from "../components/Core/Layout/Layout";
import { useAuthContext } from "../context/AuthContext";
import { useEffect, useState } from "react";

import {
  Login,
  Home,
  ProjectById,
  //Projects,
  Projects2,
  Location,
  Scheduler,
  SignUp,
  News,
  NewsById,
  RolesAndPermissions,
} from "../pages";

import AddNews from "../pages/News/AddNews/AddNews";
import { TermsAndConditions } from "../pages/SignUp/TermsAndConditions";

// it's a permission matrix based in roles (it's just a suggestion it can be modified)
export const permissions = {
  //in this case everyone can see the screen
  fullAccess: ["MANAGER", "DEVELOPER", "TEAM_LEADER"],
  //in the following cases the routes are restricted by roles: (the role names are just examples)
  onlyManagers: ["MANAGER"],
  onlyLeaders: ["TEAM_LEADER"],
  onlyRoot: ["ADMIN"],
};

function AppRouter() {
  const context = useAuthContext();
  const location = useLocation();
  const [isLogged, setIsLogged] = useState(!!context?.user);
  const [completeSignup, setCompleteSignup] = useState(!!context?.userInfo);
  const auth = {
    userData: {
      role: "MANAGER",
    },
  };

  useEffect(() => {
    if (context?.user) {
      setCompleteSignup(context?.userInfo);

      setIsLogged(!!context.user.id);
    } else {
      setIsLogged(false);
    }
  }, [context, isLogged]);
  // TODO: fix permissions shadowing
  const PrivateRoute = ({ children, permissions }) => {
    if (isLogged) {
      if (!completeSignup) {
        return <Navigate to="/signup" />;
      } else {
        if (permissions?.includes(auth.userData.role)) {
          return children;
        } else {
          return <Navigate to={location?.pathname && "/"} />;
        }
      }
    } else {
      return <Navigate to="/login" />;
    }
  };

  const SpecialRoute = ({ children, permissions }) => {
    if (isLogged && !completeSignup) {
      if (permissions?.includes(auth.userData.role)) {
        return children;
      } else {
        return <Navigate to={location?.pathname && "/"} />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  };

  const NotFound = () => <Navigate to="/" />;

  const routes = {
    // ------------------ PUBLIC ROUTES ---------------
    publicRoutes: [
      {
        path: "/login",
        component: Login,
      },
    ],
    //  ------------------ SPECIAL ROUTE ---------------
    specialRoute: [
      {
        path: "/signup",
        component: SignUp,
        permissions: permissions.fullAccess,
      },
      {
        path: "/terms",
        component: TermsAndConditions,
        permissions: permissions.fullAccess,
      },
    ],

    //  ------------------ PRIVATE ROUTES ---------------
    privateRoutes: [
      {
        path: "/",
        component: Home,
        permissions: permissions.fullAccess,
      },
      {
        path: "/news/:id",
        component: NewsById,
        permissions: permissions.fullAccess,
      },
      {
        path: "/scheduler",
        component: Scheduler,
        permissions: permissions.fullAccess,
      },
      {
        path: "/location",
        component: Location,
        permissions: permissions.fullAccess,
      },
      {
        path: "/projects",
        //component: Projects,
        component: Projects2,
        permissions: permissions.onlyManagers,
      },
      {
        path: "/projects/:id",
        component: ProjectById,
        permissions: permissions.onlyManagers,
      },
      {
        path: "/scheduler",
        component: Scheduler,
        permissions: permissions.fullAccess,
      },
      {
        path: "/news/region/:region",
        component: News,
        permissions: permissions.fullAccess,
      },
      {
        path: "/news/addNews/:isGlobal",
        component: AddNews,
        permissions: permissions.fullAccess,
      },
      {
        path: "/roles-and-permissions",
        component: RolesAndPermissions,
        permissions: permissions.fullAccess,
      },
    ],
  };

  // TODO: Instant redirect in case of not logged in user
  return (
    <>
      <Routes>
        {/* PUBLIC ROUTES */}
        {!isLogged &&
          routes.publicRoutes.map((r) => (
            <Route key={r.path} path={r.path} element={<r.component />} />
          ))}
        {routes.specialRoute.map((r) => (
          <Route
            key={r.path}
            path={r.path}
            element={
              <SpecialRoute
                key={r.path}
                path={r.path}
                permissions={r.permissions}
              >
                <r.component />
              </SpecialRoute>
            }
          />
        ))}

        <Route element={<Layout />}>
          {/* PRIVATE ROUTES */}
          {routes.privateRoutes.map((r) => (
            <Route
              path={r.path}
              key={r.path}
              element={
                <PrivateRoute
                  key={r.path}
                  path={r.path}
                  permissions={r.permissions}
                >
                  <r.component />
                </PrivateRoute>
              }
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default AppRouter;
