import React, { useEffect, useState, useRef } from "react";
import Calendar from "../Calendar/Calendar";
import Tickler from "../Tickler/Tickler";
import "./scheduler.scss";
import DaysOffList from "../DaysOffList/DaysOffList";
import { useAuthContext } from "../../../context/AuthContext";
import { useSchedulerContext } from "../../../context/SchedulerContext";
import Header from "../Header/Header";
import { InformationIcon } from "../../../Icons";
import TimeCircle from "../../Icons/TimeCircleIcon";
import data from "../../../version.json";

// language switcher feature
import { FormattedMessage } from "react-intl";

/**
 * @module
 * @description This component displays the calendar, the list of days
 * off and the tickler
 * @returns {JSX} SchedulerLayout
 */

const moment = require("moment");

const SchedulerLayout = () => {
  const schedulerLayoutRef = useRef(null);
  const auth = useAuthContext();
  const scheduler = useSchedulerContext();
  let version = data;
  let currentVersion = version[version.length - 1];
  const [context, setContext] = useState("");
  const [name, setName] = useState("name");
  const [filteredEvents, setFilteredEvents] = useState([...scheduler.companyEvents]);
  const [filteredEventsByRegion, setFilteredEventsByRegion] = useState([...scheduler.companyUserEventsByRegion]);
  const [filteredWorkedHours, setFilteredWorkedHours] = useState([...scheduler.workedHours]);
  const [filteredHolidays, setFilteredHolidays] = useState([...scheduler.holidays]);
  const [filteredGlobalEvents, setFilteredGlobalEvents] = useState([...scheduler.companyGlobalEvents])
  const [region, setRegion]=useState();
  const [local, setLocal]= useState()
  const [contextEnumerator, setContextEnumerator] = ([{
    Personal: "Personal",
    Global: "Global",
    Region: region ? region : auth.userInfo.region,
    Local: local ? local: auth.userInfo.location,
    Employee: name
  }]);

  const [selectedMonth, setSelectedMonth] = useState(
    parseInt(moment.utc().format("M"))
  );
  const [prevMonth, setPrevMonth] = useState(
    moment.utc(`${selectedMonth}`).subtract(1, "M").format("MMMM")
  );
  const [nextMonth, setNextMonth] = useState(
    moment.utc(`${selectedMonth}`).add(1, "M").format("MMMM")
  );

  useEffect(() => {

    //Si hay un evento global y un day off, se muestra el day off
    let tempEvents = scheduler.companyEvents.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.userDaysOff) {
        if(element.end === null){
          if(element.start === eventDate){
            return false;
          }
        } else if(element.start <= eventDate && element.end >= eventDate) {
          return false;
        }
      }
      return true;
    });

    //Si hay un holiday y un day off, se muestra el day off
    let tempHolidays = scheduler.holidays.filter(e => {
      let eventDate = e.start;
      for (const element of scheduler.userDaysOff) {
        if(element.end === null){
          if(element.start === eventDate){
            return false;
          }
        } else if(element.start <= eventDate && element.end >= eventDate) {
          return false;
        }
      }
      return true;
    });

    //Si hay un evento global y un holiday, se muestra el holiday
    tempEvents = tempEvents.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.holidays) {
        if(element.start === eventDate){
          return false;
        }
      }
      return true;
    });


    //Si hay un evento regional y un day off, se muestra el day off
    let tempEventsByRegion = scheduler.companyUserEventsByRegion.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.userDaysOff) {
        if(element.end === null){
          if(element.start === eventDate){
            return false;
          }
        } else if(element.start <= eventDate && element.end >= eventDate) {
          return false;
        }
      }
      return true;
    });

    //Si hay un evento regional y un holiday, se muestra el holiday
    tempEventsByRegion = tempEventsByRegion.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.holidays) {
        if(element.start===eventDate){
          return false;
        }
      }
      return true;
    });

    //Si hay hs cargadas pero un day off, se muestra el day off.
    let tempWorkedHours = scheduler.workedHours
    //Si hay hs cargadas pero un holiday, se muestra el holiday
    tempWorkedHours = tempWorkedHours.map(e => {
      let eventDate = e.start
      for (const element of scheduler.holidays) {
        if(element.start === eventDate){
          e.borderColor = 'transparent';
        }
      }
      return e;
    });

    //Si hay hs cargadas pero un evento global, se muestra el evento global
    tempWorkedHours = tempWorkedHours.map(e => {
      let eventDate = e.start
      for (const element of scheduler.companyEvents) {
        if (element.start.substring(0, 10) === eventDate) {
           e.borderColor = 'transparent'
        }
      }
      return e;
    });

    //Si hay hs cargadas pero un evento regional, se muestra el evento regional
    tempWorkedHours = tempWorkedHours.map(e => {
      let eventDate = e.start
      for (const element of scheduler.companyUserEventsByRegion) {
        if (element.start.substring(0, 10) === eventDate) {
           e.borderColor = 'transparent'
        }
      }
      return e;
    });

    //Si hay un evento en el contexto global y un holiday, se muestra el holiday
    let tempGlobalEvents = scheduler.companyGlobalEvents.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.holidays) {
        if(element.start === eventDate){
          return false;
        }
      }
      return true;
    });

    setFilteredEvents([...tempEvents]);
    setFilteredEventsByRegion([...tempEventsByRegion]);
    setFilteredWorkedHours([...scheduler.workedHours]);
    setFilteredHolidays([...tempHolidays]);
    setFilteredGlobalEvents([...tempGlobalEvents]);
  }, [scheduler.companyEvents, scheduler.companyUserEventsByRegion, scheduler.userDaysOff, scheduler.workedHours, scheduler.holidays, scheduler.timesheets, scheduler.companyGlobalEvents, context]);

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth - 1);
    setPrevMonth(
      moment.utc(`${selectedMonth - 1}`)
        .subtract(1, "M")
        .format("MMMM")
    );
    setNextMonth(
      moment.utc(`${selectedMonth - 1}`)
        .add(1, "M")
        .format("MMMM")
    );
    if (selectedMonth === 1) {
      setSelectedMonth(12);
      setPrevMonth(moment.utc(`${12}`).subtract(1, "M").format("MMMM"));
      setNextMonth(moment.utc(`${12}`).add(1, "M").format("MMMM"));
    }
    scheduler.handlerPrevMonth();
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth + 1);
    setPrevMonth(
      moment.utc(`${selectedMonth + 1}`)
        .subtract(1, "M")
        .format("MMMM")
    );
    setNextMonth(
      moment.utc(`${selectedMonth + 1}`)
        .add(1, "M")
        .format("MMMM")
    );
    if (selectedMonth === 12) {
      setSelectedMonth(1);
      setPrevMonth(moment.utc(`${1}`).subtract(1, "M").format("MMMM"));
      setNextMonth(moment.utc(`${1}`).add(1, "M").format("MMMM"));
    }
    scheduler.handlerNextMonth();
  };

  useEffect(() => {
    schedulerLayoutRef.current.focus();
    scheduler.setSelectedMonth(parseInt(moment.utc().format("MM")));
    scheduler.setSelectedYear(parseInt(moment.utc().format("YYYY")));
    setContext(contextEnumerator.Personal);
  }, []);

  const handleUpdate = () => {
    scheduler.setTaskCounter(scheduler.taskCounter + 1);
  };

  const handleSchedulerFocus = () => {
    schedulerLayoutRef.current.focus();
  };

  const [isWheeling, setWheeling] = useState(false);

  const handleWheel = (e) => {
    setWheeling(true);
  };
  const handleSetWheeling = (isWheel) => {
    setWheeling(isWheel);
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "ArrowUp" ||
      e.key === "ArrowDown" ||
      e.key === "ArrowRight" ||
      e.key === "ArrowLeft"
    ) {
      setWheeling(true);
    }
  };

  return (
    <div
      className="scheduler-layout"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onWheel={(e) => handleWheel(e)}
      ref={schedulerLayoutRef}
    >
      <Header
        setLocal={setLocal}
        setRegion={setRegion}
        local={local}
        region={region}
        setContextEnumerator={setContextEnumerator}
        contextEnumerator={contextEnumerator}
        context={context}
        setContext={setContext}
        name={name}
        setName={setName}
        addTask={function () {
          handleUpdate();
        }}
        taskCount={scheduler.taskCounter}
      />
      <hr className="hrScheduler" />
      <div className="scheduler">
        <div className="calendar">
          <div className="myCalendar">
          {context === contextEnumerator.Personal && (
              <Calendar
                context={context}
                isWheeling={isWheeling}
                setWheeling={handleSetWheeling}
                events={[
                  ...filteredWorkedHours,
                  ...filteredHolidays,
                  ...scheduler.userDaysOff,
                  ...filteredEvents,
                  ...filteredEventsByRegion,
                ]}
                onClickNextMonth={handleNextMonth}
                onClickPrevMonth={handlePrevMonth}
                addTask={function () {
                  handleUpdate();
                }}
                taskCount={scheduler.taskCounter}
                setSchedulerLayoutFocus={handleSchedulerFocus}
              />
            )}
            {context === contextEnumerator.Employee && (
              <Calendar
                context={context}
                isWheeling={isWheeling}
                setWheeling={handleSetWheeling}
                events={[
                  ...filteredWorkedHours,
                  ...filteredHolidays,
                  ...scheduler.userDaysOff,
                  ...filteredEvents,
                  ...filteredEventsByRegion,
                ]}
                onClickNextMonth={handleNextMonth}
                onClickPrevMonth={handlePrevMonth}
                addTask={function () {
                  handleUpdate();
                }}
                taskCount={scheduler.taskCounter}
                setSchedulerLayoutFocus={handleSchedulerFocus}
              />
            )}
            {context === contextEnumerator.Global && (
              <Calendar
                context={context}
                isWheeling={isWheeling}
                setWheeling={handleSetWheeling}
                events={[
                  ...scheduler.holidays,
                  ...filteredGlobalEvents,
                ]}
                onClickNextMonth={handleNextMonth}
                onClickPrevMonth={handlePrevMonth}
                addTask={function () {
                  handleUpdate();
                }}
                taskCount={scheduler.taskCounter}
                setSchedulerLayoutFocus={handleSchedulerFocus}
              />
            )}
            {context === contextEnumerator.Region && (
              <Calendar
                context={context}
                isWheeling={isWheeling}
                setWheeling={handleSetWheeling}
                events={[
                  ...scheduler.holidays,
                  ...scheduler.companyEventsByRegion
                ]}
                onClickNextMonth={handleNextMonth}
                onClickPrevMonth={handlePrevMonth}
                addTask={function () {
                  handleUpdate();
                }}
                taskCount={scheduler.taskCounter}
                setSchedulerLayoutFocus={handleSchedulerFocus}
              />
            )}
            {context === contextEnumerator.Local && (
              <Calendar
                context={context}
                isWheeling={isWheeling}
                setWheeling={handleSetWheeling}
                events={[
                  ...scheduler.holidays,
                  ...scheduler.companyEventsByRegion
                ]}
                onClickNextMonth={handleNextMonth}
                onClickPrevMonth={handlePrevMonth}
                addTask={function () {
                  handleUpdate();
                }}
                taskCount={scheduler.taskCounter}
                setSchedulerLayoutFocus={handleSchedulerFocus}
              />
            )}
          </div>
          <div className="informationScheduler">
            <i>
              <InformationIcon />
              <FormattedMessage
                id="calendar.information"
                defaultMessage="Select a day in the calendar to create events and more options"
              />
            </i>
          </div>
          <hr className="hrInfo"/>
          <div className="myDaysOf">
            {context === "Global" ? (
              <DaysOffList
                events={[
                  ...scheduler.alerts,
                  ...scheduler.holidays,
                  ...scheduler.companyGlobalEvents
                ]}
                month={scheduler.selectedMonth}
                year={scheduler.selectedYear}
              />
            ) : null}
            {context === "Personal" ||
            context === contextEnumerator.Employee ? (
              <DaysOffList
                events={[
                  ...scheduler.alerts,
                  ...scheduler.holidays,
                  ...scheduler.userDaysOff,
                  ...scheduler.companyUserEventsByRegion,
                  ...scheduler.companyEvents,
                ]}
                month={scheduler.selectedMonth}
                year={scheduler.selectedYear}
              />
            ) : (
              <DaysOffList
                events={[
                  ...scheduler.alerts,
                  ...scheduler.holidays,
                  ...scheduler.companyEventsByRegion,
                ]}
                month={scheduler.selectedMonth}
                year={scheduler.selectedYear}
              />
            )}
          </div>
          <hr />
          {context === contextEnumerator.Personal ||
          context === contextEnumerator.Employee ? (
            <>
              <div className="totalTimeReported">
                <div className="ico">
                  <TimeCircle />
                </div>
                <span>
                  <FormattedMessage
                    id="calendar.total.month.time"
                    defaultMessage="Total hours reported within the month: "
                  />
                  <strong>
                    {scheduler.totalTimeInMonth.hours}h{" "}
                    {scheduler.totalTimeInMonth.minutes}m
                  </strong>
                </span>
              </div>
              <hr />
              {/*<div className="remainingDaysOf">*/}
              {/*  {context === contextEnum.Personal ? (*/}
              {/*    <p>*/}
              {/*      <HolidaysIcon /> You have used <b>5</b> out of <b>10</b>{" "}*/}
              {/*      vacation days (<b>5</b> remaining).*/}
              {/*      <br />*/}
              {/*      Closest expiration date is <b>July '22</b>.*/}
              {/*      <VacationsModal*/}
              {/*        buttonText={*/}
              {/*          <span*/}
              {/*            style={{*/}
              {/*              color: "#16448a",*/}
              {/*              textDecoration: "underline",*/}
              {/*            }}*/}
              {/*          >*/}
              {/*            <FormattedMessage*/}
              {/*              id="scheduler.modal.vacation.footer"*/}
              {/*              defaultMessage="See more details"*/}
              {/*            />*/}
              {/*          </span>*/}
              {/*        }*/}
              {/*      />*/}
              {/*    </p>*/}
              {/*  ) : (*/}
              {/*    <p>*/}
              {/*      <HolidaysIcon /> You have used <b>5</b> out of <b>10</b>{" "}*/}
              {/*      vacation days (<b>5</b> remaining).*/}
              {/*      <br />*/}
              {/*      Closest expiration date is <b>July '22</b>.*/}
              {/*      <VacationsModalManager*/}
              {/*        buttonText={*/}
              {/*          <span*/}
              {/*            style={{*/}
              {/*              color: "#16448a",*/}
              {/*              textDecoration: "underline",*/}
              {/*            }}*/}
              {/*          >*/}
              {/*            <FormattedMessage*/}
              {/*              id="scheduler.modal.vacation.footer"*/}
              {/*              defaultMessage="See more details"*/}
              {/*            />*/}
              {/*          </span>*/}
              {/*        }*/}
              {/*      />*/}
              {/*    </p>*/}
              {/*  )}*/}
              {/*</div>*/}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="tickler">
          <Tickler
            prevMonth={prevMonth}
            nextMonth={nextMonth}
            handlePrevMonth={handlePrevMonth}
            handleNextMonth={handleNextMonth}
            context={context}
            items={scheduler.timesheets}
            alerts={scheduler.alerts}
            holidays={scheduler.holidays}
            daysOff={scheduler.userDaysOff}
            eventsGlobal={scheduler.companyEvents}
            eventsRegion={scheduler.companyEventsByRegion}
            eventsGlobalContext={scheduler.companyGlobalEvents}
            refreshTask={function () {
              handleUpdate();
            }}
          />
        </div>
      </div>
      <div className="version">
        <p className="versionText">{currentVersion["Current version"]}</p>
      </div>
    </div>
  );
};

export default SchedulerLayout;
