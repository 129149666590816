import { useAuthContext } from '../../../context/AuthContext/AuthContext';
import { getWeatherInfo } from '../services/welcomeLocal';
import { useState, useEffect } from 'react';
import scss from './Welcome.module.scss';
import { MyLocation } from '../../Icons/index';
import {FormattedMessage, useIntl} from "react-intl";

/**
 * @module
 * @description This function creates the welcome message of the local page , depending on the user information the message may vary.
 * @returns {JSX} WelcomeLocal
 */

function WelcomeLocal() {
  const context = useAuthContext();
  const intl = useIntl();
  const [weatherInfo, setWeatherInfo] = useState('');
  const [tz, setTz] = useState(null);
  const [date, setDate] = useState(new Date());

  // Weather conditions supported by current API + nighttime.
  const weatherIcons = {
    Drizzle: '☔',
    Rain: '🌧️',
    Snow: '🌨️',
    Clear: '☀️',
    Clouds: '☁️',
    ClearNight: '🌕',
  };
  const weatherDictionary = {
    Drizzle: 'drizzling',
    Rain: 'raining',
    Snow: 'snowing',
    Clear: 'sunny',
    Clouds: 'cloudy',
    ClearNight: 'a clear night',
  };

  // Get weather information
  useEffect(() => {
    getWeatherInfo(
      context?.userInfo?.location,
      context?.userInfo?.region,
      context?.user?.token
    ).then((res) => setWeatherInfo(res))
    .catch(err => {
    });
  }, [context.userInfo, context.user]);

  // Timezone info
  useEffect(() => {
    setTz(context?.userInfo?.timezone);
  }, [context.userInfo]);

  // Updates date information every sec
  useEffect(() => {
    const myInterval = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [date]);

  // suffix for day of the month
  function ordinalSuffixOf(i) {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
      return 'st';
    }
    if (j === 2 && k !== 12) {
      return 'nd';
    }
    if (j === 3 && k !== 13) {
      return 'rd';
    }
    return 'th';
  }

  // Get local tz as a fallback
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Date information
  const day = date.toLocaleDateString('en-us', {
    timeZone: tz || localTz,
    day: 'numeric',
  });
  const weekday = date.toLocaleDateString('en-us', {
    timeZone: tz || localTz,
    weekday: 'long',
  });
  const month = date.toLocaleDateString('en-us', {
    timeZone: tz || localTz,
    month: 'long',
  });
  const daySuffix = ordinalSuffixOf(day);
  const hour = date.toLocaleTimeString('en-US', {
    timeZone: tz || localTz,
    hour: '2-digit',
    minute: '2-digit',
  });

  // Day / Night logic
  useEffect(() => {
    if (weatherInfo !== 'Clear' && weatherInfo !== 'ClearNight') return;

    const nightTime = {
      start: 19,
      end: 7,
    };

    const nightTimeCheck = +date.toLocaleTimeString('en-US', {
      timeZone: tz || localTz,
      hour: '2-digit',
      hour12: false,
    });

    if (nightTimeCheck >= nightTime.start || nightTimeCheck < nightTime.end) {
      if (weatherInfo === 'Clear') setWeatherInfo('ClearNight');
    } else {
      if (weatherInfo === 'ClearNight') setWeatherInfo('Clear');
    }
  }, [weatherInfo, date, localTz, tz]);

  // Format messages to show and fallbacks
  const weatherDescription = weatherDictionary[weatherInfo]
    ? `it's ${weatherDictionary[weatherInfo]}`
    : `we hope it's a nice day`;
  const weatherIcon = weatherIcons[weatherInfo] || '☀️';
  const userLocation = context?.userInfo?.location
    ? `in ${context?.userInfo?.location}`
    : '';

  return (
    <>
      <div className={scss.container}>
        <div className={scss.title}>
          <div className={scss.icon_title}>
            <div className={scss.icon}>
              <MyLocation />
            </div>
            <h1 className={scss.welcome_primary}>
              <FormattedMessage id = "local.welcome" defaultMessage = "Hello {country}!"
              values = {{country: context?.userInfo?.region}}/>
            </h1>
          </div>
          <h2 className={scss.welcome_secondary}>
            {`It's ${weekday}, ${month} the ${day}${daySuffix}, ${hour} and ${weatherDescription}`}{' '}
            {weatherIcon} {userLocation}
          </h2>
        </div>
      </div>
    </>
  );
}

export default WelcomeLocal;
