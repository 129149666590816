import React from "react";
import { BsClock } from "react-icons/bs";
import { FormattedMessage } from "react-intl";

/**
 * @module
 * @description this component displays the tickler start time
 * @param {number} time
 * @returns {JSX} TaskTimer
 */

const TaskTimer = ({ time }) => {
  return (
    <>
      {time < 30600000 ? (
        <>
          <h2 className="taskTimer">
            <div className="timer">
              <span className="icon">
                <BsClock />
              </span>
              <span className="hours">
                {("0" + Math.floor((time / 3600000) % 60)).slice(-2)}:
              </span>
              <span className="minutes">
                {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
              </span>
              <span className="seconds">
                {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
              </span>
            </div>
          </h2>
        </>
      ) : (
        <>
          <h2 className="taskTimer" style={{ color: "#FFB800" }}>
            <div className="timer">
              <span className="icon">
                <BsClock />
              </span>
              <span className="hours">
                {("0" + Math.floor((time / 3600000) % 60)).slice(-2)}:
              </span>
              <span className="minutes">
                {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
              </span>
              <span className="seconds">
                {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
              </span>
            </div>
          </h2>
          <div>
            <p className="warningTitle">
              <FormattedMessage
                id="timer.warning.title"
                defaultMessage="You did not stop the tickler after your working hours"
              />
            </p>
            <p className="warningParagraph">
              <FormattedMessage
                id="timer.warning.text"
                defaultMessage="Please review the information and submit or delete this entry before starting a new one"
              />
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default TaskTimer;
