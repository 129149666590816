import { createContext, useState } from "react";
import axios from "axios";
import { environment } from "../../config/environment";
import { useAuthContext } from "../AuthContext";
import PropTypes from "prop-types";

const ProjectsContext = createContext();

/**
 * @description This function creates a react context that stores different information and functions that are used across all the application
 * this is made in order to avoid prop drilling through the components
 * @param {Element} children
 * @returns {JSX} AuthProvider
 */

const ProjectsProvider = ({ children }) => {
  const [playersList, setPlayersList] = useState([]);
  const { context, user } = useAuthContext();
  const auth = useAuthContext();

  /**
   * @description ...
   * @param {} filters
   * @returns {String} ...
   */

  const getQueryParamsFromFilters = (filters) => {
    let queryParamsString = "?";
    let firstParam = true;
    for (const key in filters) {
      const value = filters[key];
      let currentParam = `${key}=${value}`;
      if (!firstParam) {
        currentParam = "&" + currentParam;
      }
      firstParam = false;
      queryParamsString = queryParamsString + currentParam;
    }
    return queryParamsString;
  };

  /**
   * @description Do the API call to get the projects by a single user
   * @param {String} filters
   * @returns {Object} response
   */

  const getProjectsByUser = async (filters) => {
    const queryParams = getQueryParamsFromFilters(filters);
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/user/${user.id}${queryParams}`,
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the projects by a single user
   * @returns {Object} response
   */

  const getUserProjectsByUserId = async () => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/${user.id}`,
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get a project by its id
   * @param {Number} id
   * @returns {Object} response
   */

  const getProjectById = async (id) => {
    //logic to get the id
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/${id}`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to edit the information of the project overview section from a project
   * @param {Number} id
   * @returns {Object} response
   */

  const editProjectById = async (id, data) => {
    //logic to get the id
    console.log(data)
    const response = await axios
      .put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/${id}`,
        data,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the files from a project
   * @param {Number} projectId
   * @returns {Object} response
   */

  const getProjectFiles = async (projectId) => {
    //logic to get the id
    try {
      const response = await axios.get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/${projectId}/files`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      );
      return response;
    } catch (err) {
      throw err.response || err.toJSON() || err;
    }
  };

  const postFile = async (header, bodyFormData) => {
    const response = await axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/files`,
        bodyFormData,
        header
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const deleteFileTotally = async (payload) => {
    const response = await axios
      .delete(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/files`,
        {
          headers: {
            Authorization: user?.token,
          },
          data: payload,
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const getFile = async (name) => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/files/${name}`,
        {
          responseType: "blob",
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const changeFilePrivacity = async (id) => {
    const response = await axios
      .put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/files/toggle/${id}`,
        {},
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the information to fill the header
   * @param {Number} id
   * @returns {Object} response
   */

  const getProjectHeader = async (id) => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/project_header/${id}`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the information to fill the latest updates section from a project
   * @param {Number} id
   * @returns {Object} response
   */

  const getLatestUpdates = async (id) => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/latest_updates/${id}`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the information to fill the shortcuts section from a project
   * @param {Number} id
   * @returns {Object} response
   */

  const getShortcuts = async (id) => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/project_shortcuts/${id}`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to edit the information of the shortcuts section from a project
   * @param {Number} id
   * @param {Object} data
   * @returns {Object} response
   */

  const postShortcuts = async (id, data) => {
    const response = await axios.post(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/project_shortcuts/${id}`,
      data,
      {
        //Add this path to the environment file
        headers: {
          //User token from the local storage
          Authorization: user?.token,
        }
      }
    )
    .catch(err => {
      throw err.response || err.toJSON() || err
    });
    return response;
  }

  /**
   * @description Do the API call to get the information to fill the project overview section from a project
   * @param {Number} id
   * @returns {Object} response
   */

  const getProjectOverview = async (id) => {
    //logic to get the id
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/${id}`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to edit the information of the project overview section from a project
   * @param {Number} id
   * @returns {Object} response
   */

  const editProjectOverview = async (id, data) => {
    //logic to get the id
    const response = await axios
      .put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/${id}/overview`,
        data,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to edit the information of the how we work section from a project
   * @param {Number} id
   * @returns {Object} response
   */

  const editProjectHowWeWork = async (id, data) => {
    //logic to get the id
    const response = await axios
      .put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/${id}/hww`,
        data,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the information to fill the details section from a project
   * @param {Number} id
   * @returns {Object} response
   */

  const getProjectDetail = async (id) => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects_detail/${id}`,
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the information to fill the players section from a project
   * @param {Number} id
   * @param {String} queryParams
   * @returns {Object} response
   */

  const getTeamAndRoles = async (id, queryParams) => {
    let url;
    if (queryParams) {
      url = `/projects/${id}/team-members${queryParams}`;
    } else {
      url = `/projects/${id}/team-members`;
    }
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/${url}`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the statuses
   * @returns {Object} response
   */

  const getProjectStatusOptions = async () => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/status`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the mood options
   * @returns {Object} response
   */

  const getMoodOptions = async (id) => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/team_mood`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the client satisfaction mood options
   * @returns {Object} response
   */

  const getSatisfactionOptions = async (id) => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/client_satisfaction`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to set a project
   * @param {Numnber} projectId
   * @param {Object} payload
   * @returns {Object} response
   */

  const setProjectUpdate = async (projectId, payload) => {
    const response = await axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/project_status/${projectId}`,
        payload,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const removeAllocations = async (playerId, selectedAllocations) => {
    const response = await axios
      .put(
        `${environment.BACKEND}:${environment.PORT}${
          environment.BACKEND_VERSION
        }/module2/projects/users/${playerId}/remove?p=${selectedAllocations.join()}`,
        {},
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };
  /**
   * @description Do the API call to get the player information
   * @param {Number} playerId
   * @returns {Object} response
   */

  const getPlayerData = async (playerId) => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/team/user/${playerId}`,
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to add a new player in the project's team
   * @param {Object} payload
   * @returns {Object} response
   */

  const addNewPlayer = async (payload) => {
    const response = await axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/team`,
        payload,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to add a new project
   * @param {Object} payload
   * @returns {Object} response
   */

  const addNewProject = async (payload) => {
    const response = await axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects`,
        payload,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get all the players from a project
   * @param {Number} id
   * @returns {Object} response
   */

  const getAllPlayers = async (id) => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/users?project=${id}`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to get the project roles by user
   * @returns {Object} response
   */

  const getProjectRoles = async () => {
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/project/roles`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to edit the information from a player
   * @param {Number} playerId
   * @param {Object} payload
   * @returns {Object} response
   */

  const editPlayerData = async (playerId, payload) => {
    const response = await axios
      .put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/team/user/${playerId}`,
        payload,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  /**
   * @description Do the API call to set the player mood by a project manager
   * @param {Object} payload
   * @param {Number} statusId
   * @returns {Object} response
   */

  const setPlayersMoodPM = async (payload, statusId) => {
    const response = await axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/project_status/${statusId}/mood_manager_pov`,
        payload,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const removePlayers = async (projectId, playerId) => {
    const response = await axios
      .put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/${projectId}/team-members/${playerId}/toggle`,
        {},
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const inactivePlayer = async (playerId) => {
    const response = await axios
      .put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/users/${playerId}/toggle`,
        {},
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const archiveProject = async (projectId) => {
    const response = await axios
      .put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/${projectId}/toggle`,
        {},
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const exes = {
    getProjectsByUser,
    getProjectById,
    editProjectById,
    getUserProjectsByUserId,
    getProjectHeader,
    getLatestUpdates,
    getShortcuts,
    postShortcuts,
    getProjectOverview,
    editProjectOverview,
    editProjectHowWeWork,
    getProjectDetail,
    getTeamAndRoles,
    setProjectUpdate,
    getProjectFiles,
    postFile,
    deleteFileTotally,
    getFile,
    changeFilePrivacity,
    getProjectStatusOptions,
    getMoodOptions,
    getSatisfactionOptions,
    getPlayerData,
    addNewProject,
    addNewPlayer,
    getAllPlayers,
    getProjectRoles,
    editPlayerData,
    setPlayersMoodPM,
    removePlayers,
    inactivePlayer,
    archiveProject,
    removeAllocations,
    auth,
  };

  return (
    <ProjectsContext.Provider value={exes}>{children}</ProjectsContext.Provider>
  );
};

ProjectsContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export { ProjectsProvider };
export default ProjectsContext;
