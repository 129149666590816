import EditIcon from "./EditIcon";
import "./EditIcon.scss";

const EditComponent = () => {
  return (
    <>
      <div className="component">
        <EditIcon />
      </div>
    </>
  );
};

export default EditComponent;
