import React, { createContext, useEffect, useState, useContext } from "react";
import { useAuthContext } from "../AuthContext";
import getUserDaysOff from "../../components/MyCalendar/services/getUserDaysOff";
import getUserTimesheets from "../../components/MyCalendar/services/get.user.timesheets";
import { getCalendarHolidays } from "../../components/MyCalendar/services/get.calendar.holidays";
import { getCalendarEvents, getCalendarEventsByRegion, getGlobalCalendarEvents } from "../../components/MyCalendar/services/get.calendar.events";
import { getLocationsAndRegions } from "../../components/MyCalendar/services/getLocationsAndRegions";
import getUsersByLocation from "../../components/MyCalendar/services/getUserByLocation";
import getUsersByRegion from "../../components/MyCalendar/services/getUserByRegion";
import {useIntl} from "react-intl";

const moment = require("moment");

const SchedulerContext = createContext();

export const SchedulerProvider = ({ children }) => {
  const intl = useIntl();
  const [holidays, setHolidays] = useState([]);
  const [companyEvents, setCompanyEvents] = useState([]);
  const [companyGlobalEvents, setCompanyGlobalEvents] = useState([]);
  const [companyEventsByRegion, setCompanyEventsByRegion] = useState([]);
  const [companyUserEventsByRegion, setCompanyUserEventsByRegion] = useState([]);
  const [userDaysOff, setUserDaysOff] = useState([]);
  const [timesheets, setTimesheets] = useState([]);
  const [workedHours, setWorkedHours] = useState([]);
  const [regions, setRegions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationsRegions, setLocationsRegions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [taskCounter, setTaskCounter] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [totalTimeInMonth, setTotalTimeInMonth] = useState({
    hours: 0,
    minutes: 0,
  });
  const [location, setLocation] = useState();
  const [personalTickler, setPersonalTickler] = useState(true);
  const [users, setUsers] = useState({});
  const [selectedUser, setSelectedUser] = useState();
  const [startDate, setStartDate] = useState();
  const [position, setPosition] = useState('');
  const auth = useAuthContext();
  const calendarRef = React.useRef();
  const [updateContext, setUpdateContext] = useState(true);

  const handlerNextMonth = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    if (selectedMonth === 12) {
      setSelectedMonth(1);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const handlerPrevMonth = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    if (selectedMonth === 1) {
      setSelectedMonth(12);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  // Sum the dedicated seconds of a timesheet
  const sumDedicatedTime = (item) => {
    let total = 0;
    if (item.descriptions) {
      item.descriptions.map((element) => {
        if (element.dedicatedTime && element.isFinished === true)
        total += element.dedicatedTime;
      });
    }
    return total;
  };

  // Sum the dedicated hours of a timesheet
  const sumDedicatedHours = (item) => {
    let total = 0;
    if (item.descriptions) {
      item.descriptions.map((element) => {
        if (element.dedicatedHours && element.isFinished === true)
          total += element.dedicatedHours;
      });
    }
    return total;
  };

  //gets global events
  const getEvents = async () => {
    try {
      const response = await getCalendarEvents(
        auth.user.token,
      );
      if (response) {
        let tempCompanyEvents = [...response]

        for (let i=0; i < timesheets.length; i++) {
          let eventDate = timesheets[i].date;
          for (let j=0; j < tempCompanyEvents.length; j++) {
            if(response[j].start.substring(0, 10) === eventDate){
              let time = sumDedicatedTime(timesheets[i]);
              //If the dedicated time does not exists, use the dedicated hours
              if (time === 0) time = sumDedicatedHours(timesheets[i]) * 3600;
              let title= `${moment.duration(time, "seconds").format("h")}h
              ${moment.duration(time % 3600, "seconds").format("m")}m`

              tempCompanyEvents[j].ticklerTitle = tempCompanyEvents[j].title;
              tempCompanyEvents[j].title = title+ '='+ tempCompanyEvents[j].title;
            }
          }
        }
        setCompanyEvents([...tempCompanyEvents]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  //gets events for global context
  const getGlobalEvents = async () => {
    try {
      const response = await getGlobalCalendarEvents(
        auth.user.token,
      );
      if (response) {
        setCompanyGlobalEvents(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getEventsByRegion = async () => {
    let regions;
    let userRegion;
    try {
      const res = await getLocationsAndRegions(auth.user.token);
      if (res) {
        regions = res;
      }
      for (let i = 0; i < regions.length; i++) {
        if (regions[i].name === auth.userInfo.region) {
          userRegion = regions[i].id;
          break;
        }
      }
    } catch (error) {
      console.log(error)
    }
    try {
      const response = await getCalendarEventsByRegion(
        auth.user.token,
        userRegion
      );
      if (response) {
        setCompanyEventsByRegion(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getUserEventsByRegion = async () => {
    let regions;
    let userRegion;
    try {
      const res = await getLocationsAndRegions(auth.user.token);
      if (res) {
        regions = res;
      }
      for (let i = 0; i < regions.length; i++) {
        if (regions[i].name === auth.userInfo.region) {
          userRegion = regions[i].id;
          break;
        }
      }
    } catch (error) {
      console.log(error)
    }
    try {
      const response = await getCalendarEventsByRegion(
        auth.user.token,
        userRegion
      );
      if (response) {
        let tempCompanyUserEventsByRegion = [...response]

        for (let i=0; i < timesheets.length; i++) {
          let eventDate = timesheets[i].date;
          for (let j=0; j < tempCompanyUserEventsByRegion.length; j++) {
            if(response[j].start.substring(0, 10) === eventDate){
              let time = sumDedicatedTime(timesheets[i]);
              //If the dedicated time does not exists, use the dedicated hours
              if (time === 0) time = sumDedicatedHours(timesheets[i]) * 3600;
              let title= `${moment.duration(time, "seconds").format("h")}h
              ${moment.duration(time % 3600, "seconds").format("m")}m`
              tempCompanyUserEventsByRegion[j].title = title + '=' + tempCompanyUserEventsByRegion[j].title;
              tempCompanyUserEventsByRegion[j].ticklerTitle = title + '=' + tempCompanyUserEventsByRegion[j].title;

            }
          }
        }
        setCompanyUserEventsByRegion([...tempCompanyUserEventsByRegion]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getHolidays = async () => {
    let temporalyMonth = selectedMonth;
    if (temporalyMonth < 10) {
      temporalyMonth = "0" + temporalyMonth;
    }
    const startOfMonth = `${selectedYear}-${temporalyMonth}-01`;
    const endOfMonth = moment(startOfMonth).endOf("month").format("YYYY-MM-DD");
    let isLocation = locations.find((element) => element.name === location);
    try {
      if (isLocation) {
        // If the selected context is a location
        if (personalTickler === false) {
          // If the selected context is a location and is not the personal tickler
          const response = await getCalendarHolidays(
            startOfMonth,
            endOfMonth,
            isLocation.id,
            null,
            auth.user.token,
            false
          );
          if (response) {
            setHolidays(response);
          }
        } else {
          // If the selected context is a location and is the personal tickler
          let regionId = 0;
          locationsRegions.map((element) => {
            if (element.locations.find((loc) => loc.name === location)) {
              regionId = element.id;
            }
          });

          const response = await getCalendarHolidays(
            startOfMonth,
            endOfMonth,
            isLocation.id,
            regionId,
            auth.user.token,
            true
          );
          if (response) {
            let tempHolidays = [...response]

            for (let i=0; i < timesheets.length; i++) {
              let eventDate = timesheets[i].date;
              for (let j=0; j < tempHolidays.length; j++) {
                if(response[j].start.substring(0, 10) === eventDate){
                  let time = sumDedicatedTime(timesheets[i]);
                  //If the dedicated time does not exists, use the dedicated hours
                  if (time === 0) time = sumDedicatedHours(timesheets[i]) * 3600;
                  let title= `${moment.duration(time, "seconds").format("h")}h
                  ${moment.duration(time % 3600, "seconds").format("m")}m`

                  tempHolidays[j].title =title+ '='+ tempHolidays[j].title;
                  tempHolidays[j].ticklerTitle =title+ '='+ tempHolidays[j].title;
                }
              }
            }
            setHolidays([...tempHolidays]);
          }
        }
      } else {
        // If the selected context is not a location, get the region id
        let regionId = regions.find((element) => element.name === location);
        const response = await getCalendarHolidays(
          startOfMonth,
          endOfMonth,
          null,
          regionId.id,
          auth.user.token,
          false
        );
        if (response) {
          setHolidays(response);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLocationsRegions = async () => {
    try {
      const response = await getLocationsAndRegions(auth.user.token);
      if (response) {
        let locationsArr = [];
        let regionsArr = response.map((element) => {
          locationsArr = [...locationsArr, ...element.locations];
          return {
            id: element.id,
            name: element.name,
          };
        });
        setLocations(locationsArr);
        setRegions(regionsArr);
        setLocationsRegions(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTimesheets = async () => {

    let temporalyMonth = selectedMonth;
    if (temporalyMonth < 10) {
      temporalyMonth = "0" + temporalyMonth;
    }

    const startOfMonth = `${selectedYear}-${temporalyMonth}-01`;
    const endOfMonth = moment(startOfMonth).endOf("month").format("YYYY-MM-DD");

    try {
      const response = await getUserTimesheets(
        startOfMonth,
        endOfMonth,
        selectedUser,
        auth?.user?.token
      );
      setTimesheets(response.reverse());
    } catch (error) {
      console.error(error);
    }
  };

  const setTotalHoursPerMonth = () => {
    const initialValueOfDescription = 0;
    const initialValueOfTimesheet = 0;

    const total = timesheets.reduce(
      (prevValueOfTimesheet, currentValueOfTimesheet) =>
        (prevValueOfTimesheet += currentValueOfTimesheet.descriptions.reduce(
          (prevValueOfDescription, currentValueOfDescription) =>
            (prevValueOfDescription += currentValueOfDescription.dedicatedTime
              ? currentValueOfDescription.dedicatedTime
              : currentValueOfDescription.dedicatedHours * 3600),
          initialValueOfDescription
        )),
      initialValueOfTimesheet
    );
    setTotalTimeInMonth(secondsToHoursAndMinutesFormat(total));
  };

  function secondsToHoursAndMinutesFormat(time) {
    let totalHours = Math.trunc(time / 3600);
    let totalMinutes = time - totalHours * 3600;
    return {
      hours: totalHours,
      minutes: moment.utc(totalMinutes * 1000).format("mm"),
    };
  }

  const getDaysOff = async () => {
    let temporalyMonth = selectedMonth;
    if (temporalyMonth < 10) {
      temporalyMonth = "0" + temporalyMonth;
    }
    const startOfMonth = `${selectedYear}-${temporalyMonth}-01`;
    const endOfMonth = moment(startOfMonth).endOf("month").format("YYYY-MM-DD");
    try {
      const daysOffApproved = await getUserDaysOff.approved(
        startOfMonth,
        endOfMonth,
        auth?.user?.id,
        auth?.user?.token,
        intl
      );
      const daysOffPending = await getUserDaysOff.pending(
        startOfMonth,
        endOfMonth,
        auth?.user?.id,
        auth?.user?.token,
        intl
      );
      if (daysOffApproved || daysOffPending) {
        let tempUserDaysOff = [...daysOffApproved.concat(daysOffPending)]

        for (let i=0; i < timesheets.length; i++) {
          let eventDate = timesheets[i].date;
          for (let j=0; j < tempUserDaysOff.length; j++) {
            if (tempUserDaysOff[j].start.substring(0, 10) === eventDate) {
              const hasTimesheetInDayOff = timesheets.filter(item => {
                if(item.date === tempUserDaysOff[j].start.substring(0, 10)){
                  return true;
                }
                return false;
              });
              if(hasTimesheetInDayOff) {
                tempUserDaysOff[j].ticklerTitle = tempUserDaysOff[j].title;
              } else {
                tempUserDaysOff[j].ticklerTitle = tempUserDaysOff[j].title;
                tempUserDaysOff[j].title = null;
              }
            }
            else {
              if(tempUserDaysOff[j].title !== null) tempUserDaysOff[j].ticklerTitle = tempUserDaysOff[j].title;
            }
          }
        }
        setUserDaysOff(tempUserDaysOff);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDaysOffByUser = async () => {
    let temporalyMonth = selectedMonth;
    if (temporalyMonth < 10) {
      temporalyMonth = "0" + temporalyMonth;
    }
    const startOfMonth = `${selectedYear}-${temporalyMonth}-01`;
    const endOfMonth = moment(startOfMonth).endOf("month").format("YYYY-MM-DD");
    try {
      const daysOffApproved = await getUserDaysOff.approved(
        startOfMonth,
        endOfMonth,
        auth?.user?.id,
        auth?.user?.token,
        intl
      );
      const daysOffPending = await getUserDaysOff.pending(
        startOfMonth,
        endOfMonth,
        auth?.user?.id,
        auth?.user?.token,
        intl
      );
      if (daysOffApproved || daysOffPending) {
        let tempUserDaysOff = [...daysOffApproved.concat(daysOffPending)]

        for (let i=0; i < timesheets.length; i++) {
          let eventDate = timesheets[i].date;
          for (let j=0; j < tempUserDaysOff.length; j++) {
            if(tempUserDaysOff[j].start.substring(0, 10) === eventDate){
              let time = sumDedicatedTime(timesheets[i]);
              //If the dedicated time does not exists, use the dedicated hours
              if (time === 0) time = sumDedicatedHours(timesheets[i]) * 3600;
              let title= `${moment.duration(time, "seconds").format("h")}h
              ${moment.duration(time % 3600, "seconds").format("m")}m`
              tempUserDaysOff[j].title = title+ '='+ tempUserDaysOff[j].title;
              tempUserDaysOff[j].ticklerTitle = title+ '='+ tempUserDaysOff[j].title;
            }
          }
        }
        setUserDaysOff(tempUserDaysOff);
      }
    } catch (error) {
      console.error(error);
    }
  };


  // Calculate the total worked hours each day of month
  const getWorkedHours = () => {
    setWorkedHours([]);
    // First year and date of the calendar
    const startOfMonth = `${selectedYear}-${selectedMonth}-01`;
    // Get the last day of month selected
    const endOfMonth = parseInt(
      moment.utc(startOfMonth).endOf("month").format("DD")
    );
    // Array to save the events with the worked hours
    let tickets = [];
    // Array with holidays and days off together
    let holidaysOff = [...holidays, ...userDaysOff, ...companyEvents, ...companyUserEventsByRegion];
    // Array to save the alerts
    let alerts = [];

    // Loop for each day in the month
    for (let day = 1; day <= endOfMonth; day++) {
      // Format the date of the loop
      let date = moment(`${selectedYear}-${selectedMonth}-${day}`).format(
        "YYYY-MM-DD"
      );
      // Only make the search and ticket for today and backwards
      if (
        date <= moment().format("YYYY-MM-DD") &&
        date >= moment(startDate).format("YYYY-MM-DD")
      ) {
        let dayName = moment(date).format("dddd");

        // Get the timesheet of the day, if exists
        let item = timesheets
          ? timesheets.find(
              (element) => moment(element.date.toString().slice(0,10)).format("YYYY-MM-DD") === date
            )
          : null;
        // Get the holiday or day off, if exists
        let noWork;
        if (holidaysOff.length) noWork = holidaysOff.find(
          (element) =>
            moment(element.start).format("YYYY-MM-DD") === date ||
            (moment(element.start).format("YYYY-MM-DD") <= date &&
              element.end &&
              moment(element.end).format("YYYY-MM-DD") > date)
        )

        // If there is a timesheet and is not a holiday nor day off
        if (item && typeof(noWork) === "undefined") {
          // Calculate the total time of a timesheet with dedicatedTime
          let time = sumDedicatedTime(item);
          // If the dedicated time does not exists, use the dedicated hours
          if (time === 0) time = sumDedicatedHours(item) * 3600;
          let eventColor = time < 28800 ? "#ef9325" : "transparent";
          // Create event object
          let event = {
            id: item.id,
            title: `${moment.utc(time * 1000).format("H[h] m[m]")}`,
            start: moment(item.date.toString().slice(0,10)).format("YYYY-MM-DD"),
            borderColor: eventColor,
            textColor: time > 28000 ? "#167f45" : eventColor,
            alertStr: "",
            allDay: true,
          };
          // add event to array
          tickets.push(event);
          if (time < 28800) alerts.push(event);
        } else if (!noWork && dayName !== "Saturday" && dayName !== "Sunday") {
          // If it is not a holiday, dayOff or weekend, create the event
          let event = {
            id: Math.round(Math.random() * 100),
            alertStr: "⚠",
            title:"",
            start: moment(`${selectedYear}-${selectedMonth}-${day}`).format(
              "YYYY-MM-DD"
            ),
            borderColor: "#ff0101",
            textColor: "#ff0101",
            allDay: true,
          };
          // Add event to array
          tickets.push(event);
          alerts.push(event);
        } else if (noWork && item) {
          let holiday = noWork;
          // noWork.title = "";
          // Calculate the total time of a timesheet with dedicatedTime
          let time = sumDedicatedTime(item);
          // If the dedicated time does not exists, use the dedicated hours
          if (time === 0) time = sumDedicatedHours(item) * 3600;
          let event2 = {
            id: Math.round(Math.random() * 100),
            title: `${moment.duration(time, "seconds").format("h")}h 
              ${moment.duration(time % 3600, "seconds").format("m")}m`,
            start: moment(`${selectedYear}-${selectedMonth}-${day}`).format(
              "YYYY-MM-DD"
            ),
            textColor: "#ef9325",
            borderColor: noWork.borderColor,
            allDay: true,
          };
          // Add event to array
          tickets.push(event2);
          let objAlert = {
            id: Math.round(Math.random() * 100),
            title: `Hours reported on a ${holiday.type}, is this OK? `,
            start: moment(`${selectedYear}-${selectedMonth}-${day}`).format(
              "YYYY-MM-DD"
            ),
            textColor: "#ef9325",
            borderColor: "transparent",
            allDay: true,
            reason:
              noWork.type === "Holiday"
                ? `Happy ${holiday.title}!`
                : "Enjoy your day off",
            type: noWork.type,
          };
          alerts.push(objAlert);
        }
      }else {
        // Get the timesheet of the day, if exists
        let item = timesheets
          ? timesheets.find((element) => {
            return moment(element.date.toString().slice(0,10)).format("YYYY-MM-DD") === date
          }) : null;
        if(item !== undefined) {
          let time = sumDedicatedTime(item);
          //If the dedicated time does not exists, use the dedicated hours
          if (time === 0) time = sumDedicatedHours(item) * 3600;

          const daysOff = userDaysOff;
          let color='#167f45';
          for (const daysOffElement of daysOff) {
            //if(item.date >= daysOffElement.start && item.date <= daysOffElement.end) {
              color = daysOffElement.borderColor;
            //}
          }
          let event = {
            id: item.id,
            title: `${moment.utc(time * 1000).format("H[h] m[m]")}`,
            start: moment(item.date.toString().slice(0, 10)).format("YYYY-MM-DD"),
            borderColor: 'transparent',
            textColor: color,
            allDay: true,
          }
          tickets.push(event);
        }
      }
    }
    // Set the worked hours events
    setWorkedHours(tickets);
    setAlerts(alerts);
  };

  const getUsers = async () => {
    let isMounted = true;

    let res = [];

    if (auth.user && auth.user.token) {
      if (
        Boolean(auth.userInfo.region)
      ) {
        for (const region of regions) {
          let regionId = region.id;
          try {
            const result1 = await getUsersByRegion(regionId, auth.user.token);
            for (const user of result1) {
              if (!res.find((elem) => elem.id === user.id)) {
                if (user.id !== auth.user.id) res.push(user);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
      if (
        Boolean(auth.userInfo.location)
      ) {
        for (const location of locations) {
          let locationId = location.id;
          try {
            const res2 = await getUsersByLocation(locationId, auth.user.token);
            for (const user of res2) {
              if (!res.find((elem) => elem.id === user.id)) {
                if (user.id !== auth.user.id) res.push(user);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
    await setUsers([res]);

    return () => (isMounted = false);
  };

  useEffect(async () => {
    if (auth && auth.userInfo && auth.user) {
      setSelectedUser(auth.user.id);
      await getLocationsRegions();
      setLocation(auth.userInfo.location);
      setStartDate(auth.userInfo.startDate);
      setPosition(auth.userInfo.position);
      await getUsers();
    }
  }, []);

  useEffect(async () => {
    if (auth.user && auth.userInfo) {
      setSelectedUser(auth.user.id);
      await getLocationsRegions();
      setLocation(auth.userInfo.location);
      setPosition(auth.userInfo.position);
      await getUsers();
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.user) {
      getHolidays();
      getEventsByRegion();
      getGlobalEvents();
      getEvents();
      if (personalTickler === true) {
        getDaysOff();
        getTimesheets();
        getWorkedHours();
        getUserEventsByRegion();
      } else {
        setTimesheets([]);
        setAlerts([]);
        setWorkedHours([]);
        setUserDaysOff([]);
      }
    }
  }, [selectedMonth, location, locations, personalTickler, selectedUser, updateContext, taskCounter]);


  useEffect(() => {
    if (auth?.user && personalTickler === true) {
      setTotalHoursPerMonth();
      getWorkedHours();
    }
  }, [userDaysOff, holidays, timesheets]);

  useEffect(() => {
    if (auth?.user && personalTickler === true) {
      getTimesheets();
    }
  }, [taskCounter]);

  useEffect(() => {
    if (personalTickler === true && auth.userInfo) {
      setLocation(auth.userInfo.location);
    }
  }, [personalTickler]);

  useEffect(async () => {
    getEvents();
    await getTimesheets();
    getHolidays();
    getDaysOff();
    getWorkedHours();
    getUserEventsByRegion();
    setUpdateContext(!updateContext)
  }, [taskCounter, selectedMonth]);

  const functions = {
    handlerNextMonth,
    handlerPrevMonth,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    userDaysOff,
    holidays,
    timesheets,
    workedHours,
    taskCounter,
    setTaskCounter,
    calendarRef,
    alerts,
    totalTimeInMonth,
    location,
    setLocation,
    personalTickler,
    setPersonalTickler,
    users,
    setUsers,
    selectedUser,
    setSelectedUser,
    getUsers,
    getEvents,
    getGlobalEvents,
    getEventsByRegion,
    startDate,
    setStartDate,
    getDaysOff,
    getDaysOffByUser,
    getHolidays,
    getUserEventsByRegion,
    companyEvents,
    companyEventsByRegion,
    companyUserEventsByRegion,
    companyGlobalEvents,
    updateContext,
    setUpdateContext,
    getTimesheets,
    setTimesheets,
    sumDedicatedTime,
    sumDedicatedHours,
    regions,
    locations
  };

  return (
    <SchedulerContext.Provider value={functions}>
      {children}
    </SchedulerContext.Provider>
  );
};

export function useSchedulerContext() {
  const context = useContext(SchedulerContext);
  if (context == null) throw new Error("Missing SchedulerProvider");
  return context;
}
