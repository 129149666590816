import "./FilterableIcons.scss";
import { getStatusIcon } from "../../utils/getProjectStatusIcon";
import { Checkbox } from "@mui/material";
import ReactTooltip from "react-tooltip";

/**
 * @module
 * @description This function create the structure and the logic of the Filterable Icons
 * @param {string} projects get data projects
 * @param {any} statusButtons get data status Buttons
 * @param {string} handleFilterByStatus Filter By Status
 * @returns {JSX} Filterable Icons
 */

const FilterableIcons = ({
  projects,
  statusButtons,
  handleFilterByStatus,
  setFilterArchived,
}) => {
  // const arrayToFilter = projects.length ? projects : [];
  // const getAmountByStatus = (statusId) => {
  //   return arrayToFilter?.filter((project) => project.statusId === statusId)
  //     .length;
  // };

  const arrayToFilter = projects.map((project) => project.data);
  const projectsList = [];

  arrayToFilter.forEach((projects) => {
    projects.forEach((project) => {
      projectsList.push(project);
    });
  });

  const getAmountByStatus = (statusId) => {
    return projectsList.filter((project) => project.statusId === statusId)
      .length;
  };

  const handleArchiveShow = () => {
    setFilterArchived(true);
  };

  return (
    <>
      <div className="filterableIcons">
        <div className="status">
          {statusButtons.map((type) => (
            <div
              className={type.name}
              key={type.name}
              data-tip={type.name}
              data-for={type.name}
            >
              <span>{getAmountByStatus(type.statusId)}</span>
              {getAmountByStatus(type.statusId) ? (
                <Checkbox
                  icon={getStatusIcon(type.statusId)}
                  checkedIcon={getStatusIcon(type.statusId)}
                  onChange={(e) => {
                    handleFilterByStatus(e);
                  }}
                  value={type.statusId}
                  id={type.statusType}
                  onClick={(e) => handleArchiveShow(e, type.statusId)}
                />
              ) : (
                <Checkbox
                  icon={getStatusIcon(type.statusId)}
                  checkedIcon={getStatusIcon(type.statusId)}
                />
              )}
              <ReactTooltip
                id={type.name}
                place="top"
                backgroundColor="#3FAA58"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FilterableIcons;
