import React, { useState, useEffect } from "react";
import "./Skills.scss";
import PropTypes from "prop-types";

/**
 * @module
 * @description This component renders a clickable box portraying a individual skill. For each click, it adds one point of expertise.
 * Whenever clicked again after reaching five points, it will return to zero points and deactivate the selection.
 * This component auto renders as active on inactive depending of the amount of expertise clicked.
 * @param {String} props.item: An object containing the skillId, and the skillName.
 * @param {Function} props.setSkill: callback function to lift up this component's state.
 * @returns {JSX} A button portraying the skill name besides the level of expertise selected.
 */

const Items = ({ item, setSkill }) => {
  const [active, setActive] = useState("inactive");
  const [count, setCount] = useState(0);
  const [name, setName] = useState("");

  // This sets the parent component's skill as active, and adds levels to it on each click.
  function handler(nameItem, count) {
    if (nameItem === "" && count === 0) {
    } else {
      setSkill({ levelId: count, skill: nameItem });
    }
  }

  useEffect(() => {
    handler(name, count);
  }, [name, count]);

  // This adds a skill level on each click. For the first click, it makes the skill box active.
  function onClick() {
    if (count >= 5) {
      setActive("inactive");
      setCount(0);
    } else {
      setActive("active");
      setCount(count + 1);
      setName(item.name);
    }
  }

  return (
    <li value={item.name} onClick={onClick} className={active}>
      {item.name} {count}
    </li>
  );
};

Items.propTypes = {
  item: PropTypes.object,
  setSkill: PropTypes.func,
};

/**
 * @description This component renders a box with clickable items that portray each a skill coming from the database.
 * These items when clicked will increment the "knowledge" of this skill by one point, up to five.
 * Whenever clicked again after reaching five points, it will return to zero points and deactivate the selection.
 * @param {String} props.name: HTML attribute to be put in this component (in this case, the unordered list that showcases
 * the clickable items).
 * @param {Function} props.liftState: callback function to lift up this component's state.
 * @param {String} props.currentValue: current value of the parent's state according to this component.
 * @param {String | Array} props.skillsList: List of company skills retrieved from the API. Each of these is an object.
 * Initially it's set to "".
 * Then, when the parent fetches the list, it is uploaded as an array.
 * @returns {JSX} A box that displays all the company skills coming from the database as clickable components.
 */

const Skills = (props) => {
  const [error, setError] = useState("");
  const [skills, setSkills] = useState({ items: [{levelId: 6, skill: "Default"}] });
  const [skill, setSkill] = useState({levelId: 6, skill: "Default"});

  function skillsItem(skillToBePut) {
    if (skillToBePut?.skill === "Default") {
    } else {
      let currentStateSkills = skills.items;

      // This checks if a skill is re-setted to zero, and so deletes it from the skills state.
      if (skillToBePut.levelId === 0) {
        currentStateSkills = skills.items.filter(
          (element) => element.skill !== skillToBePut.skill
        );
        return setSkills({ items: currentStateSkills });
      }
      // This checks for every skill level update. Then it sets it in the adequate item from the skills state.
      else if (
        skillToBePut.levelId > 1 &&
        skillToBePut.levelId <= 5 &&
        currentStateSkills.length
      ) {
        const indexOfCurrentSkill = currentStateSkills.findIndex(
          (element) => element.skill === skillToBePut.skill
        );
        currentStateSkills[indexOfCurrentSkill] = skillToBePut;
        return setSkills({ items: currentStateSkills });
      }
      // Just for the initial state
      setSkills({ items: skills.items.concat(skill) });
    }
  }

  useEffect(() => {
    skillsItem(skill);
  }, [skill]);

  function handler(skills) {
    props.liftStateSkills(skills);
  }

  useEffect(() => {
    handler(skills);
  }, [skills]);

  const skillsFiltered = () => {
    let skillsFiltered = props.skillsList
      .filter(item => item.name !== "Default")
    return skillsFiltered;
  }

  return (
    <>
      {props.skillsList.length === 0 ? (
        error ? (
          <p>{error}</p>
        ) : (
          <p>Loading...</p>
        )
      ) : (
        <ul className="skill" name={props.name} value={props.currentValue}>
          {skillsFiltered().map((item) => (
            <Items item={item} key={item.id} setSkill={setSkill} />
          ))}
        </ul>
      )}
    </>
  );
};

Skills.propTypes = {
  name: PropTypes.string,
  liftState: PropTypes.func,
  currentValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  skillsList: PropTypes.array,
};

export default Skills;
