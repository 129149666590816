import { capitalLetters } from "../../utils/Projects/capitalLetters";
import { environment } from "../../config/environment";
import React, { useState, useContext } from "react";
import FullTimeIcon from "../Icons/FullTimeIcon";
import PartialTimeIcon from "../Icons/PartialTimeIcon";
import { getMoodsIcon } from "../../utils/getMoodsIcon";
import ReactTooltip from "react-tooltip";
import {useIntl} from "react-intl";
import {PlayerFloatMenu} from "../shared/PlayerFloatMenu";
import axios from "axios";
import {useInfiniteQuery} from "react-query";
import AddPlayerModal from "./AddPlayerModal";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";

/**
 * @module
 * @description This function creates the structure and the logic of information of player
 * @param {string} user get info player
 * @param {number} id get player id
 * @param {Function} getTeamAndRolesData get team and roles data
 * @returns {JSX} PlayerInfo
 */

const PlayerInfo = ({user, id, getTeamAndRolesData}) => {
  const [floatMenuOpen, setFloatMenuOpen] = useState(false);
  const [playerModalOpen, setPlayerModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const { auth } = useContext(ProjectsContext);
  let intl = useIntl();

  let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${user.photo}`;
  if (user.photo?.slice(0, 4) === "http") {
    userPhoto = user.photo;
  }

  const [filterSearch, setFilterSearch] = useState("");
  const [filterSort, setFilterSort] = useState({
    column: "",
    order: "asc",
  });

  const filters = {
    archived: 1,
    st_onTrack: 1,
    st_inDanger: 1,
    st_atRisk: 1,
    st_outdated: 1,
    sortBy: filterSort.column,
    sortOrder: filterSort.order,
  };

  const getQueryParamsFromFilters = (filters) => {
    let queryParamsString = "?";
    let firstParam = true;
    for (const key in filters) {
      const value = filters[key];
      let currentParam = `${key}=${value}`;
      if (!firstParam) {
        currentParam = "&" + currentParam;
      }
      firstParam = false;
      queryParamsString = queryParamsString + currentParam;
    }
    return queryParamsString;
  };

  const getProjectsByUser = async ({ pageParam = 1 }) => {
    const queryParams = getQueryParamsFromFilters(filters);
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/user/${auth.user.id}${queryParams}&page=${pageParam}&search=${filterSearch}`,
        {
          headers: {
            Authorization: auth.user.token,
          },
        }
      )
      .catch(async (err) => {
        console.log(err)
      });
    return response.data.data;
  };

  const useFetchingData = () => {
    return useInfiniteQuery(
      [
        "projects",
        {
          sortBy: filterSort.column,
          sortOrder: filterSort.order,
          search: filterSearch,
        },
      ],
      getProjectsByUser,
      {
        getNextPageParam: (lastPage, _pages) => {
          if (lastPage.currentPage < lastPage.totalPages) {
            return lastPage.currentPage + 1;
          } else {
            return undefined;
          }
        },
        keepPreviousData: true,
      }
    );
  };

  const {
    refetch,
  } = useFetchingData();

  /**
   * @description Handle Float Menu
   */
  const handleFloatMenu = () => {
    setFloatMenuOpen(!floatMenuOpen);
  };

  /**
   * @description handle Player Modal
   */
  const handlePlayerModal = (type) => {
    setModalType(type);
    setPlayerModalOpen(!playerModalOpen);
    setFloatMenuOpen(!floatMenuOpen);
  };

  return (
    <>
      <AddPlayerModal
        type={modalType}
        show={playerModalOpen}
        handlePlayerModal={handlePlayerModal}
        playerId={user.userId}
        projectId={id}
        callback={refetch}
      />
      <div className="player-container">
        {!user.photo ? (
          <div className="capital-letters" onClick={handleFloatMenu} data-tip="Player" data-for="player">
            <span className="initials">
              {capitalLetters(user.firstName, user.lastName)}
            </span>
          </div>
        ) : (
          <img
            src={userPhoto}
            alt="img-user"
            className="img-user"
            onClick={handleFloatMenu}
            data-tip="Player"
            data-for="player"
          />
        )}
        <ReactTooltip id="player" place="bottom" backgroundColor="#3FAA58"/>
        {user.allocationType === "Full-time" ? (
          <FullTimeIcon />
        ) : (
          <PartialTimeIcon />
        )}
        <div className="player-mood-project" data-tip="Player's mood" data-for="player-mood">{getMoodsIcon(user.moodId)}</div>
        <ReactTooltip id="player-mood" place="bottom" backgroundColor="#3FAA58"/>
        <div className="player-float">
          <PlayerFloatMenu
            floatMenuOpen={floatMenuOpen}
            handleFloatMenu={handleFloatMenu}
            firstname={user.firstName}
            lastname={user.lastName}
            playerId={user.userId}
            refetch={refetch}
            projectId={id}
            handlePlayerModal={handlePlayerModal}
          />
        </div>
      </div>
      <div className="info-user">
        <div className="role"> {user.projectRole} </div>
        <div className="name">
          {user.firstName} {user.lastName}
        </div>
      </div>
    </>
  );
};

export default PlayerInfo;
