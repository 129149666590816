import { ActionButton } from "../../../components/shared";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import css from "../NewsById.module.scss";
import css2 from "./AddNews.module.scss";
import { useAuthContext } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../../config/environment";
import { Breadcrumb } from "react-bootstrap";
import moment from "moment";
import EditComponent from "../../../components/Icons/EditComponent";
import data from "../../../version.json";
import { useIntl } from "react-intl";
import {userAuthServices} from "../../../services/Login/userAuthServices";

const AddNews = () => {
  const navigate = useNavigate();
  const context = useAuthContext();
  const { user } = useAuthContext();
  const intl = useIntl();
  let version = data;
  let currentVersion = version[version.length - 1];
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState({
    file: [],
  });
  const [imagePath, setImagePath] = useState("");
  const [authorName, setAuthorName] = useState("");

  const [loading, setLoading] = useState(false);

  const info = context?.userInfo?.photo;
  const datePublished = Date.now();
  let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${info}`;
  if (info?.slice(0, 4) === "http") {
    userPhoto = info;
  }

  let {isGlobal} = useParams();

  useEffect(() => {
    setAuthorName(context?.userInfo?.firstName + " " + context?.userInfo?.lastName);
  });

  const postFile = async (bodyFormData) => {
    const response = await axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/image`,
        bodyFormData,
        {
          headers: {
            Authorization: context?.user?.token
          },
        }
      )

      .catch((err) => {
        throw err.response || err.toJSON() || err
      });
    return response;
  };

  const onUpload = () => {
    setLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append("file", selectedFile.file);
    setImagePath(`dev/assets/newsPhotos/${selectedFile?.file?.name}`);
    setTimeout(() => {
      postFile(bodyFormData)
        .then((res) => {
          setSelectedFile(null);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err)
        });
    }, 4000);
  };

  const postNews = async () => {
    const body = {
      author_id: context?.user?.id,
      title: title,
      short_description: description,
      description: description,
      portrait: imagePath,
      imgs: {
        img: imagePath,
      },
      is_global: isGlobal === 'global' ?true:false,
      urls: null,
    };

    try {
      let path;

      if(isGlobal==='global') {
        path = `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/`
      }else {
        path = `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/local`
      }
      const response = await axios.post(
        path,
        body,
        {
          headers: {
            Authorization: context?.user?.token,
          },
        }
      );
      setTitle("");
      setDescription("");
      setSubtitle("");
      setImagePath("");
      if(isGlobal==='global') {
        navigate("/news/region/global", { replace: true });
      }else {
        navigate("/news/region/local", { replace: true });
      }
      if (response.status !== 201) {
        if (response.status === 401) {
          throw Object.assign(new Error(), {
            code: 401,
            message: "Unauthorized",
          });
        } else {
          throw Object.assign(new Error(), {
            code: 500,
            message:
              "Unexpected error, please try again or contact an administrator",
          });
        }
      }
    } catch (error) {
      console.log(error)
      throw error;
    }
  };

  const handleSubmit = () => {
    const loadData = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(
          setImagePath(`dev/newsFiles/${selectedFile?.file?.name}`)
        );
      }, 5000);
    }).then((data) => {
      postNews();
    });
    loadData();
  };

  useEffect(()=>{
    let permissions = user.permissions.userPermissions;
    let redirect = true;
    for (const permission of permissions) {
      if(permission.permission.name === 'create-global-news' || permission.permission.name === 'create-local-news' +
        '' ){
        redirect = false;
      }
    }
    if(redirect){
      navigate("/")
    }
  })

  return (
    <>
      <div className={css.container}>
        <div className={css.top}>
          <Breadcrumb className={css.breadCrumb}>
            <Breadcrumb.Item>
              <div className={css.global}>
                <Link
                  className={css.articleReadMore}
                  to={"/news/region/global"}
                >
                  {isGlobal[0].toUpperCase() + isGlobal.substring(1)} news
                </Link>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <input onChange={(e) => setTitle(e.target.value)}></input>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className={css.bottom}>
          <div className={css.section1}>
            <div className={css.articlePicture}>
              <figure>
                <div className={css2.input}>
                  <div className="element">
                    <EditComponent />
                  </div>

                  <input
                    type="file"
                    className={css2.inputfiles}
                    onInput={(e) => {
                      setSelectedFile({
                        ...selectedFile,
                        file: e.target.files[0],
                      });
                    }}
                    onChange={() => {
                      onUpload();
                    }}
                  ></input>
                </div>
              </figure>
            </div>
          </div>
          <div className={css.section2}>
            <div className={css.postedBy}>
              Creator: {authorName} &nbsp;
              <svg
                className={css.profilePicture}
                alt="profilePic"
                src={userPhoto}
                style={{ backgroundImage: `url(${userPhoto})` }}
              ></svg>
            </div>
            <div className={css.postedOn}>
              {moment(datePublished).format("MMMM DD Y")}
            </div>

            <div className={css.articleText}>
              <textarea
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <ActionButton
              secondary="tertiary"
              type="submit"
              onClick={() => handleSubmit()}
            >
              Create
            </ActionButton>
          </div>
        </div>
      </div>
      <div className={css.version}>
        <p className={css.versionText}>{currentVersion["Current version"]}</p>
      </div>
    </>
  );
};

export default AddNews;
