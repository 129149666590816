import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import scss from "./FirstTutorial.module.scss";
import { postTutorialCompleted } from "../services/tutorials";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { Link } from "react-router-dom";
import { sidebarTopbarTutorial } from "./SidebarTopbarTutorial";
import { useIntl } from "react-intl";

/**
 * @module
 * @description This function create a onboarding tutorial of a specific element or
 * set of elements . This function receives a array with all the steps of the tutorial
 * that we want to create , and in base of that returns a cool onboarding tutorial.
 * @param {object} props This object contains the array with all the steps of a specific
 * tutorial , and also the id of the tutorial.
 * @return {element} FirstTutorial Return the tutorial itself.
 */
const FirstTutorial = ({ handleChange, skip }) => {
  const [count, setCount] = useState(0);
  const [element, setElement] = useState();
  const [father, setFather] = useState();
  const [fatherStyles, setFatherStyles] = useState();
  const [style, setStyle] = useState();
  const [isDone, setIsDone] = useState(false);
  const [boxLeft, setBoxLeft] = useState();
  const [boxTop, setBoxTop] = useState();
  const canSkip = skip;
  const box = useRef(null);
  const context = useAuthContext();
  const intl = useIntl();
  const arreglo = sidebarTopbarTutorial(context?.userInfo?.firstName);
  const tutorialId = 1;
  /**
   * @description this function increase the counter by one every time a user press
   * the continue button
   */
  const click2 = () => {
    setCount(count + 1);
  };
  /**
   * @description When  a user completes the tutorial
   * then this function calls to the service that will post a new entry on the
   * database with the information of the user and which tutorial he just completed
   * @param {number} userId id of the user that is logged
   * @param {number} tutorialId id of the tutorial that the user completed
   * @returns {object} response Response of the DB once the new entry is posted
   */
  const tutorialCompleted = async (userId, tutorialId) => {
    let response;
    try {
      response = await postTutorialCompleted(userId, tutorialId, context?.user?.token);
      setCount(count + 1);
    }
    catch (error) {
    }
    return response;
  };

  /**
   * @description This functios is used to calculate the position of the div that contains all the information , based on the position of the element that the step is refering to
   */
  const calculatePosition = () => {
    let height = window.innerHeight;
    let width = window.innerWidth;
    let div = box.current.clientWidth;
    let pos = element.getBoundingClientRect();

    // This is used if the selected element is more to the right than to the left
    if (pos.x > width / 2) {
      setBoxLeft(pos.left - div + 40);
      setBoxTop(pos.bottom + 45);
      //If the element its more to the left than to the right , then this setting will applied to the element
    } else {
      setBoxLeft(pos.right + 10);
      setBoxTop(pos.bottom);

      if (arreglo[count].father.name == ".sidebar") {
        setBoxTop(pos.bottom - 70);
      }
      //If the element is below the half of the height resolution then this setting will be applied
      if (pos.y > height / 2) {
        if (width > 1536) {
          setBoxTop(pos.y - 110);
        } else {
          setBoxTop(pos.y - 140);
        }
      }
    }
    //This if is for the case that the user is using a mobile resolution
    if (width <= 816 && arreglo[count].father.name == ".sidebar") {
      // This is used for resolution with a width lower than 550
      if (width <= 550) {
        setBoxTop(height / 2);
        setBoxLeft(width / 2 - div / 2);
        //Resolutions  with a width higher than 550 but lower than 816
      } else {
        setBoxTop(pos.top - div - div);
        setBoxLeft(pos.right - pos.width);
        // This is used if the selected element is more to the right than to the left
        if (pos.x > width / 2) {
          setBoxLeft(pos.right - pos.width - pos.width / 2);
        }
      }
    }
  };

  useEffect(() => {
    let height = window.innerHeight;
    let div = box.current.clientHeight;
    if (window.innerWidth < 550) {
      // box.current.style.top = "25%";
      box.current.style.top = height / 2 - div / 2;
      box.current.style.left = "8.5%";
    } else if (
      window.innerHeight < 816 &&
      arreglo[count].father.name == ".sidebar"
    ) {
      box.current.style.bottom = `${boxTop}px`;
      box.current.style.right = `${boxLeft}px`;
    } else {
      box.current.style.top = `${boxTop}px`;
      box.current.style.left = `${boxLeft}px`;
    }
    return () => {};
  }, [boxTop, boxLeft]);

  /**
   * This use Layout effect applies a specific css style to each element
   * that the tutorial want to emphasize , and this is done before the
   * element renders in the actual DOM . Also once the user continues to the next
   * element the previous element will return to its original css style.
   */
  useLayoutEffect(() => {
    if (count != arreglo.length) {
      if (count != 0 && count <= arreglo.length - 1) {
        setElement(document.querySelector(arreglo[count].querySelector));
        setFather(document.querySelector(arreglo[count]?.father?.name));
      }

      if (element) {
        setFatherStyles(father?.style);
        setStyle(element.style);
        let style2 = arreglo[count]?.father?.style;
        Object.assign(father?.style, style2);
        style2 = arreglo[count].styles;
        Object.assign(element.style, style2);
        calculatePosition();
      }
    } else {
      setIsDone(true);
    }

    return () => {
      if (element) {
        father.style = fatherStyles;
        element.style = style;
      }
    };
  }, [count, element, boxTop]);

  /**
   * Finally in
   * case that the screen change its resolution this useeffect will recalculate the new
   * position and render the box with the information on the new position.
   */
  useEffect(() => {
    window.addEventListener("resize", calculatePosition);

    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  });

  return (
    <>
      {count != arreglo.length ? (
        <div className={scss.cover}>
          <div
            className={`${scss.guideInformation} ${
              scss[arreglo[count].posistion]
            }`}
            ref={box}
          >
            <h3 className={scss.title}>{arreglo[count].title}</h3>
            <h4 className={scss.subtitle}>{arreglo[count].subtitle}</h4>
            <p className={scss.description}>{arreglo[count].description}</p>
            {count == arreglo.length - 1 ? (
              <div id={scss.btnContainer}>
                <button
                  className={`${scss.continueButton} ${scss.completedButton}`}
                  onClick={() => {
                    tutorialCompleted(context?.userInfo.id, tutorialId);
                    handleChange(true);
                    <Link to="/">Complete tutorial</Link>;
                  }}
                >
                  Complete Tutorial
                </button>
              </div>
            ) : (
              <div
                className={`${scss[arreglo[count].posistion]}Button`}
                id={scss.btnContainer}
              >
                {canSkip && (
                  <button
                    className={`${scss.skipButton}`}
                    onClick={() => {
                      tutorialCompleted(context?.userInfo.id, tutorialId);
                      handleChange(true);
                      <Link to="/">Complete tutorial</Link>;
                    }}
                  >
                    Skip Tutorial
                  </button>
                )}
                <button
                  onClick={() => click2(arreglo)}
                  className={scss.continueButton}
                >
                  Continue
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default FirstTutorial;
