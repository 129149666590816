import { Link } from 'react-router-dom';

/**
 * @module
 * @description This function create the structure and the logic of the Information of the project
 * @param {Function} project get data project
 * @returns {JSX} Info Project
 */

const InfoProject = ({
  project: { projectName, projectKey, clientName, projectId },
}) => {
  const imgURL =
    'https://pbs.twimg.com/profile_images/1395764701620015107/rioTM8Ld_400x400.jpg';

  return (
    <>
      <img src={imgURL} className="img-project" alt="project" />
      <div className="name-project">
        <Link to={`/projects/${projectId}`} style={{ textDecoration: 'none' }}>
          <p className="title-project">{projectName}</p>
        </Link>
        <p className="description-project">
          <b>{`#${projectKey}`}</b>
          {` - ${clientName}`}
        </p>
      </div>
    </>
  );
};

export default InfoProject;
