import {useContext, useEffect, useState} from 'react';
import { FloatMenu } from '../shared';
import './ProjectActionsColumn.scss';
import AddPlayerModal from '../../components/Projects/AddPlayerModal';
import ProjectUpdateModal from './ProjectUpdateModal';
import ElipsisButtonIcon from '../Icons/ElipsisButtonIcon';
import { DialogBox } from '../shared/DialogBox';
import Swal from 'sweetalert2';
import ProjectsContext from '../../context/ProjectsContext/ProjectsContext';
import ReactTooltip from "react-tooltip";
import {useIntl} from "react-intl";
import {useAuthContext} from "../../context";

/**
 * @module
 * @description This function creates the structure and the logic of Project Actions Column
 * @param {Function} projectData get info project data
 * @param {Function} fetchingData get fetching data
 * @returns {JSX} Project Actions Column
 */

const ProjectActionsColumn = ({ projectData, refetch }) => {
  // ---------------- FLOAT MENU ACTIONS (elipsis button) ----------------
  const [floatMenuOpen, setFloatMenuOpen] = useState(false);
  const [playerModalOpen, setPlayerModalOpen] = useState(false);
  const [projectUpdateModalOpen, setProjectUpdateModalOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const { user } = useAuthContext();
  const { archiveProject, auth } = useContext(ProjectsContext);
  let intl = useIntl();

  const archiveConfirmation = {
    principalText: 'Are you sure you want to archive the project?',
    secondaryText: 'Project information will be archieved',
    confirmButtonText: `${intl.formatMessage({id : "button.confirm", defaultMessage : "Yes, I want"})}`,
    confirm: `${projectData.projectName} has been archived`,
  };

  /**
   * @description Unarchive Confirmation
   */
  const unarchiveConfirmation = {
    principalText: 'Are you sure you want to unarchive the project?',
    secondaryText: 'Project information will be unarchieved',
    confirmButtonText: `${intl.formatMessage({id : "button.confirm", defaultMessage : "Yes, I want"})}`,
    confirm: `${projectData.projectName} has been unarchived`,
  };

  const { projectId } = projectData;
  const { players } = projectData;

  const handleFloatMenu = () => {
    setFloatMenuOpen(!floatMenuOpen);
  };

  const handlePlayerModal = () => {
    setPlayerModalOpen(!playerModalOpen);
  };

  const handleProjectUpdateModal = () => {
    setProjectUpdateModalOpen(!projectUpdateModalOpen);
  };

  const addProjectUpdate = (projectId) => {
    handleProjectUpdateModal();
  };

  const archivedProject = async () => {
    await archiveProject(projectId)
      .then((res) => refetch())
      .catch( async (err) => {
        Swal.fire('Something went wrong', 'Try again later', 'error');
        console.error(err);
      });
  };

  const getMenuOptions = (project) => [
    {
      text: 'Add player',
      action: () => handlePlayerModal(),
    },
    {
      text: 'Add status update',
      action: () => addProjectUpdate(project),
      addDividerAfter: true,
    },
    {
        text: 'Archive project',
      action: () => DialogBox(archiveConfirmation, archivedProject),
    },
  ];

  /**
   * @description Get Menu Options inactive
   */
  const getMenuOptionsArchived = () => [
    {
      text: 'Unarchive project',
      action: () => DialogBox(unarchiveConfirmation, archivedProject),
    },
  ];

  useEffect(()=>{
    let permission = user.permissions.userPermissions;
    let permissionsArray = []
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <>
      <ProjectUpdateModal
        show={projectUpdateModalOpen}
        projectId={projectId}
        handleProjectUpdateModal={handleProjectUpdateModal}
        callback={refetch}
        players={players}
      />
      <AddPlayerModal
        show={playerModalOpen}
        handlePlayerModal={handlePlayerModal}
        projectId={projectId}
        type="add"
        callback={refetch}
        players={players}
      />
      {permissions.includes('update-projects') || permissions.includes('archive') || permissions.includes('create-players') ?
      <FloatMenu
        handleFloatMenu={handleFloatMenu}
        floatMenuOpen={floatMenuOpen}
        bottomLeft
        menuOptions={
          !projectData.projectIsArchived
            ? getMenuOptions(projectData)
            : getMenuOptionsArchived()
        }
      >
        <button className="projectMenu" onClick={handleFloatMenu} data-tip={intl.formatMessage({id : "project.options", defaultMessage : "Options"})} data-for="options">
          <ElipsisButtonIcon />
          <ReactTooltip id="options" place="bottom" backgroundColor="#3FAA58"/>
        </button>
      </FloatMenu> : null}
    </>
  );
};

export default ProjectActionsColumn;
