import axios from "axios";
import { environment } from "../../../config/environment";

const holidayService = {
    save: async (holidayData, userId, token) => {
      try {
        const data = await axios
        .post(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/holiday`,
            holidayData,
            {
                headers: {
                authorization: token,
            },
          }
        );
      return data;
    } catch (err) {
      throw (
        err.response || err.toJSON() || err
      );
    }
    },
}

const holidayServiceDataFormat = (data, userId, token)=>{
  
  const newData = {  
    "locationId": data.context,
    "date": data.date,
    "name": data.title,
    "description": data.description
  }
  return holidayService.save(newData, userId, token)
}


export { holidayServiceDataFormat };