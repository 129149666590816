import axios from "axios";
import { environment } from "../../../config/environment";

const getUsersByAdmin =async (token, adminId) => {
  const result = await axios.get(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/admin/${adminId}`,
      {
        headers: {
          authorization: token,
        },
      }
    ).catch(error => {
      throw error.response || error.toJSON() || error
    });

  return result.data.data;
};

export default getUsersByAdmin;